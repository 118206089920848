<template>
    <private-template>
        <template v-slot:private-template>
            <HeadingBar
                :title="$i18n.t('lb_surveys_title')"
                :text="$i18n.t('txt_surveys')"
            />
            <div class="sp__v__2x"/>
            <b-tabs lazy>
                <b-tab :title="$i18n.t('lb_templates')"> <SurveyTemplates/> </b-tab>
                <b-tab :title="$i18n.t('lb_answer')"><SurveyResponses/></b-tab>
            </b-tabs>
        </template>
    </private-template>
</template>
<script>
import PrivateTemplate from '@/components/organisms/PrivateTemplate.vue';

export default {
  components: {
    PrivateTemplate,
    HeadingBar: () => import('@/components/molecules/HeadingBar/HeadingBar.vue'),
    SurveyResponses: () => import('@/views/Survey/Survey_responses/Survey_responses.vue'),
    SurveyTemplates: () => import('@/views/Survey/Survey_templates/Survey_templates.vue'),
  },
};
</script>
