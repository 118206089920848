<template>
  <div>
    <Spinner :isLoading="isLoading" />
    <div v-if="!isLoading" class="InsightCard" v-bind:class="`InsightCard--${variant}`">
      <p class="InsightCard__label">{{ label }}</p>
      <p class="InsightCard__value">{{ value }}</p>
    </div>
  </div>
</template>

<script>
import Spinner from '@/components/atoms/Spinner/Spinner.vue';

export default {
  props: {
    label: String,
    value: Number,
    variant: String,
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
  },
  components: {
    Spinner,
  },
};
</script>
<style lang="sass" scoped>
@import './InsightCard.sass'
</style>
