import {
  getRequest,
  getRequestDocuments,
  getAllRequest,
  filterRequest,
  searchRequest,
  createRequest,
  updateRequest,
  deleteRequest,
  changeRequestStatus,
  downloadRequestDocument,
  exportRequests,
} from './requestActions';

import {
  getRequestState,
  getRequestPaginationState,
  getRequestListState,
} from './requestGetters';

import {
  setRequest,
  setRequestPagination,
  setRequestList,
  updateRequestStatus,
} from './requestMutations';

const request = {
  namespaced: true,
  state: () => ({
    request: {
      requestList: [],
      pagination: {},
    },
  }),
  actions: {
    getRequest,
    getRequestDocuments,
    getAllRequest,
    filterRequest,
    searchRequest,
    createRequest,
    updateRequest,
    deleteRequest,
    changeRequestStatus,
    downloadRequestDocument,
    exportRequests,
  },
  mutations: {
    setRequest,
    setRequestPagination,
    setRequestList,
    updateRequestStatus,
  },
  getters: {
    getRequestState,
    getRequestPaginationState,
    getRequestListState,
  },
};

export default request;
