import SurveyTypePartialsTypeApi from '../../domain/surveyTypePartialsType/SurveyTypePartialsTypeApi';

export function getAllSurveyTypePartialsType({ commit }, data) {
  const surveyTypePartialsTypeApi = new SurveyTypePartialsTypeApi();
  const getAllRequest = surveyTypePartialsTypeApi.getAllSurveyTypePartialsType(data);

  return getAllRequest
    .then((res) => res)
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function filterSurveyTypePartialsType({ commit }, data) {
  const surveyTypePartialsTypeApi = new SurveyTypePartialsTypeApi();
  const searchRequest = surveyTypePartialsTypeApi.filterSurveyTypePartialsType(data);

  return searchRequest
    .then((res) => res)
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function searchSurveyTypePartialsType({ commit }, data) {
  const surveyTypePartialsTypeApi = new SurveyTypePartialsTypeApi();
  const searchRequest = surveyTypePartialsTypeApi.searchSurveyTypePartialsType(data);

  return searchRequest
    .then((res) => res)
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function createSurveyTypePartialsType({ commit }, data) {
  const surveyTypePartialsTypeApi = new SurveyTypePartialsTypeApi();
  const createRequest = surveyTypePartialsTypeApi.createSurveyTypePartialsType(data);

  return createRequest
    .then()
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function updateSurveyTypePartialsType({ commit }, data) {
  const surveyTypePartialsTypeApi = new SurveyTypePartialsTypeApi();
  const updateRequest = surveyTypePartialsTypeApi.updateSurveyTypePartialsType(data);

  return updateRequest
    .then()
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function deleteSurveyTypePartialsType({ commit }, uuid) {
  const surveyTypePartialsTypeApi = new SurveyTypePartialsTypeApi();
  const deleteRequest = surveyTypePartialsTypeApi.deleteSurveyTypePartialsType(uuid);

  return deleteRequest
    .then()
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}
