<template>
  <div class="SideForm" v-if="hasRequest">
    <div class="SideForm__header">
      <RequestHeader
        :request="requestData"
        :showClose="showClose"
        @handleCloseNotification="handleCloseNotification"
      />
    </div>
    <div class="sp__v__1d5x"/>
    <div class="SideForm__content">
      <div class="sp__v__1s5x"/>
      <ContactBlock
        :user="requestData.patient"
        :isAccepted="isInvitationAccepted && isRequestAccepted"
      />
      <hr/>
      <RequestMiniCardBlock
        :request="requestData"
      />
      <div class="sp__v__1d5x"/>
      <hr/>
      <div class="sp__v"/>
      <b-tabs>
        <b-tab :title="$i18n.t('lb_summary')">
          <div class="sp__v__1d5x"/>
          <RequestSummaryInfo
            :request="this.requestData"
            isDetail
          />
        </b-tab>
        <b-tab :title="getDocumentsTitle">
          <div class="sp__v__1d5x"/>
          <DocumentTable
            v-if="hasDocuments"
            :items="this.requestData.documents"
            canDownload
          />
        </b-tab>
        <b-tab :title="getNotesTitle">
          <div class="sp__v__1d5x"/>
          <NotesTable
            v-if="hasNotes"
            :items="this.requestData.notes"
            @handleShowDetail="handleShowViewNote"
          />
        </b-tab>
        <b-tab v-if="isDoctorOrClinicOrVidasprime" :title="getCalendarTitle">
          <div class="sp__v__1d5x"/>
          <CalendarTable
            :requestID="this.requestData.id"
            :items="getAppointments"
            @handleCreate="handleCreateAppointment"
            @handleUpdate="handleUpdateAppointment"
            @handleDelete="handleDeleteAppointment"
            @handleShowDetail="handleShowViewCalendar"
            canDelete
            :isLoading="isLoadingAppointment"
            :isLoadingCreate="isLoadingCreateAppointment"
          />
        </b-tab>
        <b-tab :title="$i18n.t('lb_collaboration')"  v-if="(isAllowCollaborations || isVidasprime)">
          <div class="sp__v__1d5x"/>
          <RequestCollaboration
            :collaboratorClinics="requestData.collaborators"
            :mainClinic="requestData.clinic"
            :pendindInvitations="requestData.invitations"
          />
        </b-tab>
      </b-tabs>
      <hr/>
      <div v-if="showHealthManager">
        <div class="sp__v__1s5x"/>
        <p class="text__psb">Health Manager:</p>
        <div class="sp__v"/>
        <UserMiniCard
          :icon="getAvatarHM"
          :label="getHealthManager.name"
        />º
        <div class="sp__v__2x"/>
      </div>

      <div v-if="isDoctor">
        <div class="sp__v__1s5x"/>
        <p class="text__psb">Doctor:</p>
        <div class="sp__v"/>
        <UserMiniCard
          :icon="getIcon(getDoctor.avatar)"
          :label="getDoctor.name"
        />
        <div class="sp__v__2x"/>
      </div>

      <div v-if="!isDoctorOrClinic">
        <div class="sp__v__1s5x"/>
        <p class="text__psb">Clinic:</p>
        <div class="sp__v"/>
        <UserMiniCard
          :icon="getAvatarClinic"
          :label="getClinicName"
        />
        <div class="sp__v__2x"/>
      </div>
    </div>
    <RequestDetailActions
      :uuid="this.requestData.id"
      :isLoading="isLoading"
      v-if="(showButton && !showInvitationButtons)"
      @handleAccept="handleAcceptRequest"
      @handleReject="handleRejectRequest"
    />
    <Stage
      v-if="(!showButton && !showInvitationButtons)"
      :stage="this.getRequestStage(this.requestData.requestStage)"
    />
    <RequestDetailActions
      :uuid="invitationId"
      :isLoading="isLoading"
      v-if="(!showButton && showInvitationButtons && !showInvitationSender)"
      @handleAccept="handleAcceptInvitation"
      @handleReject="handleRejectInvitation"
    />
    <div
      class="bottomButton"
      v-if="(!showButton && showInvitationButtons && showInvitationSender)"
    >
        <b-button
          v-if="!isLoading"
          @click.prevent="handleCancelInvitation(invitationId)"
          variant="primary"
        >
          {{ $i18n.t('btn_cancel') }}
        </b-button>
    </div>
    <NoteModal
      id="requestDetailFormNoteModal"
      ref="requestDetailFormNoteModal"
    />
    <CalendarModal
      id="requestDetailFormCalendarModal"
      ref="requestDetailFormCalendarModal"
      @handleUpdate="handleUpdateAppointment"
      isEditable
    />
  </div>
</template>

<script>
import RequestHeader from '@/components/molecules/Request/RequestHeader/RequestHeader.vue';
import RequestMiniCardBlock
  from '@/components/molecules/Request/RequestMiniCardBlock/RequestMiniCardBlock.vue';
import RequestDetailActions
  from '@/components/molecules/Request/RequestDetailActions/RequestDetailActions.vue';
import RequestSummaryInfo from './RequestSummaryInfo.vue';
import DocumentTable from '@/components/molecules/Tables/DocumentTable/DocumentTable.vue';
import NotesTable from '@/components/molecules/Tables/NotesTable/NotesTable.vue';
import CalendarTable from '@/components/molecules/Tables/CalendarTable/CalendarTable.vue';
import NoteModal from '@/components/molecules/Modal/NoteModal.vue';
import CalendarModal from '@/components/molecules/Modal/CalendarModal.vue';
import ContactBlock from '@/components/molecules/ContactBlock/ContactBlock.vue';
import UserMiniCard from '@/components/molecules/User/UserMiniCard/UserMiniCard.vue';
import Stage from '@/components/atoms/Stage/Stage.vue';
// import defaultRequest from '@/views/Request/defaultRequest';
import {
  EMERGENCY_DEGREE_OPTIONS,
  BOOLEAN_SELECTION_OPTIONS,
  STATUS_OPTIONS,
  STAGE_OPTIONS,
  STATUS,
} from '@/helpers/ComponentHelper';
import { getAvatarUrl, getAvatarByPath } from '@/helpers/UserHelper';
import emptyAvatar from '@/assets/user/empty-avatar-dark.svg';
import RequestCollaboration from './RequestCollaboration.vue';

export default {
  props: {
    showClose: {
      type: Boolean,
      default: false,
    },
    request: {
      type: Object,
    },
    invitationStatus: String,
    invitationSender: Boolean,
    invitationId: String,
  },
  data() {
    return {
      requestData: this.request,
      validationState: {
        requestData: null,
      },
      levelOptions: EMERGENCY_DEGREE_OPTIONS,
      booleanOptions: BOOLEAN_SELECTION_OPTIONS,
      statusOptions: STATUS_OPTIONS,
      stageOptions: STAGE_OPTIONS,
      patientItems: [],
      healthManagerItems: [],
      clinicItems: [],
      showCreatePatient: false,
      showCreateHealthManager: false,
      showCreateClinic: false,
      isLoading: false,
      isLoadingAppointment: false,
      isLoadingCreateAppointment: false,
    };
  },
  watch: {
    request(newValue) {
      this.requestData = newValue;
    },
  },
  mounted() {},
  methods: {
    getRequestStage(data) {
      if (!data) return null;

      let stageName;

      if (typeof data === 'string') {
        const stageOption = STAGE_OPTIONS.filter((stage) => stage.value === data);
        stageName = this.$i18n.t(stageOption[0].text);

        return stageName;
      }

      const stageOption = STAGE_OPTIONS.filter((stage) => stage.value === data.id);
      stageName = this.$i18n.t(stageOption[0].text);

      return stageName;
    },
    handleShowViewNote(note) {
      this.$refs.requestDetailFormNoteModal.setNote(note);
      this.$bvModal.show('requestDetailFormNoteModal');
    },
    handleShowViewCalendar(appointment) {
      this.$refs.requestDetailFormCalendarModal.setAppointment(appointment);
      this.$bvModal.show('requestDetailFormCalendarModal');
    },
    handleCloseNotification() {
      this.$emit('handleCloseNotification');
    },
    handleCreateAppointment(appointment) {
      if (appointment) {
        this.isLoadingCreateAppointment = true;
        this.$store.dispatch('appointments/createAppointments', appointment)
          .then(() => {
            this.addAppointment(appointment);
            this.isLoadingCreateAppointment = false;
          })
          .catch(() => {
          });
      }
    },
    handleUpdateAppointment(appointment) {
      if (appointment) {
        this.$store.dispatch('appointments/updateAppointments', appointment)
          .then(() => {
            this.updateAppointment(appointment);
            this.$bvModal.hide('requestDetailFormCalendarModal');
          })
          .catch(() => {
          });
      }
    },
    updateAppointment(appointment) {
      for (let i = 0; this.requestData.appointments.length; i += 1) {
        if (this.requestData.appointments[i].id === appointment.appointmentId) {
          this.requestData.appointments[i].date = appointment.date;
          this.requestData.appointments[i].comment = appointment.comment;
          break;
        }
      }
    },
    handleDeleteAppointment(appointmentId) {
      if (appointmentId) {
        const requestData = {
          appointmentId,
        };

        this.isLoadingAppointment = true;

        this.$store.dispatch('appointments/deleteAppointments', requestData)
          .then(() => {
            this.deleteAppointment(requestData.appointmentId);
            this.isLoadingAppointment = false;
          })
          .catch(() => {
          });
      }
    },
    addAppointment(appointment) {
      this.requestData.appointments.unshift(appointment.appointment);
    },

    deleteAppointment(appointmentId) {
      for (let i = 0; this.requestData.appointments.length; i += 1) {
        if (this.requestData.appointments[i].id === appointmentId) {
          this.requestData.appointments.splice(i, 1);
          break;
        }
      }
    },

    changeRequestStatus(id, status) {
      this.isLoading = true;
      const requestData = {
        id,
        body: {
          status,
        },
      };

      this.$store.dispatch('request/changeRequestStatus', requestData)
        .then(() => {
          this.requestData.accepted = status === STATUS.ACCEPTED.key;
          this.$emit('handleChangeRequest', id, status);
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },

    handleAcceptRequest(id) {
      this.changeRequestStatus(id, STATUS.ACCEPTED.key);
    },
    handleRejectRequest(id) {
      this.changeRequestStatus(id, STATUS.REJECTED.key);
    },

    handleAcceptInvitation(id) {
      this.$emit('handleAcceptInvitation', id);
    },
    handleRejectInvitation(id) {
      this.$emit('handleRejectInvitation', id);
    },
    handleCancelInvitation(id) {
      this.$emit('handleCancelInvitation', id);
    },

    getIcon(avatar) {
      if (avatar !== null && typeof avatar !== 'undefined') {
        return getAvatarUrl(avatar);
      }

      return emptyAvatar;
    },
  },
  computed: {
    getAppointments() {
      return this.requestData.appointments;
    },

    hasRequest() {
      return this.requestData?.id !== undefined;
    },

    hasDocuments() {
      return this.requestData.documents !== undefined && this.requestData?.documents.length > 0;
    },

    getDocumentsTitle() {
      return this.hasDocuments ? this.$i18n.t('lb_documents_count',
        { count: this.requestData.documents.length }) : this.$i18n.t('lb_documents');
    },

    hasNotes() {
      return this.requestData.notes !== undefined && this.requestData?.notes.length > 0;
    },

    getNotesTitle() {
      return this.hasNotes ? this.$i18n.t('lb_notes_count',
        { count: this.requestData.notes.length }) : this.$i18n.t('lb_notes');
    },

    getCalendarTitle() {
      return 'Citas';
    },

    isVidasprime() {
      return this.$store.getters['user/isVidasprime'];
    },

    isClinic() {
      return this.$store.getters['user/isClinic'];
    },

    isDoctor() {
      return this.$store.getters['user/isDoctor'];
    },

    isDoctorOrClinic() {
      return this.$store.getters['user/isDoctorRole'] || this.$store.getters['user/isClinicRole'];
    },

    isDoctorOrClinicOrVidasprime() {
      return this.isDoctor || this.isClinic || this.isVidasprime;
    },
    isAllowCollaborations() {
      return this.$store.getters['user/isAllowCollaborations'];
    },

    isPending() {
      return this.requestData.status === STATUS.PENDING.key;
    },

    getHealthManager() {
      return this.requestData.healthManager?.id !== undefined
      && this.requestData.healthManager?.id !== null
        ? this.requestData.healthManager : null;
    },

    getClinic() {
      return this.requestData.clinic?.id !== undefined
      && this.requestData.clinic?.id !== null
        ? this.requestData.clinic : null;
    },

    getClinicName() {
      return this.getClinic && this.getClinic.name
        ? this.getClinic.name
        : null;
    },

    getDoctor() {
      return this.$store.getters['user/getCurrentUser'];
    },

    showHealthManager() {
      return (this.getHealthManager && !this.isDoctorOrClinic);
    },

    getAvatarHM() {
      const data = this.requestData.healthManager;
      if (!(data && data.avatar)) {
        return emptyAvatar;
      }

      return getAvatarByPath(data);
    },

    getAvatarClinic() {
      const data = this.requestData.clinic;
      if (!(data && data.avatar)) {
        return emptyAvatar;
      }

      return getAvatarByPath(data);
    },

    showButton() {
      if (this.isVidasprime) {
        return false;
      }
      return this.isPending;
    },
    showInvitationButtons() {
      if (this.invitationStatus !== undefined) {
        return this.invitationStatus.toLowerCase() === 'pending';
      }
      return false;
    },

    showInvitationSender() {
      if (this.invitationSender !== undefined) {
        return this.invitationSender;
      }
      return false;
    },

    isRequestAccepted() {
      const req = this.requestData.status.toUpperCase() === STATUS.ACCEPTED.key.toUpperCase();
      return req;
    },
    isInvitationAccepted() {
      const inv = this.invitationStatus !== undefined
        ? this.invitationStatus.toUpperCase() === STATUS.ACCEPTED.key.toUpperCase()
        : true;
      return inv;
    },
  },
  components: {
    RequestHeader,
    RequestMiniCardBlock,
    RequestDetailActions,
    RequestSummaryInfo,
    DocumentTable,
    NotesTable,
    NoteModal,
    ContactBlock,
    UserMiniCard,
    Stage,
    CalendarTable,
    CalendarModal,
    RequestCollaboration,
  },
};
</script>

<style lang="sass" scoped>
  @import './SideForm.sass'
</style>
