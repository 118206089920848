import {
  getCurrentTab,
  getShowMobileDetails,
  getIsUserLoading,
} from './uiGetters';
import {
  setCurrentTab,
  setShowMobileDetails,
  setIsUserLoading,
} from './uiMutations';

const ui = {
  namespaced: true,
  state: () => ({
    currentTab: 'request',
    showMobileDetails: false,
    isUserLoading: false,
  }),
  actions: {},
  mutations: {
    setCurrentTab,
    setShowMobileDetails,
    setIsUserLoading,
  },
  getters: {
    getCurrentTab,
    getShowMobileDetails,
    getIsUserLoading,
  },
};

export default ui;
