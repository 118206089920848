<template>
  <div
    :class="
      isCentered ?
      'ModalFooter ModalFooter__centered' :
      'ModalFooter'"
  >
    <b-button
      v-if="!onlyOk && !isLoading"
      @click.prevent="handleCancel"
      variant="secondary"
    >
      {{ cancelLabel }}
    </b-button>
    <span
      v-if="!onlyCancel && !isLoading && !onlyOk"
      class="sp__v__1d5x"
    />
    <Spinner
      :isLoading="isLoading"
      :full="fullSpinner"
    />
    <b-button
      v-if="withImport && isClinic"
      @click.prevent="handleImport"
      variant="secondary"
    >
      Importar usuarios
    </b-button>
    <span
      v-if="withImport"
      class="sp__v__1d5x"
    />
    <b-button
      v-if="!onlyCancel && !isLoading"
      @click.prevent="handleOk"
      variant="primary"
    >
      {{ okLabel }}
    </b-button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Spinner from '@/components/atoms/Spinner/Spinner.vue';

export default {
  props: {
    okLabel: {
      type: String,
      default: '',
    },
    cancelLabel: {
      type: String,
      default: '',
    },
    isCentered: {
      type: Boolean,
      required: false,
    },
    onlyCancel: {
      type: Boolean,
      default: false,
    },
    onlyOk: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
    fullSpinner: {
      type: Boolean,
      default: false,
    },
    withImport: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleCancel() {
      this.$emit('handleCancel');
    },

    handleOk() {
      this.$emit('handleOk');
    },

    handleImport() {
      this.$emit('handleImport');
    },
  },
  computed: {
    ...mapGetters('user', {
      isClinic: 'isClinic',
    }),
  },
  components: {
    Spinner,
  },
};
</script>

<style lang="sass" scoped>
  @import './ModalFooter.sass'
</style>
