export default {
  lb_add: '+ Añadir',
  // User
  lb_configuration: 'Configuración',
  // Patient
  lb_patient: 'Paciente',
  lb_patients: 'Pacientes',
  lb_survey: 'Encuesta',
  lb_surveys: 'Encuestas',
  lb_surveys_title: 'Descubre la opinión de tus pacientes y maximiza su satisfacción',
  lb_surveys_satisfaction: 'Satisfacción',
  lb_reputation: 'Reputación',
  lb_total_patients: 'TOTAL PACIENTES',
  lb_new_patient: 'Nuevo Paciente',
  lb_update_patient: 'Editar Paciente',
  lb_select_patient: 'Seleccionar Paciente',
  lb_import_patient: 'Importar Paciente',

  // Home
  lb_home: 'Insights',

  // Survey
  lb_templates: 'Plantillas',
  lb_answer: 'Respuestas',
  lb_survey_sent_count: 'Enviadas',
  lb_survey_answered: 'Respondidas',
  lb_survey_response_rate: 'Porcentaje de respuestas',

  // Survey Type
  lb_survey_type: 'Tipo de encuesta',
  lb_select_survey_type: 'Seleccionar encuesta',
  lb_survey_types: 'Diseño y repositorio de encuestas',
  lb_result_survey: 'Resultados encuesta',
  lb_survey_send_at: 'Fecha de envío',
  lb_survey_answered_at: 'Fecha de respuesta',
  lb_new_survey: 'Nueva encuesta',
  lb_new_survey_type: 'Nueva encuesta',
  lb_select_survey: 'Seleccionar encuesta',
  lb_survey_type_name: 'Nombrar la nueva encuesta',
  lb_update_survey_type: 'Editar tipo de encuesta',

  // Partial type
  lb_patial_type: 'Tipo de elemento',

  // Survey Type Partials type
  lb_survey_types_partials_types: 'Elementos de la encuesta',
  lb_new_survey_type_partial_type: 'Nuevo elemento',
  lb_add_survey_type_partial_type: 'Añadir elemento',
  lb_survey_type_partials_type_name: 'Nombre del elemento',
  lb_survey_type_partials_type_title: 'Pregunta',
  lb_survey_type_partials_type_description: 'Descripción de la pregunta',
  lb_survey_type_partials_type_help: 'Texto de ayuda',
  lb_survey_type_partials_type_is_required: '¿Es obligatorio?',
  lb_survey_type_partials_type_placeholder: 'Placeholder',

  // Doctors
  lb_doctors: 'Perfiles',
  lb_doctors_title: 'Equipo',
  lb_new_doctor: 'Nuevo Doctor',
  lb_update_doctor: 'Editar Doctor',
  lb_add_doctor: 'Asignar Doctor',
  // Health Manager
  lb_health_manager: 'Health Manager',
  lb_new_health_manager: 'Nuevo Health Manager',
  lb_update_health_manager: 'Editar Health Manager',
  lb_add_health_manager: 'Asignar Health Manager',
  // Calendar
  lb_calendar: 'Calendario',
  // Reviews
  lb_reviews: 'Reviews',
  // Reports
  lb_reports: 'Informes',
  // Clinics
  lb_clinic: 'Clínica',
  lb_clinics: 'Clínicas',
  lb_new_clinic: 'Nueva Clínica',
  lb_update_clinic: 'Editar Clínica',
  lb_clinic_logo: 'Logo de la clínica',
  lb_clinic_name: 'Nombre de la clínica',
  lb_add_clinic: 'Asignar Clínica',
  // Applications
  lb_applications: 'Aplicaciones',
  // Treatments
  lb_treatments: 'Tratamientos',
  // Skyadoctor
  lb_skyadoctor: 'SkyaDoctor',
  // Requests
  lb_total_requests: 'Total',
  lb_requests_pending: 'Pendientes',
  lb_requests_accepted: 'Aceptadas',
  lb_requests_rejected: 'Rechazadas',
  lb_new_request: 'Nueva Solicitud',
  lb_update_request: 'Editar Solicitud',
  lb_copy_request: 'Copiar Solicitud',
  lb_request_count: 'Solicitudes ({count})',
  lb_request_tab_table: 'Mis solicitudes',
  lb_request_tab_table_count: 'Mis solicitudes ({count})',
  // Collaborations
  lb_collaboration_tab_sent: 'Invitaciones enviadas',
  lb_collaboration_tab_sent_count: 'Invitaciones enviadas ({count})',
  lb_collaboration_tab_received: 'Invitaciones recibidas',
  lb_collaboration_tab_received_count: 'Invitaciones recibidas ({count})',
  lb_collaboration_main: 'Clinica Principal',
  lb_collaboration_clinics: 'Clinicas Colaboradoras',
  lb_collaboration_invitations: 'Invitaciones Pendientes',
  lb_collaboration_modal_title: 'Nueva invitación para colaborar',
  lb_collaboration_modal_comment_title: 'Comentarios',
  lb_collaboration_modal_comment_placeholder: 'Introduce tus comentarios (Opcional)',
  lb_collaboration_modal_name_title: 'Nombre',
  lb_collaboration_modal_name_placeholder: 'Introduce el nombre',
  lb_collaboration_modal_email_title: 'Email',
  lb_collaboration_modal_email_placeholder: 'Introduce el mail',
  lb_collaboration_result_email_title: 'Invitación enviada con éxito',
  lb_collaboration_result_email_subtitle: 'Has enviado tu invitación a la siguiente clínica: ',
  lb_collaboration_result_clinic_subtitle: 'Has enviado tu invitación a la siguiente clínica, podrás ver su estado en invitaciones pendientes.',
  lb_collaboration_cancel_title: 'Vas a cancelar una invitación enviada',
  lb_collaboration_cancel_subtitle: 'Vas a cancelar la invitación de la solicitud',
  lb_collaboration_accept_title: 'Vas a aceptar una invitación enviada',
  lb_collaboration_accept_subtitle: 'Vas a aceptar la invitación de la solicitud',
  lb_collaboration_reject_title: 'Vas a rechazar una invitación enviada',
  lb_collaboration_reject_subtitle: 'Vas a rechazar la invitación de la solicitud',
  // Documents
  lb_documents: 'Documentos',
  lb_add_document: 'Añadir documento',
  lb_documents_count: 'Documentos ({count})',
  // Notes
  lb_note: 'Cualificación',
  // lb_notes: 'Notas',
  lb_notes: 'Cualificaciones',
  lb_add_note: 'Añadir cualificación',
  // lb_notes_count: 'Notas ({count})',
  lb_notes_count: 'Cualificaciones ({count})',
  // Notifications
  lb_notifications: 'Notificaciones',
  lb_notifications_empty: 'No tienes nuevas notificaciones',
  // Contact
  lb_contact_info: 'Información de contacto',
  lb_contact_not_accepted: 'Acepta la solicitud para ver la información de contacto.',
  lb_call: 'Llamada',
  // Dates
  lb_dates: 'Citas',
  lb_dates_count: 'Citas ({count})',
  // Password
  lb_change_password: 'Cambiar contraseña',
  lb_actual_password: 'Contraseña actual',
  lb_new_password: 'Nueva contraseña',
  // Filters
  lb_select_origin: 'Selecciona origen',
  lb_select_specialty: 'Selecciona especialidad',
  lb_select_clinic: 'Selecciona clínica',
  lb_select_insurance: 'Selecciona seguro',
  lb_select_stage: 'Selecciona etapa',
  lb_select_status: 'Selecciona estado',
  lb_select_date: 'Selecciona una fecha',
  lb_select_market: 'Selecciona mercado',
  lb_select_partial_type: 'Selecciona un tipo de elemento',
  // Type
  lb_type: 'Tipo',
  lb_type_something: 'Introduce texto',
  lb_type_data: 'Introduce dato',
  lb_type_year: 'Introduce año',
  lb_type_email: 'Introduce email',
  lb_type_phone: 'Introduce teléfono',
  lb_type_city: 'Introduce ciudad',
  // Intervals
  lb_interval_all: 'Todo el tiempo',
  lb_interval_week: 'Última semana',
  lb_interval_month: 'Último mes',
  lb_interval_year: 'Último año',
  // Common
  lb_cancel: 'Cancelar',
  lb_sent: 'Enviado',
  lb_received: 'Recibido',
  lb_invited: 'Invitado',
  lb_invited_by: 'Invitado por',
  lb_created: 'Creado',
  lb_created_by: 'Creado por',
  lb_name: 'Nombre',
  lb_lastname: 'Apellido',
  lb_origin: 'Origen',
  lb_date: 'Fecha',
  lb_dob: 'Fecha de nacimiento',
  lb_idCrm: 'ID CRM',
  lb_email: 'Email',
  lb_phone: 'Teléfono',
  lb_request: 'Solicitudes',
  lb_insight_description: 'Filtra por fecha y conoce el estado de tus solicitudes a tiempo real.',
  lb_external: 'Paciente Vidasprime',
  lb_gender: 'Género',
  lb_tutor: 'Tutor',
  lb_insurance: 'Seguro',
  lb_hospital: 'Hospital',
  lb_market: 'Mercado',
  lb_info: 'Información',
  lb_contact: 'Contacto',
  lb_contact_person: 'Nombre persona de contacto',
  lb_city: 'Ciudad',
  lb_foundation_year: 'Año de fundación',
  lb_description: 'Descripción',
  lb_acreditations: 'Acreditaciones',
  lb_google_mybusiness_account: 'Cuenta de Google My Business',
  lb_first_price: 'Precio primera consulta',
  lb_default_language: 'Idioma por defecto',
  lb_language: 'Idioma',
  lb_contact_data: 'Datos de contacto',
  lb_vp_request: 'Solicitudes Vidasprime',
  lb_total_request: 'Solicitudes totales',
  lb_total: 'Totales',
  lb_instance: 'Caso',
  lb_instance_number: 'Nº de caso',
  lb_specialty: 'Especialidad',
  lb_pathology: 'Patología',
  lb_doctor: 'Doctor',
  lb_stage: 'Etapa',
  lb_status: 'Estado',
  lb_emergency_degree: 'GRADO DE INTERÉS',
  lb_private_insurance: 'SEGURO MÉDICO PRIVADO',
  lb_recent_reports: 'INFORMES RECIENTES',
  lb_summary: 'Resumen',
  lb_subscription: 'Suscripción',
  lb_is_subscribed: 'Suscripción Activa',
  lb_collaboration: 'Colaboración',
  lb_collaborations: 'Colaboraciones',
  lb_collaborator: 'Colaboradora',
  lb_medical_procedure: 'Procedimiento médico',
  lb_confirm_title: '¿Estás seguro?',
  lb_confirm_text: 'Esta operación no se podrá revertir.',
  lb_bio: 'Bio',
  lb_professional_experience: 'Experiencia profesional',
  lb_others: 'Otros',
  lb_logo: 'Logo',
  lb_new_activity: 'Nueva actividad',
  lb_can_not_undo: 'Si confirmas el usuario se borrará.',
  lb_creation_date: 'Fecha de creación',
  lb_private_insurance_min: 'Seguro privado',
  lb_can_not_undo_health_manager: 'Si confirmas el Health Manager se borrará.',
  lb_can_not_undo_clinic: 'Si confirmas la Clínica se borrará.',
  lb_procedure: 'Procedimiento',
  lb_refer_to: 'Derivado a',
  lb_refer_to_f: 'Derivada a',
  lb_dni_passport: 'DNI o Pasaporte',
  lb_dni_passport_s: 'DNI / Pasaporte',
  lb_clients: 'Clientes',
  lb_online_presence: 'Presencia online',
  lb_videoconsultation: 'Videoconsulta',
  lb_patient_experience: 'Experiencia paciente',
  lb_to: 'a',
  lb_from: 'de',
  // forgot password
  lb_forgot_password: '¿Has olvidado tu contraseña?',
  lb_password: 'Contraseña',
  lb_confirm_password: 'Confirma tu contraseña',
};
