import { STATUS, STAGE } from '../../helpers/ComponentHelper';

export default {
  uuid: null,
  instance: null,
  created: null,
  specialty: null,
  pathology: null,
  medicalProcedure: null,
  requestStage: STAGE.RECEIVED.key,
  status: STATUS.PENDING.key,
  emergencyDegree: null,
  hasInsurance: false,
  hasRecentReports: false,
  patient: {
    id: null,
    created: null,
    name: null,
    lastname: null,
    gender: null,
    origin: null,
    dni: null,
    dob: null,
    tutor: null,
    insurance: null,
    hospital: null,
    email: null,
    phone: null,
    requests: null,
    contactWhatsApp: false,
  },
  healthManager: {
    id: null,
    avatar: null,
    name: null,
    lastname: null,
    market: null,
    request: null,
    patients: null,
    email: null,
    phone: null,
  },
  clinic: {
    id: null,
    avatar: null,
    name: null,
    contactName: null,
    city: null,
    yearFoundation: null,
    description: null,
    credentials: null,
    googleMyBusinessAccount: null,
    email: null,
    firstPrice: null,
    phone: null,
    requests: null,
    vidasPrimeRequest: null,
    patients: null,
  },
  doctor: {
    id: null,
    avatar: null,
    name: null,
    lastname: null,
    specialty: null,
    vidasprimeRequest: null,
    totalRequest: null,
    patientsCount: null,
    bio: null,
    experience: null,
    details: null,
    email: null,
    phone: null,
  },
  healthManagerItems: [],
  documents: [],
  appointments: [],
  notes: [],
  collaborators: [],
  invitations: [],
};
