import getAxiosWithAuthHeader from '../../helpers/AuthHelper';
import {
  getDataWithUuid,
  getParameterUrl,
  getSearchUrl,
} from '../../helpers/ApiHelper';
import DoctorFactory from './DoctorFactory';

const DOCTORS = '/api/doctors';
const SEARCH_DOCTORS = '/api/doctors/search';

class doctorAPI {
  constructor() {
    this.client = getAxiosWithAuthHeader();
  }

  getAllDoctor(currentPage) {
    const parameterUrl = getParameterUrl(DOCTORS, currentPage.page,
      [], [], currentPage.itemsPerPage);

    return this.client
      .get(parameterUrl)
      .then((res) => DoctorFactory.getAllDoctor(res.data))
      .catch((error) => Promise.reject(error));
  }

  filterDoctor(data) {
    const parameterUrl = getParameterUrl(
      SEARCH_DOCTORS,
      data.page,
      data.filters,
      data.ordenation,
    );

    return this.client
      .get(parameterUrl)
      .then((res) => DoctorFactory.getAllDoctor(res.data))
      .catch((error) => Promise.reject(error));
  }

  searchDoctor(filters) {
    const filterUrl = getSearchUrl(SEARCH_DOCTORS, filters);

    return this.client
      .get(filterUrl)
      .then((res) => DoctorFactory.getAllDoctor(res.data))
      .catch((error) => Promise.reject(error));
  }

  createDoctor(data) {
    const idData = getDataWithUuid(data);

    return this.client
      .post(DOCTORS, idData)
      .then(() => idData)
      .catch((error) => Promise.reject(error));
  }

  updateDoctor(requestData) {
    const url = `${DOCTORS}/${requestData.id}`;

    return this.client
      .put(url, requestData.body)
      .then()
      .catch((error) => Promise.reject(error));
  }

  deleteDoctor(id) {
    const url = `${DOCTORS}/${id}`;

    return this.client
      .delete(url)
      .then()
      .catch((error) => Promise.reject(error));
  }
}

export default doctorAPI;
