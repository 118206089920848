import ClinicModel from './ClinicModel';
import PaginationFactory from '../pagination/PaginationFactory';
import UserModel from '../user/UserModel';

class ClinicFactory {
  constructor() {
    this.clinic = {};
  }

  getAllClinic(data) {
    const clinicResponse = {
      clinicList: this.getCollection(data?.collection),
      pagination: PaginationFactory.getPagination(data?.pagination),
    };

    return clinicResponse;
  }

  getClinic(clinic) {
    const user = new UserModel({
      id: clinic.user.id,
      name: clinic.user.name,
      email: clinic.user.email,
      roles: clinic.user.roles,
      avatar: clinic.user.avatar,
      defaultLanguage: clinic.user.defaultLanguage,
    });

    this.clinic = new ClinicModel({
      id: clinic.id,
      city: clinic.city,
      contactName: clinic.contactName,
      credentials: clinic.credentials,
      description: clinic.description,
      allowCollaborations: clinic.allowCollaborations,
      googleMyBusinessAccount: clinic.googleMyBusinessAccount,
      isEnabled: clinic.isEnabled,
      name: clinic.name,
      patients: clinic.patients,
      phone: clinic.phone,
      requests: clinic.requests,
      totalRequests: clinic.totalRequests,
      vidasPrimeRequests: clinic.vidasPrimeRequests,
      yearFoundation: clinic.yearFoundation,
      insurances: clinic.insurances,
      specialties: clinic.specialties,
      hasInsurances: clinic.hasInsurances,
      firstPrice: clinic.firstPrice,
      email: user?.email,
      avatar: user?.avatar,
      defaultLanguage: user?.defaultLanguage,
      role: user?.roles[0],
    });

    return this.clinic;
  }

  getCollection(clinicCollection) {
    const clinicList = clinicCollection.map(
      (clinic) => this.getClinic(clinic),
    );

    return clinicList;
  }
}

export default new ClinicFactory();
