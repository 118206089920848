import {
  getAllSurveyType,
  filterSurveyType,
  searchSurveyType,
  createSurveyType,
  updateSurveyType,
  deleteSurveyType,
} from './surveyTypeActions';

const surveyType = {
  namespaced: true,
  state: () => ({}),
  mutations: {},
  actions: {
    getAllSurveyType,
    filterSurveyType,
    searchSurveyType,
    createSurveyType,
    updateSurveyType,
    deleteSurveyType,
  },
  getters: {
  },
};

export default surveyType;
