import axios from '../domain/services/index';
import StorageManager from './StorageManager';
import i18n from '@/plugins/vue-i18n';

export default function getAxiosWithAuthHeader() {
  const token = StorageManager.getToken();
  axios.defaults.headers.common.locale = i18n.locale;

  if (token !== null) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  }

  return axios;
}
