import KpiAPI from '../../domain/kpi/KpiApi';

export function getAllKpi({ commit }, data) {
  const kpiApi = new KpiAPI();
  const getAllKpis = kpiApi.getAllKpi(data);

  return getAllKpis
    .then((res) => res)
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function getRequests({ commit }, data) {
  const kpiApi = new KpiAPI();

  return kpiApi.getRequests(data)
    .then((res) => res)
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function getAccepted({ commit }, data) {
  const kpiApi = new KpiAPI();

  return kpiApi.getAccepted(data)
    .then((res) => res)
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function getAveragePrice({ commit }, data) {
  const kpiApi = new KpiAPI();

  return kpiApi.getAveragePrice(data)
    .then((res) => res)
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function getAverageTimeRequest({ commit }, data) {
  const kpiApi = new KpiAPI();

  return kpiApi.getAverageTimeRequest(data)
    .then((res) => res)
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}
