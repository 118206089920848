import PaginationFactory from '../pagination/PaginationFactory';
import SurveyModel from './SurveyModel';

class SurveyFactory {
  constructor() {
    this.survey = {};
  }

  getAllSurvey(data) {
    const surveyResponse = {
      surveyList: this.getCollection(data?.collection),
      pagination: PaginationFactory.getPagination(data?.pagination),
    };

    return surveyResponse;
  }

  getSurvey(survey) {
    this.survey = new SurveyModel({
      id: survey.id,
      sendBy: survey.sendBy,
      patient: survey.patient,
      surveyType: survey.surveyType,
      request: survey.request,
      answers: survey.answers,
      sendAt: survey.sendAt,
      answeredAt: survey.answeredAt,
      createdAt: survey.createdAt,
      deletedAt: survey.deletedAt,
    });

    return this.survey;
  }

  getCollection(surveyCollection) {
    const surveyList = surveyCollection.map(
      (survey) => this.getSurvey(survey),
    );
    return surveyList;
  }
}
export default new SurveyFactory();
