export default class requestModel {
  constructor(requestDatae) {
    this.id = requestDatae.id;
    this.caseNumber = requestDatae.caseNumber;
    this.createdAt = requestDatae.createdAt;
    this.patient = requestDatae.patient;
    this.specialty = requestDatae.specialty;
    this.level = requestDatae.level;
    this.pathology = requestDatae.pathology;
    this.medicalProcedure = requestDatae.medicalProcedure;
    this.hasInsurance = requestDatae.hasInsurance;
    this.hasRecentReports = requestDatae.hasRecentReports;
    this.healthManager = requestDatae.healthManager;
    this.clinic = requestDatae.clinic;
    this.requestStage = requestDatae.requestStage;
    this.notes = requestDatae.notes;
    this.appointments = requestDatae.appointments;
    this.documents = requestDatae.documents;
    this.status = requestDatae.status;
    this.doctor = requestDatae.doctor;
    this.doctors = requestDatae.doctors || [];
    this.collaborators = requestDatae.collaborators || [];
    this.invitations = requestDatae.invitations || [];
  }
}
