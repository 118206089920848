<template>
  <div class="Contact">
    <a
      class="WhatsappButton"
      target="_blank"
      :href="action"
    >
      <img :src="image" />
    </a>
    <div class="sp__v__1s5x" />
    <p class="text__pr Contact__Text">{{ label }}</p>
  </div>
</template>

<script>
export default {
  props: {
    image: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    action: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="sass">
  @import './Contact.sass'
</style>
