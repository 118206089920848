import AppointmentsApi from '../../domain/appointments/AppointmentsApi';

export function createAppointments({ commit }, data) {
  const appointmentsApi = new AppointmentsApi();
  const createRequest = appointmentsApi.createAppointments(data);

  return createRequest
    .then()
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function updateAppointments({ commit }, data) {
  const appointmentsApi = new AppointmentsApi();
  const updateRequest = appointmentsApi.updateAppointments(data);

  return updateRequest
    .then()
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function deleteAppointments({ commit }, id) {
  const appointmentsApi = new AppointmentsApi();
  const deleteRequest = appointmentsApi.deleteAppointments(id);

  return deleteRequest
    .then()
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}
