import { setUser, setUserAuth } from './userMutations';
import {
  getCurrentUser,
  getSpecialties,
  isVidasprime,
  isHealthManager,
  isClinic,
  isDoctor,
  isDoctorOrClinic,
  isAllowCollaborations,
  isCollaborator,
  isCollaboratorClinic,
  isClinicRole,
  isDoctorRole,
} from './userGetters';
import { resetPassword, getUser, updateUser } from './userActions';

const user = {
  namespaced: true,
  state: () => ({
    name: null,
    avatar: null,
    city: null,
    market: null,
    specialty: null,
    specialties: [],
    email: null,
    phone: null,
    role: null,
    roles: null,
    allowCollaborations: null,
  }),
  mutations: {
    setUser,
    setUserAuth,
  },
  actions: {
    resetPassword,
    getUser,
    updateUser,
  },
  getters: {
    getCurrentUser,
    getSpecialties,
    isVidasprime,
    isHealthManager,
    isClinic,
    isDoctor,
    isDoctorOrClinic,
    isAllowCollaborations,
    isCollaborator,
    isCollaboratorClinic,
    isClinicRole,
    isDoctorRole,
  },
};

export default user;
