import PatientModel from './PatientModel';
import PaginationFactory from '../pagination/PaginationFactory';

class PatientFactory {
  constructor() {
    this.patient = {};
  }

  getAllPatient(data) {
    const patientResponse = {
      patientList: this.getCollection(data?.collection),
      pagination: PaginationFactory.getPagination(data?.pagination),
    };

    return patientResponse;
  }

  getPatient(patient) {
    this.patient = new PatientModel({
      id: patient.id,
      idCrm: patient.idCrm,
      createdAt: patient.createdAt,
      name: patient.name,
      lastname: patient.lastname,
      gender: patient.gender,
      origin: patient.origin,
      dni: patient.dni,
      dob: patient.dob,
      tutor: patient.tutor,
      hasInsurance: patient.hasInsurance,
      insurance: patient.insurance,
      hospital: patient.hospital,
      email: patient.email,
      phone: patient.phone,
      requests: patient.requests,
      requestList: patient.requestList,
      contactWhatsApp: patient.contactWhatsApp,
      documents: patient.documents,
      totalRequests: patient.totalRequests,
      isExternalPatient: patient.isExternalPatient,
    });

    return this.patient;
  }

  getCollection(patientCollection) {
    const patientList = patientCollection.map(
      (patient) => this.getPatient(patient),
    );

    return patientList;
  }
}

export default new PatientFactory();
