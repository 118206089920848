import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export function getClinics(state) {
  return state;
}

export function getClincPaginationState(state) {
  return state.pagination;
}

export function getClinicListState(state) {
  return state.clinicList;
}

export function hasClinics(state) {
  return state && state.length > 0;
}
