import ClinicApi from '../../domain/clinic/ClinicApi';

export function getAllClinic({ commit }, data) {
  const clinicApi = new ClinicApi();
  const getAllRequest = clinicApi.getAllClinic(data);

  return getAllRequest
    .then((res) => {
      commit('clinic/setClinics', res, { root: true });
      return res;
    })
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function filterClinic({ commit }, data) {
  const clinicApi = new ClinicApi();
  const searchRequest = clinicApi.filterClinic(data);

  return searchRequest
    .then((res) => {
      commit('clinic/setClinics', res, { root: true });
      return res;
    })
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function searchClinic({ commit }, data) {
  const clinicApi = new ClinicApi();
  const searchRequest = clinicApi.searchClinic(data);

  return searchRequest
    .then((res) => res)
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function createClinic({ commit }, data) {
  const clinicApi = new ClinicApi();
  const createRequest = clinicApi.createClinic(data);

  return createRequest
    .then()
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function createCollaboratorClinic({ commit }, data) {
  const clinicApi = new ClinicApi();
  const createCollaborationRequest = clinicApi.createCollaboratorClinic(data);

  return createCollaborationRequest
    .then()
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function updateClinic({ commit }, data) {
  const clinicApi = new ClinicApi();
  const updateRequest = clinicApi.updateClinic(data);

  return updateRequest
    .then()
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function deleteClinic({ commit }, uuid) {
  const clinicApi = new ClinicApi();
  const deleteRequest = clinicApi.deleteClinic(uuid);

  return deleteRequest
    .then()
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}
