import {
  createAppointments,
  updateAppointments,
  deleteAppointments,
} from './appointmentsActions';

const appointments = {
  namespaced: true,
  state: () => ({}),
  mutations: {},
  actions: {
    createAppointments,
    updateAppointments,
    deleteAppointments,
  },
  getters: {},
};

export default appointments;
