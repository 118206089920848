import PaginationFactory from '../pagination/PaginationFactory';
import SurveyTypePartialsTypeModel from './SurveyTypePartialsTypeModel';

class SurveyTypePartialsTypeFactory {
  constructor() {
    this.surveyTypePartialsType = {};
  }

  getAllSurveyTypePartialsType(data) {
    const surveyTypePartialsTypeResponse = {
      surveyTypePartialsTypeList: this.getCollection(data?.collection),
      pagination: PaginationFactory.getPagination(data?.pagination),
    };

    return surveyTypePartialsTypeResponse;
  }

  getSurveyTypePartialsType(surveyTypePartialsType) {
    this.surveyTypePartialsType = new SurveyTypePartialsTypeModel({
      id: surveyTypePartialsType.id,
      surveyTypeId: surveyTypePartialsType.surveyTypeId,
      partialsType: surveyTypePartialsType.partialsType,
      title: surveyTypePartialsType.title,
      description: surveyTypePartialsType.description,
      help: surveyTypePartialsType.help,
      name: surveyTypePartialsType.name,
      placeholder: surveyTypePartialsType.placeholder,
      isRequired: surveyTypePartialsType.isRequired,
      createdAt: surveyTypePartialsType.createdAt,
      updatedAt: surveyTypePartialsType.updatedAt,
      deletedAt: surveyTypePartialsType.deletedAt,
      data: surveyTypePartialsType.data,
    });

    return this.surveyTypePartialsType;
  }

  getCollection(surveyCollection) {
    const surveyTypePartialsTypeList = surveyCollection.map(
      (surveyTypePartialsType) => this.getSurveyTypePartialsType(surveyTypePartialsType),
    );
    return surveyTypePartialsTypeList;
  }
}
export default new SurveyTypePartialsTypeFactory();
