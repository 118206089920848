export const LABEL = {
  YOU: 'You',
  MAX: 'Max',
  MIN: 'Min',
  AVERAGE: 'Avg',
  ACCEPTED: 'accepted',
  PENDING: 'pending',
  REJECTED: 'rejected',
  TOTAL: 'total',
  WITH_APPOINTMENT: 'with appointment',
  CONFIRMED_APPOINTMENT: 'confirmed appointment',
  WITHOUT_APPOINTMENT: 'without appointment',
};

const COLORS = {
  YOU: '#4E20E4',
  MAX: '#394A64',
  MIN: '#A4B0C0',
  AVERAGE: '#73839C',
  ACCEPTED: '#68CC45',
  PENDING: '#E08700',
  REJECTED: '#D83556',
  WITH_APPOINTMENT: '#2850FF',
  CONFIRMED_APPOINTMENT: '#4E20E4',
  WITHOUT_APPOINTMENT: '#73839C',
};

export function getColorByLabel(label) {
  switch (label) {
    case LABEL.YOU:
      return COLORS.YOU;
    case LABEL.MAX:
      return COLORS.MAX;
    case LABEL.MIN:
      return COLORS.MIN;
    case LABEL.AVERAGE:
      return COLORS.AVERAGE;
    case LABEL.ACCEPTED:
    case LABEL.TOTAL:
      return COLORS.ACCEPTED;
    case LABEL.PENDING:
      return COLORS.PENDING;
    case LABEL.REJECTED:
      return COLORS.REJECTED;
    case LABEL.WITH_APPOINTMENT:
      return COLORS.WITH_APPOINTMENT;
    case LABEL.CONFIRMED_APPOINTMENT:
      return COLORS.CONFIRMED_APPOINTMENT;
    case LABEL.WITHOUT_APPOINTMENT:
      return COLORS.WITHOUT_APPOINTMENT;
    default:
      return '';
  }
}

export function convertDataToGraphSeries({ options, labels }) {
  const seriesArray = [];

  labels.forEach((label, index) => {
    const serieObject = {
      name: `cp_${label.toLowerCase()}`,
      data: [{ x: options[index], y: 0 }],
      color: getColorByLabel(label),
    };

    seriesArray.push(serieObject);
  });
  const youIndex = seriesArray.findIndex((serie) => serie.name === 'cp_you');
  const youValue = seriesArray[youIndex];
  seriesArray.splice(youIndex, 1);
  seriesArray.push(youValue);

  return seriesArray;
}
