import SurveyTypeApi from '../../domain/surveyType/SurveyTypeApi';

export function getAllSurveyType({ commit }, data) {
  const surveyTypeApi = new SurveyTypeApi();
  const getAllRequest = surveyTypeApi.getAllSurveyType(data);

  return getAllRequest
    .then((res) => res)
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function filterSurveyType({ commit }, data) {
  const surveyTypeApi = new SurveyTypeApi();
  const searchRequest = surveyTypeApi.filterSurveyType(data);

  return searchRequest
    .then((res) => res)
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function searchSurveyType({ commit }, data) {
  const surveyTypeApi = new SurveyTypeApi();
  const searchRequest = surveyTypeApi.searchSurveyType(data);

  return searchRequest
    .then((res) => res)
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function createSurveyType({ commit }, data) {
  const surveyTypeApi = new SurveyTypeApi();
  const createRequest = surveyTypeApi.createSurveyType(data);

  return createRequest
    .then()
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function updateSurveyType({ commit }, data) {
  const surveyTypeApi = new SurveyTypeApi();
  const updateRequest = surveyTypeApi.updateSurveyType(data);

  return updateRequest
    .then()
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function deleteSurveyType({ commit }, uuid) {
  const surveyTypeApi = new SurveyTypeApi();
  const deleteRequest = surveyTypeApi.deleteSurveyType(uuid);

  return deleteRequest
    .then()
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}
