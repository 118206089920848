import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export function getInvitationState(state) {
  return state.invitations;
}

export function getInvitationPaginationState(state) {
  return state.invitations.pagination;
}

export function getInvitationListState(state) {
  return state.invitations.invitationList;
}
