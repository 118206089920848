<template>
  <div class="Pagination">
    <PaginationCounter
      :pagination="pagination"
    />
    <b-pagination
      v-model="currentPage"
      :per-page="perPage"
      :total-rows="rows"
      @change="handleRequest"
    />
  </div>
</template>

<script>
import PaginationCounter from './PaginationCounter.vue';

export default {
  props: {
    pagination: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      currentPage: this.pagination?.currentPage,
      perPage: this.pagination?.itemsPerPage,
      rows: this.pagination?.totalItems,
    };
  },
  mounted() {},
  methods: {
    handleRequest(page) {
      this.$emit('handleRequest', page);
    },
  },
  watch: {
    pagination(newValue) {
      this.currentPage = newValue.currentPage;
    },
  },
  components: {
    PaginationCounter,
  },
};
</script>

<style lang="sass" scoped>
  @import './Pagination.sass'
</style>
