<template>
  <div class="Table">
    <b-table
      :items="items"
      :fields="columns"
      hover
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      selectable
      select-mode="single"
      @row-selected="onRowSelected"
    >
      <template #cell(name)="data">
        {{ `${data.item.name} ${data.item.lastname}` }}
      </template>
      <template #cell(createdAt)="data">
        <span class="text__sr text-secondary">{{ data.item.createdAt | formatDate }}</span>
      </template>
      <template #cell(collaboration)="data">
        <Badge
          v-if="isCollaboration(data)"
          :variant="status.COLLABORATION.key"
        >
          {{$i18n.t(status.COLLABORATION.value)}}
        </Badge>
      </template>
      <template #cell(origin)="data">
        <span class="text__sr text-secondary">{{ getOrigin(data.item.origin) }}</span>
      </template>
      <template #cell(dob)="data">
        <span class="text__sr text-secondary">{{ data.item.dob | formatDate }}</span>
      </template>
      <template #cell(email)="data">
        <span class="text__sr text-secondary">
          {{ getValidEmailFormat(data.item.id, data.item.email) }}
        </span>
      </template>
      <template #cell(phone)="data">
        <span class="text__sr text-secondary">{{ data.item.phone }}</span>
      </template>
      <template #cell(totalRequests)="data">
        <span class="text__sr text-secondary">{{ data.item.totalRequests }}</span>
      </template>
      <template #cell(isExternalPatient)="data">
        <span class="text__sr text-secondary">
          <img
            v-if="data.item.isExternalPatient"
            :width="size"
            :height="size"
            src="@/assets/actions/close.svg"
          />
          <img
            v-else
            :width="size"
            :height="size"
            src="@/assets/actions/done.svg"
          />
        </span>
      </template>
      <template #cell(actions)="data">
        <div v-if="!isCollaborator">
          <IconActionsGroup
          @edit="showUpdateForm"
          @delete="showDeleteModal"
          :objectData="data.item"
          :canCopy="false"
          :canDelete="canDelete"
          />
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import IconActionsGroup from '@/components/atoms/Icon/IconActionsGroup/IconActionsGroup.vue';
import { getImageFormatted, STATUS } from '@/helpers/ComponentHelper';
import { checkEmailFormat } from '@/helpers/UserHelper';
import i18n from '@/plugins/vue-i18n';
import Badge from '@/components/atoms/Badge/Badge.vue';

export default {
  props: {
    patientItems: {
      type: Array,
      default: () => [],
    },
    isVidasprime: {
      type: Boolean,
      default: false,
    },
    canDelete: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    vidasPrime() {
      return this.isVidasprime;
    },
    isCollaborator() {
      return this.$store.getters['user/isCollaborator'];
    },
    columns() {
      const columns = [
        {
          key: 'createdAt',
          label: i18n.t('lb_created'),
          sortable: true,
        },
        {
          key: 'name',
          label: i18n.t('lb_name'),
          sortable: true,
        },
        {
          key: 'collaboration',
          label: this.$i18n.t('lb_type'),
        },
        {
          key: 'idCrm',
          label: i18n.t('lb_idCrm'),
          sortable: true,
          requiresVidasprime: true,
        },
        {
          key: 'origin',
          label: i18n.t('lb_origin'),
          sortable: true,
        },
        {
          key: 'dob',
          label: i18n.t('lb_dob'),
          sortable: true,
        },
        {
          key: 'email',
          label: i18n.t('lb_email'),
          sortable: true,
        },
        {
          key: 'phone',
          label: i18n.t('lb_phone'),
          sortable: true,
        },
        {
          key: 'totalRequests',
          label: i18n.t('lb_request'),
          sortable: true,
        },
        {
          key: 'isExternalPatient',
          label: i18n.t('lb_external'),
          sortable: true,
        },
        {
          key: 'actions',
          label: '',
        },
      ];
      // If the user isn't an admin, filter out fields that require auth.
      if (!this.vidasPrime) {
        return columns.filter((field) => !field.requiresVidasprime);
      }
      return columns;
    },
  },
  data() {
    return {
      sortBy: 'case',
      sortDesc: false,
      items: this.patientItems,
      status: STATUS,
    };
  },
  methods: {
    onRowSelected(items) {
      this.$emit('handleSelectRow', items[0]);
    },

    showUpdateForm(data) {
      this.$emit('showUpdateForm', data);
    },

    showDeleteModal(data) {
      this.$emit('showDeleteModal', data);
    },

    getImage(image) {
      return getImageFormatted(image);
    },

    getOrigin(origin) {
      return origin !== null ? origin.name : null;
    },

    isCollaboration(data) {
      let isCollaborating = false;
      let isPrincipal = false;

      const isCollaboration = data.item.requests.reduce((collaboration, request) => {
        const userId = this.$store.getters['user/getCurrentUser'].uuid;

        if (request.clinic.id === userId || isPrincipal) {
          isPrincipal = true;
          // If is principal in any request then, is not collaboration
          return false;
        }

        request.collaborators.forEach((clinic) => {
          if (clinic.id === userId) {
            isCollaborating = true;
          }
        });

        return !isPrincipal && isCollaborating;
      }, false);

      return isCollaboration;
    },

    getValidEmailFormat(id, email) {
      if (id && email) {
        return checkEmailFormat(id, email)
          ? email
          : null;
      }

      return null;
    },
  },
  watch: {
    sortBy(newValue) {
      this.$emit('handleSortDesc', newValue);
    },

    sortDesc(newValue) {
      if (newValue === true) {
        this.$emit('handleSortDesc', this.sortBy);
      } else {
        this.$emit('handleSortAsc', this.sortBy);
      }
    },
  },
  components: {
    IconActionsGroup,
    Badge,
  },
};
</script>

<style lang="sass" scoped>
@import '../Table.sass'
</style>
