<template>
    <RequestDetailForm
      v-if="request !== null"
      :request="request"
      @handleChangeRequest="handleChangeRequest"
    />
</template>

<script>
import RequestDetailForm from './RequestDetailForm.vue';
import request from '@/components/atoms/Mocks/request.json';

export default {
  props: {
    id: {
      type: String,
      default: null,
    },
    notification: {
      type: Object,
      default: () => {},
    },
    requestId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      request: null,
    };
  },
  mounted() {
    this.getRequest();
  },
  methods: {
    handleChangeRequest(uuid, status) {
      this.$emit('handleChangeRequest', uuid, status);
    },

    handleCancel() {
      this.$emit('handleCancel');
    },
    getRequest() {
      this.$store.dispatch('request/getRequest', {
        requestId: this.notification.request.id,
      })
        .then((res) => {
          this.request = res;
        })
        .catch(() => {
        });
      this.request = request;
    },
  },
  components: {
    RequestDetailForm,
  },
};
</script>
