<template>
  <div>
    <div class="sp__v__3x"/>
    <HeadingBar
      :title="$i18n.t('cp_patient_info_title')"
      :text="$i18n.t('cp_patient_info_text')"
      :isSmall="true"
    />

    <hr />

    <div class="sp__v"/>
    <LabelGroup
      :label="`${$i18n.t('lb_name')}:`"
      :text="patient.name"
    />
    <div class="sp__v"/>
    <LabelGroup v-if="isVidasprime"
      :label="`${$i18n.t('lb_idCrm')}:`"
      :text="patient.idCrm"
    />
    <div class="sp__v"/>
    <LabelGroup
      :label="`${$i18n.t('lb_lastname')}:`"
      :text="patient.lastname"
    />
    <div class="sp__v"/>
    <LabelGroup
      :label="`${$i18n.t('lb_origin')}:`"
      :text="getOrigin"
    />
    <div class="sp__v"/>
    <LabelGroup
      :label="`${$i18n.t('lb_dni_passport_s')}:`"
      :text="patient.dni"
    />
    <div class="sp__v"/>
    <LabelGroup
      :label="`${$i18n.t('lb_gender')}:`"
      :text="getGender(patient.gender)"
    />
    <div class="sp__v"/>
    <LabelGroup
      :label="`${$i18n.t('lb_tutor')}:`"
      :text="patient.tutor"
    />
    <div class="sp__v"/>
    <LabelGroup
      :label="`${$i18n.t('lb_insurance')}:`"
      :text="getInsurance"
    />
    <div class="sp__v"/>
    <LabelGroup
      :label="`${$i18n.t('lb_doctors')}:`"
    />
    <ul id="doctors">
      <li class="text__sr" v-for="(item) in getDoctorNames" v-bind:key="item">
        {{ item.name }} {{ item.lastname }}
      </li>
    </ul>
    <LabelGroup
      :label="`${$i18n.t('lb_hospital')}:`"
      :text="patient.hospital"
    />
    <div class="sp__v"/>
    <LabelGroup
      :label="`${$i18n.t('lb_dob')}:`"
      :text="patient.dob | formatDate"
    />
    <div class="sp__v"/>

  </div>
</template>

<script>
import LabelGroup from '@/components/atoms/Label/LabelGroup.vue';
import HeadingBar from '@/components/molecules/HeadingBar/HeadingBar.vue';
import { getGender } from '@/helpers/UserHelper';
// import doctor from "@/store/doctor/doctor";

export default {
  props: {
    patient: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    getGender(value) {
      return getGender(value);
    },
  },
  computed: {
    isVidasprime() {
      return this.$store.getters['user/isVidasprime'];
    },
    getOrigin() {
      return this.patient.origin !== null ? this.patient.origin.name : null;
    },

    getInsurance() {
      return this.patient.insurance !== null ? this.patient.insurance.name : null;
    },
    getDoctorNames() {
      const doctors = [];
      this.patient.requests.forEach((request) => {
        request.doctors.forEach((doctor) => {
          doctors.push(doctor);
        });
      });

      return doctors;
    },
  },
  components: {
    LabelGroup,
    HeadingBar,
  },
};
</script>
