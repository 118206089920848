import getAxiosWithAuthHeader from '../../helpers/AuthHelper';
import PartialsTypeFactory from './PartialsTypeFactory';

const PARTIALS_TYPES = '/api/partials/types';

class partialsTypeAPI {
  constructor() {
    this.partialType = getAxiosWithAuthHeader();
  }

  getAllPartialsTypes() {
    return this.partialType
      .get(PARTIALS_TYPES)
      .then((res) => res.data.collection.map(
        (partialsType) => PartialsTypeFactory.getPartialsType(partialsType),
      ))
      .catch((error) => Promise.reject(error));
  }
}

export default partialsTypeAPI;
