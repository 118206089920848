<template>
  <ModalAction
    :id="id"
    :cancelLabel="$i18n.t('btn_close')"
    @handleCancel="handleCancel"
    isCentered
    onlyCancel
    :title="getErrors.title"
  >
    <template
      v-for="(error,index) in getErrors.list"
    >
      <p class="text__pr text-danger" :key="index">
        {{ `${error.message}` }}
      </p>
    </template>
  </ModalAction>
</template>

<script>
import { mapGetters } from 'vuex';
import ModalAction from '@/components/molecules/Modal/ModalAction.vue';

export default {
  name: 'ErrorModal',
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  methods: {
    handleCancel() {
      this.$store.commit('error/cleanError');
    },
  },
  computed: {
    ...mapGetters({
      getErrors: 'error/getErrors',
    }),
  },
  components: {
    ModalAction,
  },
};
</script>
