<template>
  <div :id="id" class="ComingSoon">
    <div class="ComingSoon__block w-card">
      <div class="d-flex flex-column">
        <p class="text__psb">
          {{ label }}
        </p>
        <div class="sp__v__1s5x"></div>
        <p class="text__pr">
          {{ text }}
        </p>
        <slot></slot>
      </div>
      <button @click.prevent="handleClose">
        <img src="@/assets/actions/close.svg" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
    },
    label: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
  },
  methods: {
    handleClose() {
      this.$emit('handleClose');
    },
  },
};
</script>

<style lang="sass">
@import './ComingSoon.sass'
</style>
