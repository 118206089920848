import PaginationFactory from '../pagination/PaginationFactory';
import SurveyTypeModel from './SurveyTypeModel';

class SurveyTypeFactory {
  constructor() {
    this.surveyType = {};
  }

  getAllSurveyType(data) {
    const surveyTypeResponse = {
      surveyTypeList: this.getCollection(data?.collection),
      pagination: PaginationFactory.getPagination(data?.pagination),
    };

    return surveyTypeResponse;
  }

  getSurveyType(surveyType) {
    this.surveyType = new SurveyTypeModel({
      id: surveyType.id,
      name: surveyType.name,
      templatePath: surveyType.templatePath,
      totalSurveys: surveyType.totalSurveys,
      answered: surveyType.answered,
      createdByData: surveyType.createdByData,
      createdAt: surveyType.createdAt,
      updatedAt: surveyType.updatedAt,
      deletedAt: surveyType.deletedAt,
      language: surveyType.language,
      surveyTypesPartialsTypes: surveyType.surveyTypesPartialsTypes,
    });

    return this.surveyType;
  }

  getCollection(surveyCollection) {
    const surveyTypeList = surveyCollection.map(
      (surveyType) => this.getSurveyType(surveyType),
    );
    return surveyTypeList;
  }
}
export default new SurveyTypeFactory();
