import esComponents from './es-components';
import eslabels from './es-labels';
import esButtons from './es-buttons';
import esValidations from './es-validations';
import esTexts from './es-texts';
import esErrors from './es-errors';

const messages = {
  ...esComponents,
  ...eslabels,
  ...esButtons,
  ...esValidations,
  ...esTexts,
  ...esErrors,
};

export default messages;
