import { getUserData, getRole } from '../../helpers/UserHelper';

export function setUser(state, user) {
  Object.assign(state, user);
  const newRole = getRole(user.roles);
  if (newRole) state.role = newRole;
}

export function setUserAuth(state, authState) {
  if (authState.token) {
    const userData = getUserData(authState.token);

    setUser(state, userData);
  }
}
