<template>
  <ClinicForm
    id="create-clinic"
    :title="$i18n.t('lb_new_clinic')"
    @handleOk="create"
    @handleCancel="handleCancel"
    :isLoading="isLoading"
  />
</template>

<script>
import ClinicForm from '@/components/organisms/Clinic/ClinicForm.vue';
import { getAvatarBase64 } from '@/helpers/UserHelper';

export default {
  data() {
    return {
      isLoading: false,
    };
  },
  mounted() {
    this.$bvModal.show('create-clinic');
  },
  methods: {
    create(data) {
      const newData = {
        ...(this.getAvatar(data.avatar)),
        name: data.name,
        contactName: data.contactName,
        city: data.city,
        yearFoundation: data.yearFoundation,
        description: data.description,
        credentials: data.credentials,
        hasInsurances: data.hasInsurances,
        insurances: data.insurances,
        allowCollaborations: data.allowCollaborations,
        googleMyBusinessAccount: data.googleMyBusinessAccount,
        email: data.email,
        phone: data.phone,
        specialties: data.specialties,
        defaultLanguage: data?.defaultLanguage ?? '1',
      };

      this.isLoading = true;

      this.$store.dispatch('clinic/createClinic', newData)
        .then((res) => {
          const resData = data;
          resData.id = res.id;
          this.$emit('addClinic', resData);
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },

    getAvatar(avatar) {
      return getAvatarBase64(avatar);
    },

    handleCancel() {
      this.$emit('handleCancel');
    },
  },
  components: {
    ClinicForm,
  },
};
</script>
