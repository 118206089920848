import PartialsTypeModel from '@/domain/partialsType/PartialsTypeModel';

class PartialsTypeFactory {
  constructor() {
    this.partialsType = {};
  }

  getPartialsType(partialsType) {
    this.partialsType = {
      id: partialsType.id,
      name: partialsType.name,
      path: partialsType.path,
    };
    return new PartialsTypeModel(this.partialsType);
  }
}

export default new PartialsTypeFactory();
