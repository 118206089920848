<template>
  <private-template>
    <template v-slot:private-template>
      <div class="sp__v__2x"/>
      <ClinicDetail
        v-if="showDetail"
        :clinic="clinic"
        @showUpdateForm="showDetailUpdateForm"
        @showDeleteConfirm="showDetailDeleteModal"
        @hideDetail="hideDetailView"
      />
      <div v-if="!showDetail">
        <HeadingBar
          :title="$i18n.t('lb_clients')"
          :text="$i18n.t('txt_clients')"
        />
        <div class="buttonContainer">
          <b-button
            @click.prevent="showCreateModal"
            variant="primary"
            size="sm"
          >
            {{ `+ ${$i18n.t('btn_new_f')}` }}
          </b-button>
        </div>
        <div class="sp__v__2x"/>
        <TableGroup
          id="clinic"
          :items="clinicList"
          :isLoading="isTableLoading"
          :pagination="pagination"
          :searchText="searchText"
          :filterCount="getFilterCount"
          @handleSearch="handleSearchByInput"
          @handleSearchByFilter="handleSearchByFilter"
          @handlePaginate="handleFilterSearch"
          @handleCleanSearch="handleCleanSearch"
        >
          <template v-slot:content>
            <ClinicFilters
              @handlerChangeCityFilter="handlerChangeCityFilter"
              :filters="filters"
            />
          </template>

          <ClinicTable
            :clinicItems="clinicList"
            @showUpdateForm="showUpdateForm"
            @showDeleteModal="showDeleteModal"
            @handleSelectRow="handleSelectRow"
          />
        </TableGroup>
      </div>
        <CreateClinic
          v-if="showCreateClinic"
          @addClinic="addClinic"
          @handleCancel="closeCreateModal"
        />
        <UpdateClinic
          @updateClinic="updateClinic"
          :clinic="clinic"
        />
        <DeleteClinic
          @deleteClinic="deleteClinic"
          ref="deleteClinic"
        />
    </template>
  </private-template>
</template>

<script>
import PrivateTemplate from '@/components/organisms/PrivateTemplate.vue';
import HeadingBar from '@/components/molecules/HeadingBar/HeadingBar.vue';
import CreateClinic from '@/components/organisms/Clinic/CreateClinic.vue';
import UpdateClinic from '@/components/organisms/Clinic/UpdateClinic.vue';
import TableGroup from '@/components/molecules/Tables/TableGroup/TableGroup.vue';
import ClinicTable from '@/components/molecules/Tables/ClinicTable/ClinicTable.vue';
import DeleteClinic from '@/components/organisms/Clinic/DeleteClinic.vue';
import ClinicDetail from '@/components/organisms/Clinic/ClinicDetail.vue';
import ClinicFilters from '@/components/organisms/Clinic/ClinicFilters.vue';
import { getSortType, SORT_TYPE, getFiltersCount } from '@/helpers/ComponentHelper';
import { getOrdenation, getFiltersGroup } from '@/helpers/ApiHelper';
import { getAvatarByProps } from '@/helpers/UserHelper';

export default {
  data() {
    return {
      // clinicList: [],
      // pagination: {},
      clinic: {
        id: null,
        avatar: null,
        name: null,
        contactName: null,
        city: null,
        yearFoundation: null,
        description: null,
        credentials: null,
        hasInsurances: null,
        insurances: [],
        allowCollaborations: null,
        googleMyBusinessAccount: null,
        email: null,
        phone: null,
        firstPrice: null,
        requests: null,
        totalRequests: null,
        vidasPrimeRequests: null,
        patients: null,
        specialties: [],
        defaultLanguage: null,
      },
      showCreateClinic: false,
      showDetail: false,
      filters: {
        city: null,
      },
      isTableLoading: false,
      searchText: null,
      sort: {
        name: null,
        type: null,
      },
    };
  },
  created() {
    this.handleSearch();
  },
  methods: {
    handleSearch(page = 1) {
      this.isTableLoading = true;

      this.$store.dispatch('clinic/getAllClinic', {
        page,
        itemsPerPage: 10,
      })
        .then(() => {
          // this.clinicList = res.clinicList;
          // this.pagination = res.pagination;
          this.isTableLoading = false;
        })
        .catch(() => {
          this.isTableLoading = false;
        });
    },

    getFilters() {
      const filtersGroup = [];

      if (this.searchText !== null) {
        filtersGroup.push(
          `name:${this.searchText}`,
        );
      }

      return getFiltersGroup(filtersGroup, this.filters);
    },

    getRequestData(page = 1) {
      return {
        page,
        filters: this.getFilters(),
        ordenation: getOrdenation(this.sort),
      };
    },

    handleFilterSearch(page = 1) {
      this.isTableLoading = true;

      this.$store.dispatch('clinic/filterClinic', this.getRequestData(page))
        .then(() => {
          // this.clinicList = res.clinicList;
          // this.pagination = res.pagination;
          this.isTableLoading = false;
        })
        .catch(() => {
          this.isTableLoading = false;
        });
    },

    handleSelectRow(item) {
      this.setClinic(item);

      this.showDetailView();
    },

    showUpdateForm(data) {
      this.setClinic(data);

      this.$bvModal.show('update-clinic');
    },

    showDetailUpdateForm(data) {
      this.setClinic(data);

      this.$bvModal.show('update-clinic');
    },

    showDeleteModal(data) {
      this.$refs.deleteClinic.setId(data.id);
      this.$bvModal.show('delete-clinic');
    },

    showDetailDeleteModal() {
      this.$refs.deleteClinic.setId(this.clinic.id);
      this.$bvModal.show('delete-clinic');
    },

    showCreateModal() {
      this.showCreateClinic = true;
    },

    closeCreateModal() {
      this.showCreateClinic = false;
    },

    showDetailView() {
      this.showDetail = true;
    },

    hideDetailView() {
      this.showDetail = false;
    },

    addClinic(data) {
      const newClinic = {
        id: data.id,
        name: data.name,
        lastname: data.lastname,
        market: data.market,
        avatar: data.avatar ? URL.createObjectURL(data.avatar.file) : null,
        phone: data.phone,
        email: data.email,
        contactName: data.contactName,
        city: data.city,
        yearFoundation: data.yearFoundation,
        description: data.description,
        credentials: data.credentials,
        hasInsurances: data.hasInsurances,
        insurances: data.insurances,
        allowCollaborations: data.allowCollaborations,
        googleMyBusinessAccount: data.googleMyBusinessAccount,
        requests: data.requests,
        firstPrice: data.firstPrice,
        totalRequests: data.totalRequests,
        vidasPrimeRequests: data.vidasPrimeRequests,
        patients: data.patients,
        specialties: data.specialties,
        defaultLanguage: data.defaultLanguage,
      };

      this.clinicList.unshift(newClinic);
      this.incrementPagination();

      this.closeCreateModal();
    },

    incrementPagination() {
      this.pagination.totalItems += 1;
      this.pagination.itemsPerPage += 1;
    },

    updateClinic(data) {
      this.$bvModal.hide('update-clinic');
      this.setClinic(data);
      this.handleFilterSearch(this.page);
    },

    deleteClinic(id) {
      this.$bvModal.hide('delete-clinic');

      if (this.showDetail) {
        this.hideDetailView();
      }

      for (let i = 0; this.clinicList.length; i += 1) {
        if (this.clinicList[i].id === id) {
          this.clinicList.splice(i, 1);
          this.handleSearch(this.pagination.currentPage);
          break;
        }
      }
    },

    handleSearchByInput(text) {
      if (text !== null) {
        this.searchText = text;
        this.handleFilterSearch();
      } else {
        this.searchText = null;
      }
    },

    handleSearchByFilter() {
      this.handleFilterSearch();
      this.$root.$emit('bv::toggle::collapse', 'clinic-sidebar');
    },

    handleSortAsc(param) {
      this.sort.type = this.sort.name === param ? getSortType(this.sort.type) : SORT_TYPE.ASC;
      this.sort.name = param;

      this.handleFilterSearch();
    },

    handlerChangeCityFilter(value) {
      this.filters.city = value;
    },

    setClinic(data) {
      this.clinic = {
        id: data.id,
        avatar: data.avatar,
        name: data.name,
        contactName: data.contactName,
        city: data.city,
        yearFoundation: data.yearFoundation,
        description: data.description,
        credentials: data.credentials,
        hasInsurances: data.hasInsurances,
        insurances: data.insurances,
        allowCollaborations: data.allowCollaborations,
        googleMyBusinessAccount: data.googleMyBusinessAccount,
        email: data.email,
        phone: data.phone,
        firstPrice: data.firstPrice,
        requests: data.requests,
        totalRequests: data.totalRequests,
        vidasPrimeRequests: data.vidasPrimeRequests,
        patients: data.patients,
        specialties: data.specialties,
        defaultLanguage: data.defaultLanguage,
        role: data.role,
      };
    },

    handleCleanSearch() {
      this.filters.city = null;
      this.searchText = null;
      this.sort.name = null;
      this.sort.type = null;

      this.handleSearch();
    },

    getAvatar(avatar) {
      return getAvatarByProps(avatar);
    },
  },
  computed: {
    clinicList: {
      get() { return this.$store.state.clinic.clinicList; },
      set(value) { this.$store.commit('clinic/setClinicList', value); },
    },
    pagination: {
      get() { return this.$store.state.clinic.pagination; },
      set(value) { this.$store.commit('clinic/setClinicPagination', value); },
    },
    getFilterCount() {
      return getFiltersCount(this.filters);
    },
  },
  components: {
    PrivateTemplate,
    HeadingBar,
    CreateClinic,
    UpdateClinic,
    TableGroup,
    ClinicTable,
    DeleteClinic,
    ClinicDetail,
    ClinicFilters,
  },
};
</script>
