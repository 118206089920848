import KpiModel from './KpiModel';
import PaginationFactory from '../pagination/PaginationFactory';
import { getColorByLabel } from '../../helpers/GraphHelper';

class KpiFactory {
  constructor() {
    this.kpi = {};
  }

  getAllKpi(data) {
    const kpiResponse = {
      kpiList: this.getCollection(data?.collection),
      pagination: PaginationFactory.getPagination(data?.pagination),
    };

    return kpiResponse;
  }

  getKpi(kpi) {
    this.kpi = new KpiModel({
      amount: kpi.amount,
      name: kpi.name,
      percentage: kpi.percentage,
    });

    return this.kpi;
  }

  getCollection(kpiCollection) {
    const patientList = kpiCollection.map(
      (kpi) => this.getKpi(kpi),
    );

    return patientList;
  }

  getRequests(data) {
    this.kpi = data.collection;
    this.kpi.all = Object.keys(data.collection.all).map((status) => {
      const value = data.collection.all[status];
      const kpi = {
        label: status,
        value,
      };

      return kpi;
    });

    this.kpi.columns.options = this.kpi.columns.options.slice(1).map((option) => {
      const label = option.name.toLowerCase();
      return {
        name: `lb_requests_${label}`,
        label,
        data: option.data,
        color: getColorByLabel(label),
      };
    });
    return this.kpi;
  }

  getAccepted(data) {
    this.kpi = data.collection;
    return this.kpi;
  }

  getAveragePrice(data) {
    this.kpi = data.collection;
    return this.kpi;
  }

  getAverageTimeRequest(data) {
    this.kpi = data.collection;
    return this.kpi;
  }
}

export default new KpiFactory();
