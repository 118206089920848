import Vue from 'vue';

export function setClinics(state, clinic) {
  Vue.set(this.state, 'clinic', clinic);
}

export function setClinicPagination(state, pagination) {
  state.pagination = pagination;
}

export function setClinicList(state, clinicList) {
  state.clinicList = clinicList;
}
