<template>
  <SideBar
    :class="showNotification ? 'SideForm__bar' : 'SideForm__notifications'"
    :id="id"
    @onHiddenSidebar="handleCancel"
  >
    <div
       v-if="!showNotification"
       class="NotificationSideBar"
      >
      <p class="text__psbb">{{ $i18n.t('lb_notifications') }}</p>
      <div class="sp__v__3x" />
      <NotificationList
        v-if="show"
        :notifications="notifications"
        @handleShowNotification="handleShowNotification"
      />
      <Spinner
        :isLoading="isLoading"
        variant="primary"
      />
    </div>
     <SingleRequestDetail
      v-if="this.notificationId !== null"
      :id="notificationId"
      :notification="getNotification(notificationId)"
      @handleCloseNotification="handleCloseNotification"
      @handleChangeRequest="handleChangeRequest"
    />
  </SideBar>
</template>

<script>
import SideBar from '@/components/atoms/SideBar/SideBar.vue';
import SingleRequestDetail from '@/components/organisms/Request/SingleRequestDetail.vue';
import NotificationList from './NotificationList.vue';
import Spinner from '@/components/atoms/Spinner/Spinner.vue';

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
      default: false,
    },
    notifications: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      notificationId: null,
    };
  },
  methods: {
    handleChangeRequest(id, status) {
      this.$root.$emit('bv::toggle::collapse', 'notificationList-sidebar');
      this.$emit('handleChangeRequest', id, status);
    },
    getNotification(id) {
      this.notifications.forEach((notification) => {
        if (notification.id === id) {
          this.notification = notification;
        }
      });
      return this.notification;
    },
    handleShowNotification(id) {
      this.notificationId = id;
    },

    handleCloseNotification() {
      this.notificationId = null;
    },

    handleCancel() {
      this.handleCloseNotification();
    },
  },
  computed: {
    showNotification() {
      return this.notificationId !== null;
    },
  },
  components: {
    SideBar,
    SingleRequestDetail,
    NotificationList,
    Spinner,
  },
};
</script>

<style lang="sass">
@import './NotificationSideBar.sass'
</style>
