<template>
  <div class="Table">
    <b-table
      :items="items"
      :fields="fields"
      striped
      borderless
      hover
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
    >
      <template #cell(created)="data">
        <span class="text__sr text-secondary">{{ data.item.created }}</span>
      </template>
      <template #cell(name)="data">
        <span class="text__sb text-primary">{{ data.item.name }}</span>
      </template>
      <template #cell(origin)="data">
        <span class="text__sr text-secondary">{{ data.item.origin }}</span>
      </template>
      <template #cell(specialty)="data">
        <span class="text__sr text-secondary">{{ data.item.specialty }}</span>
      </template>
      <template #cell(derivative)="data">
        <IconGroup
          :icon="data.item.derivative.icon"
          :label="data.item.derivative.name"
        />
      </template>
      <template #cell(pathology)="data">
        <span class="text__sr text-secondary">{{ data.item.pathology }}</span>
      </template>
      <template #cell(source)="">
        <Badge variant="neutral">
          vidasprime
        </Badge>
      </template>
      <template #cell(phase)="data">
        <span class="text__sr text-secondary">{{ data.item.phase }}</span>
      </template>
      <template #cell(status)="data">
        <Badge :variant="data.item.status">
          {{ data.item.status }}
        </Badge>
      </template>
      <template #cell(actions)="">
        <IconActionsGroup
          @edit="showEditForm"
          @copy="showEditForm"
          @delete="showEditForm"
        />
      </template>
    </b-table>
  </div>
</template>

<script>
import Badge from '@/components/atoms/Badge/Badge.vue';
import IconGroup from '@/components/atoms/Icon/IconGroup/IconGroup.vue';
import IconActionsGroup from '@/components/atoms/Icon/IconActionsGroup/IconActionsGroup.vue';

export default {
  data() {
    return {
      sortBy: 'case',
      sortDesc: false,
      items: [
        {
          case: 13,
          created: '10/05/2021',
          name: 'Michael Wong',
          origin: 'Reino Unido',
          specialty: 'Oncología',
          derivative: {
            icon: 'https://placekitten.com/30/30',
            name: 'Sanitas',
          },
          source: 'Vidasprime',
          phase: 'Recibida',
          status: 'pending',
          uuid: '1',
        },
        {
          case: 12,
          created: '15/05/2021',
          name: 'Alice Palmer',
          origin: 'Francia',
          specialty: 'Oncología',
          derivative: {
            icon: 'https://placekitten.com/30/30',
            name: 'Baviera',
          },
          source: 'Vidasprime',
          phase: 'Recibida',
          status: 'accepted',
          uuid: '2',
        },
      ],
      fields: [
        { key: 'case', label: 'Caso', sortable: true },
        { key: 'created', label: 'Creado', sortable: true },
        { key: 'name', label: 'Nombre', sortable: true },
        { key: 'origin', label: 'Origen', sortable: true },
        { key: 'specialty', label: 'Especialidad', sortable: true },
        { key: 'derivative', label: 'Derivada a', sortable: true },
        { key: 'source', label: 'Fuente', sortable: true },
        { key: 'phase', label: 'Etapa', sortable: true },
        { key: 'status', label: 'Estado', sortable: true },
        { key: 'actions', label: '' },
      ],
    };
  },
  methods: {
    showEditForm() {},
  },
  watch: {
    sortBy() {},
    sortDesc() {},
  },
  components: {
    Badge,
    IconGroup,
    IconActionsGroup,
  },
};
</script>

<style lang="sass" scoped>
  @import './Table.sass'
</style>
