<template>
  <b-row>
    <b-col class="col-6">
      <div class="InsuranceComboselector" v-if="this.showSpecialties">
        <div class="InsuranceComboselector__selectGroup">
          <SelectGroup
            id="clinicInsurances"
            :options="getSpecialtyOptions"
            :label="$i18n.t('lb_select_specialty')"
            :selectedOption="this.specialtySelected"
            @handlerSelect="handleChangeSpecialties"
            :isLoading="isLoading"
          />
          <FormButton
            @handleClick="handleAddSpecialty"
          >
            {{ $i18n.t('btn_add') }}
          </FormButton>
        </div>
        <div class="InsuranceComboselector__list">
          <MultiBadge
            v-for="(specialty, index) in specialtyBadgeList"
            :key="index"
            :item="specialty"
            @handleDelete="handleDeleteSpecialty(index)"
          />
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from 'vuex';
import SelectGroup from '@/components/atoms/Select/SelectGroup.vue';
import { BOOLEAN_SELECTION_OPTIONS } from '@/helpers/ComponentHelper';
import MultiBadge from '@/components/atoms/Badge/MultiBadge.vue';
import FormButton from '@/components/atoms/Buttons/FormButton/FormButton.vue';

export default {
  props: {
    specialties: {
      type: Array,
      default: () => [],
    },
    hasSelectedSpecialties: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      booleanOptions: BOOLEAN_SELECTION_OPTIONS,
      specialtySelected: null,
      specialtySelectedInfo: null,
      specialtyBadgeList: null,
      showSpecialties: this.hasSelectedSpecialties,
      hasCurrentSpecialties: false,
      isLoading: false,
    };
  },
  created() {
    if (!this.hasSpecialties) {
      this.loadSpecialtyList();
    }
  },
  mounted() {
    this.loadInformation();
  },
  methods: {
    async loadSpecialtyList() {
      this.isLoading = true;
      await this.$store.dispatch('specialties/getAllSpecialties')
        .then(() => {
          this.loadBadgeList();
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },

    loadInformation() {
      this.hasCurrentSpecialties = this.hasSelectedSpecialties;
      this.showSpecialties = this.hasSelectedSpecialties;
      this.loadBadgeList();
    },

    loadBadgeList() {
      if (this.showSpecialties) {
        this.specialtyBadgeList = this.specialty.filter(
          (specialty) => this.specialties.some(
            (el) => specialty.id === el.id,
          ),
        );
      }
    },

    handleChangeHasSpecialty(value) {
      const boolValue = value === 'true';
      this.hasCurrentSpecialties = value;
      this.showSpecialties = boolValue;
      this.resetSpecialtyValues();

      this.$emit('handleChangeHasSpecialty', this.hasCurrentSpecialties);
    },

    handleChangeSpecialties(value) {
      this.specialtySelectedInfo = this.getInsuranceInfo(value);
      this.specialtySelected = value;
    },

    handleAddSpecialty() {
      this.specialtyBadgeList.push(this.specialtySelectedInfo);
      const updatedBadgeList = this.removeDuplicates(this.specialtyBadgeList);
      this.specialtyBadgeList = updatedBadgeList;

      this.$emit('handleChangeSpecialties', this.specialtyBadgeList);
    },

    handleDeleteSpecialty(index) {
      this.specialtyBadgeList.splice(index, 1);

      this.$emit('handleChangeSpecialties', this.specialtyBadgeList);
    },

    resetSpecialtyValues() {
      this.specialtyBadgeList = [];
    },

    removeDuplicates(list) {
      return [...new Set(list)];
    },

    getInsuranceInfo(id) {
      return this.specialty.find((el) => el.id === id);
    },
  },
  computed: {
    ...mapGetters('specialties', {
      specialty: 'getSpecialties',
      hasSpecialties: 'hasSpecialties',
    }),

    getSpecialtyOptions() {
      const newSpecialties = this.specialty.map((specialty) => {
        const newSpecialty = {
          text: specialty.name,
          value: specialty.id,
        };

        return newSpecialty;
      });

      return newSpecialties;
    },
  },
  components: {
    SelectGroup,
    FormButton,
    MultiBadge,
  },
};
</script>

<style lang="sass" scoped>
@import './SpecialtyComboSelector.sass'
</style>
