import getAxiosWithAuthHeader from '../../helpers/AuthHelper';
import {
  getDataWithUuid,
  getParameterUrl,
  getSearchUrl,
} from '../../helpers/ApiHelper';
import SurveyTypeFactory from './SurveyTypeFactory';

const SURVEY_TYPE = '/api/survey/type';
const SEARCH_SURVEYS_TYPE = '/api/survey/type/search';

class surveyTypeAPI {
  constructor() {
    this.client = getAxiosWithAuthHeader();
  }

  getAllSurveyType(currentPage) {
    const url = `${SURVEY_TYPE}/${currentPage.uuid}`;

    let parameterUrl = getParameterUrl(url, currentPage.page,
      [], [], currentPage.itemsPerPage);
    parameterUrl += `&language=${currentPage.language}`;

    return this.client
      .get(parameterUrl)
      .then((res) => SurveyTypeFactory.getAllSurveyType(res.data))
      .catch((error) => Promise.reject(error));
  }

  filterSurveyType(data) {
    let parameterUrl = getParameterUrl(
      SEARCH_SURVEYS_TYPE,
      data.page,
      data.filters,
      data.ordenation,
    );
    parameterUrl += `&language=${data.language}`;

    return this.client
      .get(parameterUrl)
      .then((res) => SurveyTypeFactory.getAllSurveyType(res.data))
      .catch((error) => Promise.reject(error));
  }

  searchSurveyType(filters) {
    const filterUrl = getSearchUrl(SEARCH_SURVEYS_TYPE, filters);

    return this.client
      .get(filterUrl)
      .then((res) => SurveyTypeFactory.getAllSurveyType(res.data))
      .catch((error) => Promise.reject(error));
  }

  createSurveyType(data) {
    const idData = getDataWithUuid(data);

    return this.client
      .post(SURVEY_TYPE, idData)
      .then(() => idData)
      .catch((error) => Promise.reject(error));
  }

  updateSurveyType(requestData) {
    const url = `${SURVEY_TYPE}/${requestData.id}`;

    return this.client
      .put(url, requestData.body)
      .then()
      .catch((error) => Promise.reject(error));
  }

  deleteSurveyType(id) {
    const url = `${SURVEY_TYPE}/${id}`;

    return this.client
      .delete(url)
      .then()
      .catch((error) => Promise.reject(error));
  }
}

export default surveyTypeAPI;
