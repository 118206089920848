import getAxiosWithAuthHeader from '../../helpers/AuthHelper';
import InsightsFactory from './InsightsFactory';

const INSIGHTS = '/api/insights';

class InsightsAPI {
  constructor() {
    this.client = getAxiosWithAuthHeader();
  }

  getInsights(data) {
    const url = `${INSIGHTS}/${data.specialtyId}`;

    return this.client
      .get(url)
      .then((res) => InsightsFactory.getAllInsights(res.data))
      .catch((error) => Promise.reject(error));
  }
}

export default InsightsAPI;
