import getAllPartialsTypes from './partialsTypesActions';
import setPartialsTypes from './partialsTypesMutations';
import { getPartialsTypes, hasPartialsTypes } from './partialsTypesGetters';

const partialsType = {
  namespaced: true,
  state: () => ([]),
  actions: {
    getAllPartialsTypes,
  },
  mutations: {
    setPartialsTypes,
  },
  getters: {
    getPartialsTypes,
    hasPartialsTypes,
  },
};

export default partialsType;
