<template>
  <div id="salesforceChat"></div>
</template>

<script>
import { mapGetters } from 'vuex';
// import DEVICE from '../../../helpers/ResponsiveHelper';

export default {
  name: 'SalesforceChat',
  computed: {
    ...mapGetters({
      isDoctorOrClinic: 'user/isDoctorOrClinic',
    }),
    isMobile() {
      return this.$store.getters['responsive/isMobile'];
    },
    viewController() {
      if (this.isDoctorOrClinic) window.embedded_svc.showHelpButton();
      else window.embedded_svc.hideHelpButton();
      return null;
    },
  },
  methods: {
    initESW(gslbBaseURL) {
      window.embedded_svc.settings.displayHelpButton = true; // Or false
      window.embedded_svc.settings.language = 'es-ES'; // For example, enter 'en' or 'en-US'

      window.embedded_svc.settings.defaultMinimizedText = 'Habla con un agente';
      window.embedded_svc.settings.disabledMinimizedText = 'Agente no disponible';

      window.embedded_svc.settings.enabledFeatures = ['LiveAgent'];
      window.embedded_svc.settings.entryFeature = 'LiveAgent';

      window.embedded_svc.init(
        'https://vidasprime123.my.salesforce.com',
        'https://vidasprime123.secure.force.com/liveagentsupportsite',
        gslbBaseURL,
        '00D7Q000003QEkM',
        'Chat',
        {
          baseLiveAgentContentURL: 'https://c.la2-c1-fra.salesforceliveagent.com/content',
          deploymentId: '5727Q0000000J3V',
          buttonId: '5737Q0000000JLO',
          baseLiveAgentURL: 'https://d.la2-c1-fra.salesforceliveagent.com/chat',
          eswLiveAgentDevName: 'Chat',
          isOfflineSupportEnabled: false,
        },
      );
    },
  },
  mounted() {
    const self = this;
    const chatScript = document.createElement('script');
    chatScript.setAttribute('src', 'https://service.force.com/embeddedservice/5.0/esw.min.js');
    chatScript.async = true;
    chatScript.defer = true;
    document.head.appendChild(chatScript);
    // eslint-disable-next-line func-names
    chatScript.onload = function () {
      if (!window.embedded_svc) {
        const s = document.createElement('script');
        s.setAttribute('src', 'https://vidasprime123.my.salesforce.com/embeddedservice/5.0/esw.min.js');
        // eslint-disable-next-line func-names
        s.onload = function () {
          self.initESW(null);
        };
        document.body.appendChild(s);
      } else {
        self.initESW('https://service.force.com');
      }
    };
  },
  watch: {
    isDoctorOrClinic() {
      if (this.isDoctorOrClinic) window.embedded_svc.showHelpButton();
      else window.embedded_svc.hideHelpButton();
    },
  },
};
</script>

<style>
  .embeddedServiceHelpButton .helpButton .uiButton {
    background-color: #4E20E4 !important;
    font-family: "Arial", sans-serif;
  }
  .embeddedServiceHelpButton .helpButton .uiButton:focus {
    /* outline: 1px solid #4E20E4 !important; */
    opacity: 0.8;
  }
</style>
