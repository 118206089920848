import Vue from 'vue';

export function setClinics(state, clinics) {
  Vue.set(state, 'invitations', clinics);
}

export function setInvitations(state, invitations) {
  state.invitations = invitations;
}

export function setInvitationPagination(state, pagination) {
  state.invitations.pagination = pagination;
}

export function setInvitationList(state, invitationList) {
  state.invitations.requestList = invitationList;
}

export function updateInvitationStatus(state, inv) {
  const rIndex = state.invitations.invitationList.findIndex((item) => item.id === inv.id);
  const mutatedInv = state.invitations.invitationList[rIndex];
  mutatedInv.status = inv.body.status;
  Vue.set(state.invitations.invitationList, rIndex, mutatedInv);
}
