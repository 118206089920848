<template>
  <!-- VP-14:TODO aplicar isMobile y renderizar los componentes correspondientes
        según si es mobile o no
    -->
  <!-- VP-14:TODO: CREAR SIDEBAR PARA MOBILE -->
  <div class="PrivateTemplate">
    <SideMenu />
    <div class="viewContainer">
      <Header />
      <div class="viewContainer__content">
        <slot name="private-template"></slot>
      </div>
    </div>
  </div>
</template>
<script>
import SideMenu from '@/components/molecules/SideMenu/SideMenu.vue';
import Header from '@/components/molecules/Header/Header.vue';
import DEVICE from '../../helpers/ResponsiveHelper';

export default {
  components: {
    SideMenu,
    Header,
  },
  mounted() {
    /* TODO: VP-14 REPLICAR EN las páginas de login */
    const resizeObserver = new ResizeObserver((entries) => {
      const [element] = entries;
      const isMobile = element.contentRect.width < DEVICE.MOBILE_WIDTH;
      this.$store.commit('responsive/setIsMobile', isMobile);
    });

    resizeObserver.observe(this.$el);
  },
  computed: {
    /* TODO: VP-14 EXAMPLE HOW TO USE IT. REMOVE IT */
    isMobile() {
      return this.$store.getters['responsive/isMobile'];
    },
  },
};
</script>

<style lang="sass" scoped>
@import './PrivateTemplate.sass'
</style>
