import getAxiosWithAuthHeader from '../../helpers/AuthHelper';

const APPOINTMENTS = 'api/appointments';

class appointmentsAPI {
  constructor() {
    this.client = getAxiosWithAuthHeader();
  }

  createAppointments(requestData) {
    const url = `${APPOINTMENTS}`;

    return this.client
      .post(url, requestData.appointment)
      .then()
      .catch((error) => Promise.reject(error));
  }

  updateAppointments(requestData) {
    const url = `${APPOINTMENTS}/${requestData.requestId}/${requestData.appointmentId}`;

    return this.client
      .put(url, requestData)
      .then()
      .catch((error) => Promise.reject(error));
  }

  deleteAppointments(requestData) {
    const url = `${APPOINTMENTS}/${requestData.appointmentId}`;

    return this.client
      .delete(url)
      .then()
      .catch((error) => Promise.reject(error));
  }
}

export default appointmentsAPI;
