export default {
  txt_login: 'Welcome! Log in with your username and password to get to know the community of patients referred to your clinic.',
  txt_new_user_access: 'You are one step away from accesing VidasPrime. Choose a password to access your private area <br> Remember to save the password.',
  txt_patients_vp_role: 'Discover the list of patients referred to the clinics.',
  txt_patients_clinic_role: 'Discover the list of patients.',
  txt_clients: 'List of all active subscribers.',
  txt_healthmanagers: 'These are all the profiles that manage patients.',
  txt_doctors: 'Manage your medical team from here.',
  txt_surveys: 'From here you can create, design and send surveys and know the results.',
  txt_home: 'Welcome to VidasPrime, find the information you need to improve the performance of your consultation.',
  txt_survey_types: 'Manage your survey types from here.',
  txt_forgot_password: 'Enter your email address and we will send you a password recovery email.',
  txt_reset_password: 'Enter new password.',
  txt_invitation_email: ' you will be able to see its status in pending invitations.',
  txt_subscribe: 'Subscribe me',
  txt_ask_demo: 'Request demo',
};
