<template>
  <DoctorForm
    id="update-doctor"
    ref="updateDoctorForm"
    :title="$i18n.t('lb_update_doctor')"
    :doctor="getDoctor"
    :isLoading="isLoading"
    @handleOk="update"
  />
</template>

<script>
import DoctorForm from './DoctorForm.vue';
import { getAvatarBase64 } from '@/helpers/UserHelper';

export default {
  props: {
    doctor: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    update(data) {
      this.isLoading = true;

      const requestData = {
        id: data.id,
        body: {
          ...(this.getAvatar(data.avatar)),
          name: data.name,
          lastname: data.lastname,
          specialty: data.specialty?.id,
          bio: data.bio,
          experience: data.experience,
          details: data.details,
          email: data.email,
          phone: data.phone,
          defaultLanguage: data.defaultLanguage,
        },
      };

      this.$store.dispatch('doctor/updateDoctor', requestData)
        .then(() => {
          this.$emit('updateDoctor', data);
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },

    getAvatar(avatar) {
      return getAvatarBase64(avatar);
    },
  },
  computed: {
    getDoctor() {
      return this.doctor;
    },
  },
  components: {
    DoctorForm,
  },
};
</script>
