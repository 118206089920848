import {
  getAllSurvey,
  filterSurvey,
  searchSurvey,
  createSurvey,
  updateSurvey,
  deleteSurvey,
} from './surveyActions';

const survey = {
  namespaced: true,
  state: () => ({}),
  mutations: {},
  actions: {
    getAllSurvey,
    filterSurvey,
    searchSurvey,
    createSurvey,
    updateSurvey,
    deleteSurvey,
  },
  getters: {
  },
};

export default survey;
