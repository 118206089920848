import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store/index';
import LoginView from '../views/LoginView/LoginView.vue';
import OnboardingView from '../views/Onboarding/OnboardingView.vue';
import ForgotPasswordView from '@/views/ForgotPassword/ForgotPasswordView.vue';
import ResetPasswordView from '@/views/ResetPassword/ResetPasswordView.vue';
import HealthManagerView from '../views/HealthManager/HealthManagerView.vue';
import RequestView from '../views/Request/RequestView.vue';
import HomeView from '../views/Home/HomeView.vue';
import PatientView from '../views/Patient/PatientView.vue';
import ClinicView from '../views/Clinic/ClinicView.vue';
import DoctorView from '../views/Doctor/DoctorView.vue';
import CalendarView from '../views/Calendar/CalendarView.vue';
import ReviewView from '../views/ReviewView/ReviewView.vue';
import OnlinePresenceView from '../views/OnlinePresence/OnlinePresenceView.vue';
import VideoconsultationView from '../views/Videoconsultation/VideoconsultationView.vue';
import PatientExperienceView from '../views/PatientExperience/PatientExperienceView.vue';
import UIKitComponents from '../views/UIKit/UIKitComponents.vue';
import UserVerification from '../views/UserVerification/UserVerification.vue';
import SurveyTypeViewer from '@/views/SurveyTypeView/SurveyTypeView.vue';
import SurveyFormView from '@/views/SurveyFormView/SurveyFormView.vue';
// import SurveyTypeView from '@/views/SurveyType/SurveyTypeView.vue';
import SurveyView from '@/views/SurveyView/SurveyView.vue';
import { getUserData } from '@/helpers/UserHelper';
import { isCollaborator } from '../store/user/userGetters';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Login',
    component: LoginView,
    meta: {
      isPublic: true,
    },
  },
  {
    path: '/onboarding',
    name: 'Onboarding',
    component: OnboardingView,
    meta: {
      isPublic: true,
    },
  },
  {
    path: '/password/forgot',
    name: 'ForgotPassword',
    component: ForgotPasswordView,
    meta: {
      isPublic: true,
    },
  },
  {
    path: '/password/recovery/:token',
    name: 'ResetPassword',
    component: ResetPasswordView,
    meta: {
      isPublic: true,
    },
  },
  {
    path: '/home',
    name: 'Home',
    component: HomeView,
    meta: {
      isPublic: false,
    },
  },
  {
    path: '/request',
    name: 'Request',
    component: RequestView,
    meta: {
      isPublic: false,
      isAllowedForCollaborators: true,
    },
  },
  {
    path: '/patient',
    name: 'Patient',
    component: PatientView,
    meta: {
      isPublic: false,
      isAllowedForCollaborators: true,
    },
  },
  {
    path: '/survey',
    name: 'Satisfaction',
    component: SurveyView,
    meta: {
      isPublic: false,
    },
  },
  {
    path: '/survey/:survey',
    name: 'SurveyForm',
    component: SurveyFormView,
    meta: {
      isPublic: true,
    },
  },
  // {
  //   path: '/survey-type',
  //   name: 'SurveyTypeView',
  //   component: SurveyTypeView,
  //   meta: {
  //     isPublic: false,
  //   },
  // },
  {
    path: '/surveyType/:surveyType',
    name: 'SurveyTypeViewer',
    component: SurveyTypeViewer,
    meta: {
      isPublic: true,
    },
  },
  {
    path: '/doctor',
    name: 'Doctor',
    component: DoctorView,
    meta: {
      isPublic: false,
    },
  },
  {
    path: '/health-manager',
    name: 'HealthManager',
    component: HealthManagerView,
    meta: {
      isPublic: false,
    },
  },
  {
    path: '/calendar',
    name: 'Calendar',
    component: CalendarView,
    meta: {
      isPublic: false,
    },
  },
  {
    path: '/review',
    name: 'Review',
    component: ReviewView,
    meta: {
      isPublic: false,
    },
  },
  {
    path: '/client',
    name: 'Client',
    component: ClinicView,
    meta: {
      isPublic: false,
    },
  },
  {
    path: '/online-presence',
    name: 'OnlinePresence',
    component: OnlinePresenceView,
    meta: {
      isPublic: false,
    },
  },
  {
    path: '/patient-experience',
    name: 'PatientExperience',
    component: PatientExperienceView,
    meta: {
      isPublic: false,
    },
  },
  {
    path: '/video-consultation',
    name: 'Videoconsultation',
    component: VideoconsultationView,
    meta: {
      isPublic: false,
    },
  },
  {
    path: '/components',
    name: 'UIKitComponents',
    component: UIKitComponents,
    meta: {
      isPublic: true,
    },
  },
  {
    path: '/users/verification/:token',
    name: 'UserVerification',
    component: UserVerification,
    meta: {
      isPublic: true,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
  const routeIsPublic = to.matched.some((record) => record.meta.isPublic);
  if (routeIsPublic) {
    next();
    return;
  }

  const isAuth = store.getters['auth/hasAuth'];
  if (isAuth) {
    const user = getUserData(store.state.auth.token);
    const routeIsAllowedForCollaborators = to.matched.some(
      (record) => record.meta.isAllowedForCollaborators,
    );
    if (isCollaborator({ ...user, role: user.roles[0] }) && !routeIsAllowedForCollaborators) {
      next({ name: 'Request' });
      return;
    }
    store.commit('user/setUserAuth', store.state.auth);

    next();
    return;
  }

  next({ name: 'Login' });
});

export default router;
