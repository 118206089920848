<template>
    <div v-bind:id="`${title}-chartLineal`" class="container">
      <Spinner
        :isLoading="isLoading"
      />
      <div v-if="isEmpty" class="noData">
        <div class="noData-text">
          <span >{{ $i18n.t('val_empty_data') }}</span>
        </div>
      </div>
      <apexchart
        type="scatter"
        height="150"
        :options="options"
        :series= 'isEmpty ? [] : series'
      ></apexchart>
    </div>
</template>

<script>
import Spinner from '@/components/atoms/Spinner/Spinner.vue';

export default {
  props: {
    series: {
      type: Array,
    },
    title: {
      type: String,
    },
    type: String,
    isLoading: Boolean,
    isEmpty: Boolean,
  },
  computed: {},
  data() {
    return {
      options: {
        chart: {
          toolbar: {
            show: false,
            tools: {
              selection: true,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
            },
          },
        },
        dataLabels: {
          background: {
            enabled: true,
            padding: 8,
            borderRadius: 4,
          },
          enabled: true,
          offsetY: -12,
          formatter: (rI, { ctx, seriesIndex }) => ctx.series.w.config.series[seriesIndex].name,
        },
        tooltip: {
          enabled: true,
          custom: (
          ) => {
            const isYouBiggerThanMed = this.series[3].data[0].x > this.series[1].data[0].x;
            return `<div class="graph-tooltip">
                  <div class="graph-tooltip__header"></div>
                  <div class="graph-tooltip__body">
                    <div class="graph-tooltip__list">
                      <div class="graph-tooltip__row">
                          <span class="graph-tooltip__bullet graph-tooltip__bullet--min"></span>
                          <span class="graph-tooltip__label">${this.series[0].name}</span>
                          <span>${this.type === 'currency'
    ? new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR' }).format(this.series[0].data[0].x)
    : `${parseFloat(this.series[0].data[0].x).toFixed(0)}h`}
                          </span>
                      </div>
                      <div class="graph-tooltip__row">
                          <span class="graph-tooltip__bullet graph-tooltip__bullet--${this.series[isYouBiggerThanMed ? 1 : 3].color === '#4E20E4' ? 'you' : 'avg'}"></span>
                          <span class="graph-tooltip__label">${this.series[isYouBiggerThanMed ? 1 : 3].name}</span>
                          <span>${this.type === 'currency'
    ? new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR' }).format(this.series[isYouBiggerThanMed ? 1 : 3].data[0].x)
    : `${parseFloat(this.series[isYouBiggerThanMed ? 1 : 3].data[0].x).toFixed(0)}h`}
                          </span>
                      </div>
                      <div class="graph-tooltip__row">
                          <span class="graph-tooltip__bullet graph-tooltip__bullet--${this.series[isYouBiggerThanMed ? 3 : 1].color === '#4E20E4' ? 'you' : 'avg'}"></span>
                          <span class="graph-tooltip__label">${this.series[isYouBiggerThanMed ? 3 : 1].name}</span>
                          <span>${this.type === 'currency'
    ? new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR' }).format(this.series[isYouBiggerThanMed ? 3 : 1].data[0].x)
    : `${parseFloat(this.series[isYouBiggerThanMed ? 3 : 1].data[0].x).toFixed(0)}h`}
                          </span>
                      </div>
                      <div class="graph-tooltip__row">
                          <span class="graph-tooltip__bullet graph-tooltip__bullet--max"></span>
                          <span class="graph-tooltip__label" >${this.series[2].name}</span>
                          <span>${this.type === 'currency'
    ? new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR' }).format(this.series[2].data[0].x)
    : `${parseFloat(this.series[2].data[0].x).toFixed(0)}h`}
                          </span>
                      </div>
                    </div>
                  </div>
                </div>`;
          },
        },
        xaxis: {
          show: false,
          type: 'numeric',
          tickAmount: 5,
          labels: {
            formatter: (val) => `${parseFloat(val).toFixed(0)}${this.type === 'currency' ? '€' : 'h'}`,
          },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          show: false,
        },
        grid: {
          yaxis: {
            lines: {
              show: false,
            },
          },
          padding: {
            right: 55,
            left: 55,
          },
        },
        title: {
          text: this.title,
          align: 'left',
          offsetX: 30,
          offsetY: 32,
          floating: true,
          style: {
            fontSize: '14px',
            fontWeight: '600',
          },
        },
        legend: {
          show: false,
          floating: true,
          position: 'top',
          horizontalAlign: 'right',
          offsetY: 32,
          formatter: (seriesName, opts) => {
            const formatted = this.type === 'currency'
              ? `${seriesName}: 
                  ${new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR' })
    .format(this.series[opts.seriesIndex].data[0].x)}`
              : `${seriesName}: ${parseFloat(this.series[opts.seriesIndex].data[0].x).toFixed(0)}h`;
            return formatted;
          },
        },
        noData: {
          text: '',
        },
      },
    };
  },
  components: {
    Spinner,
  },
};
</script>

<style lang="sass">
  @import './GraphTooltip.sass'
</style>

<style lang="sass" scoped>
  @import './charts.sass'
</style>
