<template>
  <div class="Table">
    <b-table
      :items="items"
      :fields="columns"
      hover
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      selectable
      select-mode="single"
      @row-selected="onRowSelected"
    >
      <template #cell(name)="data">
        <UserSmallInfo
          :icon="getIcon(data.item.avatar)"
          :label="data.item.name"
          :text="data.item.city"
        />
      </template>
      <template #cell(collaboration)="data">
        <Badge
          v-if="isCollaboration(data)"
          :variant="status.COLLABORATION.key"
        >
          <span>{{$i18n.t('lb_collaborator')}}</span>
        </Badge>
      </template>
      <template #cell(city)="data">
        <Badge variant="neutral">
          {{ data.item.city }}
        </Badge>
      </template>
      <template #cell(vidasPrimeRequests)="data">
        <span class="text__sr text-secondary">{{ data.item.vidasPrimeRequests }}</span>
      </template>
      <template #cell(totalRequests)="data">
        <span class="text__sr text-secondary">{{ data.item.totalRequests }}</span>
      </template>
      <template #cell(patients)="data">
        <span class="text__sr text-secondary">{{ data.item.patients }}</span>
      </template>
      <template #cell(actions)="data">
        <IconActionsGroup
          @edit="showUpdateForm"
          @delete="showDeleteModal"
          :objectData="data.item"
          :canCopy="false"
        />
      </template>
    </b-table>
  </div>
</template>

<script>
import { isCollaborator } from '../../../../store/user/userGetters';
import IconActionsGroup from '@/components/atoms/Icon/IconActionsGroup/IconActionsGroup.vue';
import UserSmallInfo from '@/components/atoms/User/UserSmallInfo/UserSmallInfo.vue';
import Badge from '@/components/atoms/Badge/Badge.vue';
import { getAvatarIcon } from '@/helpers/UserHelper';
import { STATUS } from '@/helpers/ComponentHelper';
import i18n from '@/plugins/vue-i18n';

export default {
  props: {
    clinicItems: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      sortBy: 'case',
      sortDesc: false,
      items: this.clinicItems,
      status: STATUS,
    };
  },
  computed: {
    columns() {
      return [
        {
          key: 'name',
          label: i18n.t('lb_name'),
          sortable: true,
        },
        {
          key: 'collaboration',
          label: this.$i18n.t('lb_type'),
        },
        {
          key: 'city',
          label: i18n.t('lb_city'),
          sortable: true,
        },
        {
          key: 'vidasPrimeRequests',
          label: i18n.t('lb_vp_request'),
          sortable: true,
        },
        {
          key: 'totalRequests',
          label: i18n.t('lb_total_request'),
          sortable: true,
        },
        {
          key: 'patients',
          label: i18n.t('lb_patients'),
          sortable: true,
        },
        {
          key: 'actions',
          label: '',
        },
      ];
    },
  },
  methods: {
    onRowSelected(items) {
      this.$emit('handleSelectRow', items[0]);
    },

    showUpdateForm(data) {
      this.$emit('showUpdateForm', data);
    },

    showDeleteModal(data) {
      this.$emit('showDeleteModal', data);
    },

    getIcon(avatar) {
      return getAvatarIcon(avatar);
    },

    isCollaboration(data) {
      return isCollaborator(data.item);
    },
  },
  watch: {
    sortBy(newValue) {
      this.$emit('handleSortDesc', newValue);
    },

    sortDesc(newValue) {
      if (newValue === true) {
        this.$emit('handleSortDesc', this.sortBy);
      } else {
        this.$emit('handleSortAsc', this.sortBy);
      }
    },
  },
  components: {
    IconActionsGroup,
    UserSmallInfo,
    Badge,
  },
};
</script>

<style lang="sass" scoped>
  @import '../Table.sass'
</style>
