export default {
  btn_accept: 'Accept',
  btn_add: 'Add',
  btn_apply: 'Apply',
  btn_cancel: 'Cancel',
  btn_change: 'Change',
  btn_close_session: 'Log off',
  btn_close: 'Close',
  btn_confirm: 'Confirm',
  btn_delete: 'Delete',
  btn_edit: 'Edit',
  btn_export: 'Export',
  btn_filters: 'Filters',
  btn_forgot_password: 'Recover password',
  btn_import: 'Import',
  btn_login: 'Log in',
  btn_new_f: 'New',
  btn_new: 'New',
  btn_new_collaboration: 'New collaboration',
  btn_send_request: 'Send request',
  btn_reject: 'Reject',
  btn_reset_password: 'Log in',
  btn_save_request: 'Save request',
  btn_save: 'Save',
  btn_send_survey: 'Send survey',
  btn_survey_new: 'New',
  btn_survey_type_new: 'New',
  btn_update: 'Update',
  btn_verify: 'Verify',
  btn_watch: 'See',
  btn_invitation: 'Send invitation',
  btn_invitation_cancel: 'Cancel invitation',
  btn_invitation_accept: 'Accept invitation',
  btn_invitation_reject: 'Reject invitation',
  btn_search: 'Search',
};
