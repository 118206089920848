<template>
  <div>
    <div class="LoginMobileView" v-if="isMobile">
      <div>
        <LoginForm />
      </div>
    </div>
    <div class="LoginView" v-if="!isMobile">
      <div class="LoginView__loginFormContainer">
        <LoginForm />
      </div>
      <div class="LoginView__imageContainer" />
      <!-- <img src="@/assets/images/doctors.png" /> -->
    </div>
  </div>
</template>

<script>
import LoginForm from '@/components/organisms/Login/LoginForm.vue';
import StorageManager from '@/helpers/StorageManager';
import DEVICE from '../../helpers/ResponsiveHelper';

export default {
  name: 'LoginView',
  components: {
    LoginForm,
  },
  data: () => ({
    isMobile: false,
  }),
  mounted() {
    const resizeObserver = new ResizeObserver((entries) => {
      const [element] = entries;
      this.isMobile = element.contentRect.width < DEVICE.MOBILE_WIDTH;
      this.$store.commit('responsive/setIsMobile', this.isMobile);
    });

    resizeObserver.observe(this.$el);
  },
  created() {
    StorageManager.removeLocalData();
  },
};
</script>

<style lang="sass" scoped>
@import './LoginView.sass'
</style>
