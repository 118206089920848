export default {
  txt_login: '¡Bienvenido! Accede con tu usuario y contraseña para conocer la comunidad de pacientes derivados a tu Clínica.',
  txt_new_user_access: 'Estás a un paso de acceder a VidasPrime. Elige una contraseña y accederás a tu área privada. <br> Recuerda guardar la contraseña.',
  txt_patients_vp_role: 'Descubre el listado de pacientes derivados a las clínicas.',
  txt_patients_clinic_role: 'Descubre el listado de pacientes.',
  txt_clients: 'Listado con todos los suscriptores activos.',
  txt_healthmanagers: 'Estos son todos los perfiles que gestionan pacientes.',
  txt_doctors: 'Gestiona desde aquí a tu equipo médico.',
  txt_surveys: 'Desde aquí puedes crear, diseñar y enviar encuestas y conocer los resultados.',
  txt_home: 'Bienvenido a VidasPrime, encuentra la información que necesitas para mejorar el rendimiento de tu consulta.',
  txt_survey_types: 'Gestiona desde aquí los diferentes tipos encuesta.',
  txt_forgot_password: 'Introduce tu correo electrónico y te enviaremos un mail de recuperación de contraseña.',
  txt_reset_password: 'Introduce tu nueva contraseña.',
  txt_invitation_email: ' podrás ver su estado en invitaciones pendientes.',
  txt_subscribe: 'Suscribirme',
  txt_ask_demo: 'Solicitar demo',
};
