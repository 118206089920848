export default class SurveyTypePartialsTypeModel {
  constructor(data) {
    this.id = data.id;
    this.surveyTypeId = data.surveyTypeId;
    this.partialsType = data.partialsType;
    this.title = data.title;
    this.description = data.description;
    this.help = data.help;
    this.name = data.name;
    this.placeholder = data.placeholder;
    this.isRequired = data.isRequired;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
    this.deletedAt = data.deletedAt;
    this.data = data.data;
  }
}
