<template>
  <div>
    <div class="ForgotPasswordMobileView" v-if="isMobile">
      <ForgotPasswordForm />
    </div>
    <div class="ForgotPasswordView" v-if="!isMobile">
      <div class="ForgotPasswordView__forgotPasswordFormContainer">
        <ForgotPasswordForm />
      </div>
      <div class="ForgotPasswordView__imageContainer" />
    </div>
  </div>
</template>

<script>
import ForgotPasswordForm from '@/components/organisms/ForgotPassword/ForgotPasswordForm.vue';
import DEVICE from '../../helpers/ResponsiveHelper';

export default {
  name: 'ForgotPasswordView',
  mounted() {
    /* TODO: VP-14 REPLICAR EN las páginas de login */
    const resizeObserver = new ResizeObserver((entries) => {
      const [element] = entries;
      const isMobile = element.contentRect.width < DEVICE.MOBILE_WIDTH;
      this.$store.commit('responsive/setIsMobile', isMobile);
    });

    resizeObserver.observe(this.$el);
  },
  computed: {
    /* TODO: VP-14 EXAMPLE HOW TO USE IT. REMOVE IT */
    isMobile() {
      return this.$store.getters['responsive/isMobile'];
    },
  },
  components: {
    ForgotPasswordForm,
  },
};
</script>

<style lang="sass" scoped>
@import './ForgotPasswordView.sass'
</style>
