<template>
  <div class="IconActionsGroup">
    <button
      v-if="canPreview"
      @click.prevent="handlePreview"
    >
      <inline-svg
        class="IconActionsGroup__icon"
        :src="require('@/assets/actions/preview.svg')"
      />
    </button>
    <button
      v-if="canEdit"
      @click.prevent="handleEdit"
    >
      <inline-svg
        class="IconActionsGroup__icon"
        :src="require('@/assets/actions/edit.svg')"
      />
    </button>
    <button
      v-if="canCopy"
      @click.prevent="handleCopy"
    >
      <inline-svg
        class="IconActionsGroup__icon"
        :src="require('@/assets/actions/copy.svg')"
      />
    </button>
    <button
      v-if="canDelete"
      @click.prevent="handleDelete"
    >
      <inline-svg
        class="IconActionsGroup__icon"
        :src="require('@/assets/actions/delete.svg')"
      />
    </button>
    <button
      v-if="canSend"
      @click.prevent="handleSend"
    >
      <inline-svg
        :src="require('@/assets/actions/Up.svg')"
      />
    </button>
  </div>
</template>

<script>

export default {
  props: {
    objectData: {
      type: Object,
      default: () => {},
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
    canCopy: {
      type: Boolean,
      default: true,
    },
    canDelete: {
      type: Boolean,
      default: true,
    },
    canPreview: {
      type: Boolean,
      default: false,
    },
    canSend: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleEdit() {
      this.$emit('edit', this.objectData);
    },
    handleCopy() {
      this.$emit('copy', this.objectData);
    },
    handleDelete() {
      this.$emit('delete', this.objectData);
    },
    handlePreview() {
      this.$emit('preview', this.objectData);
    },
    handleSend() {
      this.$emit('send', this.objectData);
    },
  },
};
</script>

<style lang="sass">
  @import './IconActionsGroup.sass'
</style>
