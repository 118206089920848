<template>
  <div class="OnboardingForm">
    <p class="OnboardingForm__title">{{$i18n.t('cp_onboarding_register')}}</p>
    <div class="OnboardingForm__form">
      <InputGroup
        id="onBoardingName"
        :placeholder="$i18n.t('lb_clinic_name')"
        @handlerInputChange="handleChangeName"
        :isValid="this.validationState.name"
        :error="this.$i18n.t('val_empty')"
        :value="this.onboardingState.name"
        class="OnboardingForm__input--large"
      />
      <InputGroup
        id="onBoardingContactName"
        :placeholder="$i18n.t('lb_contact_person')"
        @handlerInputChange="handleChangeContactName"
        :isValid="this.validationState.contactName"
        :error="this.$i18n.t('val_empty')"
        :value="this.onboardingState.contactName"
      />
      <InputGroup
        id="onBoardingCity"
        :placeholder="$i18n.t('lb_city')"
        @handlerInputChange="handleChangeCity"
        :isValid="this.validationState.city"
        :error="this.$i18n.t('val_empty')"
        :value="this.onboardingState.city"
      />
      <InputGroup
        id="onBoardingPhone"
        :placeholder="$i18n.t('lb_phone')"
        @handlerInputChange="handleChangePhone"
        :isValid="this.validationState.phone"
        :error="this.$i18n.t('val_phone')"
        :value="this.onboardingState.phone"
      />
      <InputGroup
        id="onBoardingEmail"
        :placeholder="$i18n.t('lb_email')"
        :value="this.onboardingState.email"
        :disabled="true"
      />
    </div>
    <div class="OnboardingForm__checkbox">
      <input
        type="checkbox"
        :value="this.onboardingState.termsAccepted"
        @change="handleTermsChange"
      />
      <span>
        {{ this.$i18n.t('cp_onboarding_terms_1') }}
        <a :href="privacyPolicyLink" target="_blank">{{ this.$i18n.t('cp_onboarding_terms_2') }}</a>
        {{ this.$i18n.t('cp_onboarding_terms_3') }}
        <a :href="cookiesPolicyLink" target="_blank">{{ this.$i18n.t('cp_onboarding_terms_4') }}</a>
      </span>
    </div>
    <FormButton
      @handleClick="handleOnboarding"
      :isLoading="isLoading"
      classProp="OnboardingForm__button"
    >
      {{ this.$i18n.t('btn_send_request') }}
    </FormButton>
  </div>
</template>

<script>
import InputGroup from '@/components/atoms/Input/InputGroup.vue';
import FormButton from '@/components/atoms/Buttons/FormButton/FormButton.vue';
import {
  isValidForm,
  getPhoneValidationState,
  isValidDataText,
  sanitizeEmail,
} from '@/helpers/ValidationHelper';
import { PRIVACY_POLICY_LINK, COOKIES_POLICY_LINK } from '@/helpers/ComponentHelper';

export default {
  props: {
  },
  data: () => ({
    onboardingState: {
      email: null,
      name: null,
      contactName: null,
      city: null,
      phone: null,
      termsAccepted: false,
    },
    validationState: {
      email: null,
      name: null,
      contactName: null,
      city: null,
      phone: null,
      termsAccepted: false,
    },
    isLoading: false,
    privacyPolicyLink: PRIVACY_POLICY_LINK,
    cookiesPolicyLink: COOKIES_POLICY_LINK,
  }),
  methods: {
    handleChangeName(text) {
      this.onboardingState.name = text;
    },

    handleChangeContactName(text) {
      this.onboardingState.contactName = text;
    },

    handleChangeCity(text) {
      this.onboardingState.city = text;
    },

    handleChangePhone(text) {
      this.onboardingState.phone = text;
    },

    handleTermsChange() {
      this.onboardingState.termsAccepted = !this.onboardingState.termsAccepted;
    },

    async registerCollaborator() {
      const clinic = {
        name: this.onboardingState.name,
        contactName: this.onboardingState.contactName,
        city: this.onboardingState.city,
        phone: this.onboardingState.phone,
        email: this.onboardingState.email,
      };
      this.isLoading = true;
      this.$store.dispatch('clinic/createCollaboratorClinic', clinic)
        .then(() => {
          this.$emit('collaborationClinicCreated');
        })
        .catch(() => {
          this.resetState();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    handleOnboarding() {
      if (!this.onboardingState.termsAccepted) {
        return;
      }

      const nameValidationState = isValidDataText(this.onboardingState.name);
      const contactNamealidationState = isValidDataText(this.onboardingState.contactName);
      const cityNameValidationState = isValidDataText(this.onboardingState.city);
      const phoneValidationState = getPhoneValidationState(this.onboardingState.phone);

      this.validationState.name = nameValidationState;
      this.validationState.contactName = contactNamealidationState;
      this.validationState.city = cityNameValidationState;
      this.validationState.phone = phoneValidationState;

      if (isValidForm([
        nameValidationState,
        contactNamealidationState,
        cityNameValidationState,
        phoneValidationState])) {
        this.registerCollaborator()
          .then(() => {
            this.resetState();
            this.resetValidationState();
          });
      }
    },

    resetState() {
      this.onboardingState.email = null;
      this.onboardingState.name = null;
      this.onboardingState.contactName = null;
      this.onboardingState.city = null;
      this.onboardingState.phone = null;
      this.onboardingState.termsAccepted = false;
    },

    resetValidationState() {
      this.validationState.email = null;
      this.validationState.name = null;
      this.validationState.contactName = null;
      this.validationState.city = null;
      this.validationState.phone = null;
    },
  },
  mounted() {
    this.onboardingState.email = sanitizeEmail(this.$route.query.email);
  },
  components: {
    InputGroup,
    FormButton,
  },
};
</script>

<style lang="sass" >
@import './OnboardingForm.sass'
</style>
