<template>
  <div id="language-selector">
    <ul @click="switchLocale(locale)">
      <li v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang"
          @click="switchLocale(lang)"
      >
        <country-flag v-bind="{country:lang}" v-bind:value="{ lang }" size='normal'/>
      </li>
    </ul>
  </div>
</template>

<script>

import i18n from '@/plugins/vue-i18n';

export default {
  name: 'LocaleSwitcher',
  methods: {
    switchLocale(locale) {
      if (i18n.locale !== locale) {
        this.$i18n.locale = locale;
        this.$root.locale = locale;
        this.locale = locale;
      }
    },
  },
  data() {
    return { langs: ['es', 'gb'] };
  },
};
</script>

<style lang="sass" scoped>
    @import './LocaleSwitcher.sass'
</style>
