<template>
  <div class="LoginForm">
    <LocaleSwitcher v-if="!isMobile"/>
    <img
      src="@/assets/logos/logo_inline.svg"
      class="w-75"
    />
    <div class="sp__v" />
    <p class="text__pr" v-html="getText" />
    <div class="sp__v__2x" />
    <InputGroup
      v-if="!isUserVerification"
      id="loginEmail"
      :placeholder="$i18n.t('lb_email')"
      @handlerInputChange="handleChangeEmail"
      :isValid="this.validationState.email"
      :error="$i18n.t('val_email')"
      :value="this.loginState.email"
    />
    <div class="sp__v__1d5x" />
    <InputGroup
      id="loginPassword"
      :placeholder="$i18n.t('lb_password')"
      @handlerInputChange="handleChangePassword"
      @handleLogin="handleLogin"
      :isValid="this.validationState.password"
      :error="this.$i18n.t('val_user_verification')"
      type="password"
      :value="this.loginState.password"
    />
    <div class="sp__v__1d5x" />
    <FormButton
      @handleClick="handleLogin"
      :isLoading="isLoading"
    >
      {{ getButtonText }}
    </FormButton>
    <div class="sp__v__1s5x" />
    <router-link
      v-if="isLoginForm"
      class="forgot_link"
      :to="{ name : 'ForgotPassword' }">
        {{ getForgotPasswordText }}
    </router-link>
    <div class="sp__v__3x" />
    <LocaleSwitcher v-if="isMobile"/>
  </div>
</template>

<script>
import InputGroup from '@/components/atoms/Input/InputGroup.vue';
import FormButton from '@/components/atoms/Buttons/FormButton/FormButton.vue';
import {
  getEmailValidationState,
  isValidForm,
  isValidDataText,
  getPasswordValidationState,
  sanitizeEmail,
} from '@/helpers/ValidationHelper';
import LocaleSwitcher from '@/components/organisms/LocaleSwitcher/LocaleSwitcher.vue';
// import DEVICE from '../../../helpers/ResponsiveHelper';

export default {
  props: {
    isUserVerification: {
      type: Boolean,
      default: false,
    },
    verificationToken: {
      type: String,
      default: '',
    },
    isLoginForm: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    loginState: {
      email: null,
      password: null,
    },
    validationState: {
      email: null,
      password: null,
    },
    userToken: null,
    isLoading: false,
  }),
  methods: {
    handleChangeEmail(text) {
      this.loginState.email = text;
    },

    handleChangePassword(text) {
      this.loginState.password = text;
    },

    async login() {
      this.isLoading = true;
      if (!this.isUserVerification) {
        await this.$store.dispatch('auth/login', this.loginState)
          .then(() => {
            this.isLoading = false;
          })
          .catch(() => {
            this.isLoading = false;
            this.resetState();
          });
      }

      this.loadUser();
    },

    async loadUser() {
      await this.$store.dispatch('user/getUser');
      if (this.isMobile) {
        this.$router.push({ name: 'Request' });
      } else {
        this.$router.push({ name: 'Home' });
      }
    },

    handleLogin() {
      if (this.loginState.email) {
        this.setSanitizedEmail();
      }

      this.isLoading = true;
      const emailValidationState = getEmailValidationState(this.loginState.email);
      const passwordValidationState = getPasswordValidationState(this.loginState.password);
      const tokenValidationState = isValidDataText(this.currentUserToken);

      if (isValidForm([
        emailValidationState,
        passwordValidationState,
      ]) && !this.isUserVerification) {
        this.resetValidationState();
        this.login();
      } else if (isValidForm([tokenValidationState, passwordValidationState])
        && this.isUserVerification) {
        const userData = {
          token: this.currentUserToken,
          password: this.loginState.password,
        };

        this.validateUser(userData);
      } else {
        this.validationState.email = emailValidationState;
        this.validationState.password = passwordValidationState;
        this.isLoading = false;
        this.resetState();
      }
    },

    async validateUser(data) {
      await this.$store.dispatch('auth/verifyUser', data)
        .then((res) => {
          this.userToken = res.token;

          this.$router.push({ name: 'Request' });
        })
        .catch(() => {
        });
      this.isLoading = false;
    },

    resetState() {
      this.loginState.email = null;
      this.loginState.password = null;
    },

    resetValidationState() {
      this.validationState.email = null;
      this.validationState.password = null;
    },

    setSanitizedEmail() {
      const sanitizedEmail = sanitizeEmail(this.loginState.email);
      this.loginState.email = sanitizedEmail;
    },
  },
  // mounted() {
  //   /* TODO: VP-14 REPLICAR EN las páginas de login */
  //   const resizeObserver = new ResizeObserver((entries) => {
  //     const [element] = entries;
  //     const isMobile = element.contentRect.width < DEVICE.MOBILE_WIDTH;
  //     this.$store.commit('responsive/setIsMobile', isMobile);
  //   });

  //   resizeObserver.observe(this.$el);
  // },
  computed: {
    isMobile() {
      return this.$store.getters['responsive/isMobile'];
    },

    currentUserToken() {
      return this.verificationToken ? this.verificationToken : this.userToken;
    },

    getButtonText() {
      return !this.isUserVerification ? this.$i18n.t('btn_login') : this.$i18n.t('btn_verify');
    },

    getText() {
      return !this.isUserVerification ? this.$i18n.t('txt_login') : this.$i18n.t('txt_new_user_access');
    },

    getForgotPasswordText() {
      return this.$i18n.t('lb_forgot_password');
    },
  },
  components: {
    InputGroup,
    FormButton,
    LocaleSwitcher,
  },
};
</script>
