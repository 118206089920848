<template>
  <div v-if="!isHidden" class="TableFilters">
    <Input
      :id="`${id}-tableSearch`"
      type="text"
      :placeholder="$i18n.t('cp_search')"
      @handlerInputChange="handleSearch"
      :value="searchText"
    />
    <div class="sp__1s5x"/>
    <div class="d-flex">
      <b-button
        @click.prevent="handleCleanSearch"
        variant="secondary"
        size="sm"
      >
        <img src="@/assets/actions/close.svg" />
      </b-button>

      <div class="sp__1s5x"/>
      <b-button
        @click.prevent="handleShowFilters"
        variant="secondary"
        size="sm"
        v-if="showFilterButton"
      >
        <span class="TableFilters__filterText">{{ getFilterCount }}</span>
      </b-button>

      <div v-if="showImportButton" class="sp__1s5x"/>
      <b-button
        v-if="showImportButton"
        variant="secondary"
        size="sm"
        @click.prevent="handleImport"
      >
        {{ $i18n.t('btn_import') }}
      </b-button>

      <div v-if="showExportButton" class="sp__1s5x"/>
      <b-button
        v-if="showExportButton"
        variant="secondary"
        size="sm"
        @click.prevent="handleExport"
      >
        {{ $i18n.t('btn_export') }}
      </b-button>
    </div>
    <SideBar
      :id="id"
      @handleAccept="handleAccept"
      @onHiddenSidebar="onHiddenSidebar"
      :filterButton="filterButton"
      showFooter
    >
      <slot></slot>
    </SideBar>
  </div>
</template>

<script>
import Input from '@/components/atoms/Input/Input.vue';
import SideBar from '@/components/atoms/SideBar/SideBar.vue';

const MIN_SEARCH__LENGHT = 3;

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    searchText: {
      type: String,
      default: '',
    },
    filterCount: {
      type: Number,
      default: 0,
    },
    isHidden: {
      type: Boolean,
      default: false,
    },
    showImportButton: {
      type: Boolean,
      default: false,
    },
    showFilterButton: {
      type: Boolean,
      default: true,
    },
    showExportButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      filterButton: true,
    };
  },
  methods: {
    handleSearch(text) {
      if (text?.length >= MIN_SEARCH__LENGHT) {
        this.$emit('handleSearch', text);
      } else {
        this.$emit('handleSearch', null);
      }
    },

    handleShowFilters() {
      const sidebarId = `${this.id}-sidebar`;

      this.$root.$emit('bv::toggle::collapse', sidebarId);
    },

    handleAccept() {
      this.$emit('handleSearchByFilter');
    },

    handleCleanSearch() {
      this.$emit('handleCleanSearch', null);
    },

    onHiddenSidebar() {
      this.$emit('onHiddenSidebar');
    },

    handleImport() {
      this.$emit('handleImport');
    },

    handleExport() {
      this.$emit('handleExport');
    },
  },
  computed: {
    getFilterCount() {
      const filterText = this.$i18n.t('btn_filters');

      return this.filterCount > 0 ? `${filterText} (${this.filterCount})` : filterText;
    },
  },
  components: {
    Input,
    SideBar,
  },
};
</script>

<style lang="sass" scoped>
@import './TableFilters.sass'
</style>
