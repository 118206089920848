import getAxiosWithAuthHeader from '../../helpers/AuthHelper';
import {
  getDataWithUuid,
  getFileNameFromContentDisposition,
  getParameterUrl,
  getSearchUrl,
} from '../../helpers/ApiHelper';
import PatientFactory from './PatientFactory';

const PATIENTS = '/api/patients';
const SEARCH_PATIENTS = '/api/patients/search';
const IMPORT_PATIENTS = '/api/import/patients';
const EXPORT_PATIENTS = '/api/export/patients';

class patientAPI {
  constructor() {
    this.client = getAxiosWithAuthHeader();
  }

  getAllPatient(currentPage) {
    const defaultOrder = 'createdAt:desc';

    const parameterUrl = getParameterUrl(SEARCH_PATIENTS, currentPage, '', defaultOrder);

    return this.client
      .get(parameterUrl)
      .then((res) => PatientFactory.getAllPatient(res.data))
      .catch((error) => Promise.reject(error));
  }

  filterPatient(data) {
    const parameterUrl = getParameterUrl(
      SEARCH_PATIENTS,
      data.page,
      data.filters,
      data.ordenation,
    );

    return this.client
      .get(parameterUrl)
      .then((res) => PatientFactory.getAllPatient(res.data))
      .catch((error) => Promise.reject(error));
  }

  searchPatient(filters) {
    const filterUrl = getSearchUrl(SEARCH_PATIENTS, filters);

    return this.client
      .get(filterUrl)
      .then((res) => PatientFactory.getAllPatient(res.data))
      .catch((error) => Promise.reject(error));
  }

  createPatient(data) {
    const idData = getDataWithUuid(data);

    return this.client
      .post(PATIENTS, idData)
      .then(() => idData)
      .catch((error) => Promise.reject(error));
  }

  updatePatient(requestData) {
    const url = `${PATIENTS}/${requestData.id}`;

    return this.client
      .put(url, requestData.body)
      .then()
      .catch((error) => Promise.reject(error));
  }

  deletePatient(uuid) {
    const url = `${PATIENTS}/${uuid}`;

    return this.client
      .delete(url)
      .then()
      .catch((error) => Promise.reject(error));
  }

  getPatientReport(interval) {
    const url = `${PATIENTS}/report?interval=${interval}`;

    return this.client
      .get(url)
      .then()
      .catch((error) => Promise.reject(error));
  }

  importPatients(data) {
    return this.client
      .post(IMPORT_PATIENTS, data)
      .then()
      .catch((error) => Promise.reject(error));
  }

  exportPatients() {
    return this.client
      .get(EXPORT_PATIENTS, { responseType: 'arraybuffer' })
      .then((response) => {
        const link = document.createElement('a');
        const blob = new Blob([response.data], { type: response.headers['content-type'] });
        const documentUrl = URL.createObjectURL(blob);
        link.href = documentUrl;

        const contentDisposition = response.headers['content-disposition'];
        link.setAttribute('download', getFileNameFromContentDisposition(contentDisposition));

        link.click();
      });
  }
}

export default patientAPI;
