export default {
  // Search
  cp_search: 'Search',
  // Whatsapp
  cp_whatsapp_contact: 'Contact by Whatsapp',
  cp_whatsapp_auth: 'The patient has given authorization to contact via Whatsapp.',
  // Whatsapp
  cp_file_uploader_help: 'Aspect ratio 1:1 (Ej: 100x100 px)',
  // Collaborations
  cp_enable_collaborations: 'Available for collaboration',
  cp_enable_collaborations_sub: 'You are currently available to collaborate with other clinics.',
  // Heading
  cp_created_at: 'Created on {date}',
  cp_patient_info_title: 'Patient information',
  cp_patient_info_text: 'Basic patient information',
  cp_patient_contact_title: 'Contact information',
  cp_patient_contact_text: 'Patient contact information',
  cp_patient_request_text: 'Requests made by the patient',
  cp_request_inbox_title: 'Inbox requests',
  cp_request_inbox_text: 'Good morning, {name}. Explore your requests.',
  cp_request_home_title: 'Your activity summary',
  cp_insights_home_title: 'Average price and response time',
  cp_insights_home_text: 'Filter by date and know the status of your requests in real time.',
  // Pagination
  cp_pagination_count: '<b>{from}</b> a <b>{to}</b> de <b>{total}</b> results',
  // Degrees
  cp_degree_low: 'Low',
  cp_degree_medium: 'Medium',
  cp_degree_high: 'High',
  // Boolean
  cp_boolean_true: 'Si',
  cp_boolean_false: 'No',
  // Languages
  cp_spanish_language: 'Spanish',
  cp_english_language: 'English',
  cp_select_language: 'Select language',
  // Stages
  cp_stage_received: 'Received',
  cp_stage_documentation_revision: 'Documentation review',
  cp_stage_specialist_evaluation: 'Specialist evaluation',
  cp_stage_treatment_plan: 'Treatment plan',
  cp_stage_in_treatment: 'In treatment',
  cp_stage_medical_discharge: 'Medical Discharge',
  // Stages
  cp_status_pending: 'Pending',
  cp_status_accepted: 'Accepted',
  cp_status_rejected: 'Rejected',
  cp_status_canceled: 'Canceled',
  cp_status_failed: 'Failed',
  cp_status_collaboration: 'Collaboration',
  // Onboarding
  cp_onboarding_title: 'Patients and doctors, connected.',
  cp_onboarding_description: 'At VidasPrime we want to transform the current healthcare model. And we rethink it from the joint needs of the doctor and the patient, connecting both visions, looking for new solutions that are more efficient and satisfactory for everyone.',
  cp_onboarding_list_1: 'Doctors and patients, together and connected',
  cp_onboarding_list_2: 'Online patient connection tools',
  cp_onboarding_list_3: 'We place the patient at the center of our decisions.',
  cp_onboarding_register: 'Register to collaborate',
  cp_onboarding_terms_1: 'I have read and accept: the ',
  cp_onboarding_terms_2: 'Terms of Service',
  cp_onboarding_terms_3: 'and the',
  cp_onboarding_terms_4: 'Privacy and Cookies Policy',
  cp_onboarding_successfull_register_title: 'You have successfully registered',
  cp_onboarding_successfull_register_description: 'Please do not forget to log in to our platform to accept or reject the application.',
  cp_contact_us: 'Contact us',
  cp_go_to_site: 'Go to the website',
  // Insights
  cp_from: 'From',
  cp_to: 'To',
  cp_graph_3_title: 'Average price first consultation',
  cp_graph_4_title: 'Average response time',
  cp_you: 'You',
  cp_max: 'Max',
  cp_min: 'Min',
  cp_avg: 'Avg',
  cp_accepted: 'Accepted',
  cp_with_appointment: 'Appointment pending',
  cp_confirmed_appointment: 'Confirmed attendance',
  cp_without_appointment: 'Without appointment',
  // Months
  cp_jan: 'Jan',
  cp_feb: 'Feb',
  cp_mar: 'Mar',
  cp_apr: 'Apr',
  cp_may: 'May',
  cp_jun: 'Jun',
  cp_jul: 'Jul',
  cp_aug: 'Aug',
  cp_sep: 'Sep',
  cp_oct: 'Oct',
  cp_nov: 'Nov',
  cp_dec: 'Dec',
  // File uploader
  cp_upload_a_file: 'Upload a file',
  cp_or_drag: 'or drag.',
  // Table
  cp_not_result: 'No results',
  // Modal
  cp_coming_soon: 'Coming soon',
  cp_coming_soon_text: 'New functionalities available on the platform',
  cp_survey_type_partials_type_is_required: 'If selected, the patient will not be able to submit the form without answering this item.',
  // Become premium modal
  cp_become_premium: 'Become a premium member',
  cp_become_premium_text: 'Upgrade your plan to unlock more features',
  // Invitation
  cp_radio_clinic: 'With a registered clinic',
  cp_radio_email: 'With an unregistered clinic',

  // Mobile
  cpm_request: 'My request',
  cpm_sent: 'Invitations sent',
  cpm_received: 'Invitations received',
};
