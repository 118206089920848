import PartialsTypeApi from '@/domain/partialsType/PartialsTypeApi';

export default function getAllPartialsTypes({ commit }, data) {
  const partialsTypeApi = new PartialsTypeApi();
  const getAllPartialsType = partialsTypeApi.getAllPartialsTypes(data);

  return getAllPartialsType
    .then((res) => {
      commit('partialsType/setPartialsTypes', res, { root: true });
      return res;
    })
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}
