export default class SurveyModel {
  constructor(data) {
    this.id = data.id;
    this.sendBy = data.sendBy;
    this.patient = data.patient;
    this.surveyType = data.surveyType;
    this.request = data.request;
    this.answers = data.answers;
    this.sendAt = data.sendAt;
    this.answeredAt = data.answeredAt;
    this.createdAt = data.createdAt;
    this.deletedAt = data.deletedAt;
  }
}
