import InvitationApi from '../../domain/invitations/InvitationsApi';
import { STATUS } from '../../helpers/ComponentHelper';

export function findClinics({ commit }, data) {
  const invitationApi = new InvitationApi();
  const findClinicsRequest = invitationApi.findClinics(data);

  return findClinicsRequest
    .then((res) => {
      commit('invitations/setClinics', res, { root: true });
      return res;
    })
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function createInvitation({ commit }, data) {
  const invitationApi = new InvitationApi();
  const createRequest = invitationApi.createInvitation(data);

  return createRequest
    .then()
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function cancelInvitation({ commit }, uuid) {
  const invitationApi = new InvitationApi();
  const cancelInv = invitationApi.cancelInvitation(uuid);

  const data = {
    id: uuid,
    body: {
      status: STATUS.CANCELED.key,
    },
  };

  return cancelInv
    .then(() => {
      commit('invitations/updateInvitationStatus', data, { root: true });
    })
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function acceptInvitation({ commit }, uuid) {
  const invitationApi = new InvitationApi();
  const acceptInv = invitationApi.acceptInvitation(uuid);

  const data = {
    id: uuid,
    body: {
      status: STATUS.ACCEPTED.key,
    },
  };

  return acceptInv
    .then(() => {
      commit('invitations/updateInvitationStatus', data, { root: true });
    })
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function rejectInvitation({ commit }, uuid) {
  const invitationApi = new InvitationApi();
  const rejectInv = invitationApi.rejectInvitation(uuid);

  const data = {
    id: uuid,
    body: {
      status: STATUS.REJECTED.key,
    },
  };

  return rejectInv
    .then(() => {
      commit('invitations/updateInvitationStatus', data, { root: true });
    })
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function getInvitations({ commit }, data) {
  const invitationApi = new InvitationApi();
  const invitationListRequest = invitationApi.getInvitations(data);

  return invitationListRequest
    .then((res) => {
      commit('invitations/setInvitations', res, { root: true });
      return res;
    })
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}
