import getAxiosWithAuthHeader from '../../helpers/AuthHelper';
import KpiFactory from './KpiFactory';
import {
  getFiltersUrl,
} from '../../helpers/ApiHelper';

const KPIS = '/api/kpis';
const KPIS_PRICE = `${KPIS}/price`;
const KPIS_REQUEST = `${KPIS}/request`;
const KPIS_TIME = `${KPIS}/time`;
const KPIS_APPOINTMENT = `${KPIS}/appointment`;

class kpiAPI {
  constructor() {
    this.kpi = getAxiosWithAuthHeader();
  }

  getAllKpi(data) {
    let parameterUrl;

    if (data === undefined) {
      parameterUrl = `${KPIS}/week`;
    } else {
      parameterUrl = `${KPIS}/${data}`;
    }

    return this.kpi
      .get(parameterUrl)
      .then((res) => KpiFactory.getAllKpi(res.data))
      .catch((error) => Promise.reject(error));
  }

  getRequests(data) {
    const parameterUrl = getFiltersUrl(
      KPIS_REQUEST,
      data.filters,
    );

    return this.kpi
      .get(parameterUrl)
      .then((res) => KpiFactory.getRequests(res.data))
      .catch((error) => Promise.reject(error));
  }

  getAccepted(data) {
    const parameterUrl = getFiltersUrl(
      KPIS_APPOINTMENT,
      data.filters,
    );

    return this.kpi
      .get(parameterUrl)
      .then((res) => KpiFactory.getAccepted(res.data))
      .catch((error) => Promise.reject(error));
  }

  getAveragePrice(data) {
    const parameterUrl = getFiltersUrl(
      KPIS_PRICE,
      data.filters,
    );

    return this.kpi
      .get(parameterUrl)
      .then((res) => KpiFactory.getAveragePrice(res.data))
      .catch((error) => Promise.reject(error));
  }

  getAverageTimeRequest(data) {
    const parameterUrl = getFiltersUrl(
      KPIS_TIME,
      data.filters,
    );

    return this.kpi
      .get(parameterUrl)
      .then((res) => KpiFactory.getAverageTimeRequest(res.data))
      .catch((error) => Promise.reject(error));
  }
}

export default kpiAPI;
