<template>
  <div class="Table Table__list">
    <b-table
      thead-class="d-none"
      :items="items"
      :fields="columns"
      fixed
    >
      <template #cell(info)="data">
        <p class="text__psbb text-dark ellipsis">{{ data.item.content  }}</p>
        <div class="sp__v__1s5x"/>
        <p class="text__pr text-soft-dark">{{ data.item.createdAt | formatDate  }}</p>
      </template>
      <template #cell(actions)="data">
        <div class="Table__list__actions">
          <b-button
            variant="secondary"
            @click.prevent="handleShowDetail(data.item)"
          >
            <img src="@/assets/actions/preview.svg" />
          </b-button>
          <div class="sp__1s5x"/>
          <b-button
            v-if="canEdit"
            variant="outline-primary"
            @click.prevent="handleEdit(data.item)"
          >
            <inline-svg
              class="icon__primary"
              :src="require('@/assets/actions/edit.svg')"
            />
          </b-button>
          <div class="sp__1s5x"/>
          <b-button
            v-if="canDelete"
            variant="outline-primary"
            @click.prevent="handleDelete(data.item.id)"
          >
            <inline-svg
              class="icon__primary"
              :src="require('@/assets/actions/delete.svg')"
            />
          </b-button>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>

import i18n from '@/plugins/vue-i18n';

export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    canDelete: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    columns() {
      return [
        { key: 'info', label: i18n.t('lb_name'), sortable: true },
        { key: 'actions', label: '' },
      ];
    },
  },
  methods: {
    handleShowDetail(note) {
      this.$emit('handleShowDetail', note);
    },

    handleEdit(note) {
      this.$emit('handleEdit', note);
    },

    handleDelete(id) {
      this.$emit('handleDelete', id);
    },
  },
  data() {
    return {
      sortBy: 'case',
      sortDesc: false,
    };
  },
};
</script>

<style lang="sass" scoped>
  @import '../Table.sass'
</style>
