import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export function getCurrentTab(state) {
  return state.currentTab;
}

export function getShowMobileDetails(state) {
  return state.showMobileDetails;
}

export function getIsUserLoading(state) {
  return state.isUserLoading;
}
