export default {
  // Search
  cp_search: 'Buscar',
  // Whatsapp
  cp_whatsapp_contact: 'Contactar por Whatsapp',
  cp_whatsapp_auth: 'El paciente ha dado autorización para poder contactar vía WhatsApp.',
  // Whatsapp
  cp_file_uploader_help: 'Aspect ratio 1:1 (Ej: 100x100 px)',
  // Collaborations
  cp_enable_collaborations: 'Disponible para colaborar',
  cp_enable_collaborations_sub: 'Actualmente estás disponible para colaborar con otras clínicas.',
  // Heading
  cp_created_at: 'Creado el {date}',
  cp_patient_info_title: 'Información del paciente',
  cp_patient_info_text: 'Información básica sobre el paciente',
  cp_patient_contact_title: 'Información de contacto',
  cp_patient_contact_text: 'Información de contacto del paciente',
  cp_patient_request_text: 'Solicitudes realizadas por el paciente',
  cp_request_inbox_title: 'Inbox de solicitudes',
  cp_request_inbox_text: 'Buenos días, {name}. Explora tus solicitudes.',
  cp_request_home_title: 'Resumen de tu actividad',
  cp_insights_home_title: 'Precio medio y tiempo medio de respuesta',
  cp_insights_home_text: 'Filtra por fecha y conoce el estado de tus solicitudes a tiempo real.',
  // Pagination
  cp_pagination_count: '<b>{from}</b> a <b>{to}</b> de <b>{total}</b> resultados',
  // Degrees
  cp_degree_low: 'Bajo',
  cp_degree_medium: 'Medio',
  cp_degree_high: 'Alto',
  // Boolean
  cp_boolean_true: 'Si',
  cp_boolean_false: 'No',
  // Languages
  cp_spanish_language: 'Español',
  cp_english_language: 'Ingles',
  cp_select_language: 'Selecciona idioma',
  // Stages
  cp_stage_received: 'Recibida',
  cp_stage_documentation_revision: 'Revisión documentación',
  cp_stage_specialist_evaluation: 'Valoración especialista',
  cp_stage_treatment_plan: 'Plan de tratamiento',
  cp_stage_in_treatment: 'En tratamiento',
  cp_stage_medical_discharge: 'Alta médica',
  // Stages
  cp_status_pending: 'Pendiente',
  cp_status_accepted: 'Aceptada',
  cp_status_rejected: 'Rechazada',
  cp_status_canceled: 'Cancelada',
  cp_status_failed: 'Fallida',
  cp_status_collaboration: 'Colaboración',
  // Onboarding
  cp_onboarding_title: 'Pacientes y doctores, conectados.',
  cp_onboarding_description: 'En VidasPrime queremos transformar el modelo sanitario actual. Y lo repensamos desde las necesidades conjuntas del doctor y del paciente, conectando ambas visiones, buscando nuevas soluciones que sean más eficientes y satisfactorias para todos.',
  cp_onboarding_list_1: 'Doctores y pacientes, juntos y conectados',
  cp_onboarding_list_2: 'Herramientas online de conexión con el paciente',
  cp_onboarding_list_3: 'Colocamos al paciente en el centro de las decisiones',
  cp_onboarding_register: 'Registro para colaborar',
  cp_onboarding_terms_1: 'He leído y acepto: las',
  cp_onboarding_terms_2: 'Condiciones del servicio',
  cp_onboarding_terms_3: 'y la',
  cp_onboarding_terms_4: 'política de privacidad y cookies',
  cp_onboarding_successfull_register_title: 'Te has registrado correctamente',
  cp_onboarding_successfull_register_description: 'Por favor, no olvides entrar en nuestra plataforma para aceptar o rechazar la solicitud',
  cp_contact_us: 'Contacta con nosotros',
  cp_go_to_site: 'Ir a la web',
  // Insights
  cp_from: 'Desde',
  cp_to: 'Hasta',
  cp_graph_3_title: 'Precio medio primera consulta',
  cp_graph_4_title: 'Tiempo medio respuesta',
  cp_you: 'Tú',
  cp_max: 'Máx',
  cp_min: 'Mín',
  cp_avg: 'Med',
  cp_accepted: 'Aceptadas',
  cp_with_appointment: 'Cita pendiente',
  cp_confirmed_appointment: 'Asistencia confirmada',
  cp_without_appointment: 'Sin cita',
  // Months
  cp_jan: 'Ene',
  cp_feb: 'Feb',
  cp_mar: 'Mar',
  cp_apr: 'Abr',
  cp_may: 'May',
  cp_jun: 'Jun',
  cp_jul: 'Jul',
  cp_aug: 'Ago',
  cp_sep: 'Sep',
  cp_oct: 'Oct',
  cp_nov: 'Nov',
  cp_dec: 'Dic',
  // File uploader
  cp_upload_a_file: 'Sube un archivo',
  cp_or_drag: 'o arrastralo.',
  // Table
  cp_not_result: 'No hay resultados',
  // Modal
  cp_coming_soon: 'Próximamente',
  cp_coming_soon_text: 'Nuevas funcionalidades disponibles en la plataforma',
  cp_survey_type_partials_type_is_required: 'Si está seleccionada, el paciente no podrá enviar el formulario sin dar respuesta a este elemento.',
  // Become premium modal
  cp_become_premium: 'Cónviertete en un miembro premium',
  cp_become_premium_text: 'Mejora tu plan para conseguir nuevas funcionalidades',
  // Invitation
  cp_radio_clinic: 'Con una clínica registrada',
  cp_radio_email: 'Con una clínica no registrada',

  // Mobile
  cpm_request: 'Mis solicitudes',
  cpm_sent: 'Invitaciones enviadas',
  cpm_received: 'Invitaciones recibidas',
};
