/* eslint-disable class-methods-use-this */
import ReducedClinicModel from './ReducedClinicModel';
import PaginationFactory from '../pagination/PaginationFactory';

class InvitationFactory {
  constructor() {
    this.invitation = {};
    this.reducedClinic = {};
  }

  findClinics(data) {
    const findClinicsResponse = {
      clinicList: this.getClinicsCollections(data.collection),
      pagination: PaginationFactory.getPagination(data?.pagination),
    };

    return findClinicsResponse;
  }

  getClinicsCollections(dataList) {
    this.invitation = {};
    const clinicList = dataList.map(
      (clinic) => new ReducedClinicModel({
        id: clinic.user.id,
        avatar: clinic.user.avatar,
        name: clinic.name,
        email: clinic.user.email,
        phone: clinic.phone,
      }),
    );
    return clinicList;
  }

  getInvitations(data) {
    const InvitationResponse = {
      invitationList: this.formatInvitationsCollection(data?.collection),
      pagination: PaginationFactory.getPagination(data?.pagination),
    };
    return InvitationResponse;
  }

  setRequest(inv) {
    if (!inv) {
      return {};
    }
    const { request, collaborator } = inv;
    const collaborators = request.collaborators ? [...request.collaborators] : [];

    if (!collaborators.find((col) => col.id === collaborator.id)) {
      collaborators.push(collaborator);
    }

    return { ...request, collaborators };
  }

  formatInvitationsCollection(dataList) {
    const invitationList = dataList.map((inv) => {
      const invitation = {
        request: inv?.request,
        id: inv?.id,
        caseNumber: inv?.request.caseNumber,
        date: inv?.createdAt,
        patientName: `${inv?.request?.patient?.name} ${inv?.request?.patient?.lastname}`,
        origin: inv?.request?.patient?.origin,
        specialty: inv?.request?.specialty,
        procedure: inv?.request?.medicalProcedure,
        invited: {
          name: inv?.collaborator?.name || inv?.collaboratorName,
          avatar: inv?.collaborator?.avatar,
        },
        invitedBy: {
          name: inv?.clinic?.name,
          avatar: inv?.clinic?.avatar,
        },
        stage: inv?.request?.requestStage,
        status: inv?.status.toLowerCase(),
      };
      return invitation;
    });

    return invitationList;
  }
}

export default new InvitationFactory();
