<template>
  <div id="chart" class="container charts">
    <Spinner
      :isLoading="isLoading"
    />
    <div v-if="isEmpty" class="noData">
      <div class="noData-text">
        <span >{{ $i18n.t('val_empty_data') }}</span>
      </div>
    </div>
    <apexchart
      v-if="!isLoading"
      :options="options"
      height='475'
      :series= 'isEmpty ? [] : series'
    ></apexchart>
  </div>
</template>

<script>
import Spinner from '@/components/atoms/Spinner/Spinner.vue';

export default {
  name: 'ChartBar',
  props: {
    series: {
      type: Array,
    },
    categories: {
      type: Array,
    },
    title: {
      type: String,
    },
    isLoading: Boolean,
    isEmpty: Boolean,
  },
  data() {
    return {
      options: {
        dataLabels: {
          enabled: false,
        },
        chart: {
          type: 'bar',
          stacked: true,
          toolbar: {
            show: false,
            tools: {
              selection: true,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
            },
          },
        },
        plotOptions: {
          bar: {
            columnWidth: '50%',
            borderRadius: 4,
            borderRadiusApplication: 'end',
            borderRadiusWhenStacked: 'last',
            dataLabels: {
              total: {
                enabled: true,
                offsetY: -4,
                formatter: (val) => (val > 0 ? val : ''),
              },
            },
          },
        },
        title: {
          text: this.title,
          align: 'left',
          offsetX: 10,
          offsetY: -5,
          floating: true,
          style: {
            fontSize: '14px',
            fontWeight: '600',
          },
        },
        xaxis: {
          categories: this.categories,
        },
        yaxis: {
          labels: {
            formatter(val) {
              return val.toFixed(0);
            },
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          position: 'top',
          horizontalAlign: 'right',
          offsetY: -5,
          markers: {
            radius: 100,
            offsetX: -5,
            offsetY: 1,
          },
          itemMargin: {
            horizontal: 15,
            vertical: 0,
          },
        },
        noData: {
          text: '',
        },
      },
    };
  },
  components: {
    Spinner,
  },
};
</script>

<style lang="sass" scoped>
  @import './charts.sass'
</style>
