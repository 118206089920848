<template>
  <ModalAction
    :id="id"
    :title="getTitle"
    :okLabel="$i18n.t('btn_update')"
    :cancelLabel="$i18n.t('btn_cancel')"
    @handleOk="uploadAppointment"
    :hideFooter="isDisabled"
    :isLoading="isLoading"
  >
    <Textarea
      @handlerChange="handlerChange"
      :value="this.appointment.comment"
      :disabled="isDisabled"
    />
  </ModalAction>
</template>

<script>
import ModalAction from '@/components/molecules/Modal/ModalAction.vue';
import Textarea from '@/components/atoms/Textarea/Textarea.vue';
// import { getCurrentDate } from '@/helpers/ComponentHelper';

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      appointment: {
        id: null,
        comment: '',
        createdAt: '',
      },
      date: new Date(),
      timezone: '',
    };
  },
  methods: {
    setAppointment(appointment) {
      this.appointment.id = appointment.id;
      this.appointment.comment = appointment.comment;
      this.appointment.date = appointment.date;
      this.appointment.isConfirmed = appointment.isConfirmed;
      this.appointment.requestId = appointment.requestId;
    },

    handlerChange(content) {
      this.appointment.comment = content;
    },

    uploadAppointment() {
      const newAppointment = {
        requestId: this.appointment.requestId,
        appointmentId: this.appointment.id,
        date: this.appointment.date,
        comment: this.appointment.comment,
        isConfirmed: this.appointment.isConfirmed,
      };

      this.$emit('handleUpdate', newAppointment);
    },
  },
  computed: {
    isDisabled() {
      return !this.isEditable;
    },

    getTitle() {
      return this.isDisabled ? this.$i18n.t('lb_note') : this.$i18n.t('lb_add_note');
    },
  },
  components: {
    ModalAction,
    Textarea,
  },
};
</script>
