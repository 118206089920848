import getAxiosWithAuthHeader from '../../helpers/AuthHelper';
import {
  getDataWithUuid,
  getParameterUrl,
  getSearchUrl,
} from '../../helpers/ApiHelper';
import SurveyFactory from './SurveyFactory';

const SURVEYS = '/api/survey';
const SEARCH_SURVEYS = '/api/survey/search';

class surveyAPI {
  constructor() {
    this.client = getAxiosWithAuthHeader();
  }

  getAllSurvey(currentPage) {
    const url = `${SURVEYS}/${currentPage.uuid}`;

    const parameterUrl = getParameterUrl(url, currentPage.page,
      [], [], currentPage.itemsPerPage);

    return this.client
      .get(parameterUrl)
      .then((res) => SurveyFactory.getAllSurvey(res.data))
      .catch((error) => Promise.reject(error));
  }

  filterSurvey(data) {
    const parameterUrl = getParameterUrl(
      SEARCH_SURVEYS,
      data.page,
      data.filters,
      data.ordenation,
    );

    return this.client
      .get(parameterUrl)
      .then((res) => SurveyFactory.getAllSurvey(res.data))
      .catch((error) => Promise.reject(error));
  }

  searchSurvey(filters) {
    const filterUrl = getSearchUrl(SEARCH_SURVEYS, filters);

    return this.client
      .get(filterUrl)
      .then((res) => SurveyFactory.getAllSurvey(res.data))
      .catch((error) => Promise.reject(error));
  }

  createSurvey(data) {
    const idData = getDataWithUuid(data);

    return this.client
      .post(SURVEYS, idData)
      .then(() => idData)
      .catch((error) => Promise.reject(error));
  }

  updateSurvey(requestData) {
    const url = `${SURVEYS}/${requestData.id}`;
    return this.client
      .put(url, requestData.body)
      .then()
      .catch((error) => Promise.reject(error));
  }

  deleteSurvey(id) {
    const url = `${SURVEYS}/${id}`;

    return this.client
      .delete(url)
      .then()
      .catch((error) => Promise.reject(error));
  }
}

export default surveyAPI;
