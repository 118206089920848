<template>
  <b-modal
    :id="id"
    hide-footer
    :hide-header="hideHeader"
    :title="title"
    :centered="isCentered"
    no-close-on-backdrop
    lazy
    @hidden="onHidden"
    :modal-class="chooseClass"
  >
    <template v-slot:modal-header-close>
      <img src="@/assets/actions/close.svg" />
    </template>
    <slot></slot>
  </b-modal>
</template>

<script>

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    isCentered: {
      type: Boolean,
      default: false,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    isForm: {
      type: Boolean,
      default: false,
    },
    isClean: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    chooseClass() {
      const form = this.isForm ? 'Modal__form' : '';
      const clean = this.isClean ? 'Modal__clean' : '';
      return `${clean} ${form}`;
    },
  },
  methods: {
    onHidden() {
      this.$emit('onHidden');
    },
  },
};
</script>
