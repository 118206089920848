import {
  getAllKpi, getRequests, getAccepted, getAveragePrice, getAverageTimeRequest,
} from './kpiActions';

const kpi = {
  namespaced: true,
  actions: {
    getAllKpi,
    getRequests,
    getAccepted,
    getAveragePrice,
    getAverageTimeRequest,
  },
};

export default kpi;
