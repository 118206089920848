<template>
  <private-template>
    <template v-slot:private-template>
      <HeadingBar
        :title="$i18n.t('lb_home')"
        :text="$i18n.t('txt_home')"
        class="HomePage"
      />
      <div class="sp__v__3x"/>
      <div class="HomePage__insights-wrapper">
          <div class="HomePage__header">
            <div>
              <h2 class="HomePage__header-title">{{$i18n.t('lb_request')}}</h2>
              <p class="HomePage__header-text">{{$i18n.t('lb_insight_description')}}</p>
            </div>
            <div class="HomePage__filters">
              <DatepickerGropup
                id="fromFilter"
                :placeholder="$i18n.t('cp_from')"
                @handleChangeDate="handleFromDateChange"
                :value="fromDate"
                :max="maxDate"
              />
              <DatepickerGropup
                id="toFilter"
                :placeholder="$i18n.t('cp_to')"
                @handleChangeDate="handleToDateChange"
                :value="toDate"
                :min="fromDate"
                :max="maxDate"
              />
            </div>
          </div>
          <div class="HomePage__cards-wrapper">
            <InsightCard
              v-for="(kpi, index) in kpis"
              :key="index"
              :label="getKpisText[kpi.label]"
              :value="kpi.value"
              :isLoading="isLoading"
              :variant="kpi_variants[index]"
            />
          </div>
        <div class="HomePage__chart">
          <ChartBar
            v-if="!isLoading && totalCategories.length"
            :categories="totalCategories"
            :series="totalSeries"
            :title="$i18n.t('lb_total')"
            :isEmpty="totalSeriesIsEmpty"
          />
          <ChartRadial
            v-if="!isLoading && acceptedSeries.length"
            :series="acceptedSeries"
            :labels="acceptedLabels"
            :values="acceptedValues"
            :colors="acceptedColors"
            :title="$i18n.t('lb_requests_accepted')"
            :isEmpty="totalAcceptedIsEmpty"
          />
        </div>
      </div>

      <div class="sp__v__2x"/>

      <div v-if="!isDoctor" class="HomePage HomePage__insights-wrapper">
        <b-row>
          <b-col class="col-10 p-0">
            <h2 class="RequestHeadingCards__title">
              {{$i18n.t('cp_insights_home_title')}}
            </h2>
            <p class="RequestHeadingCards__text HomePage__text">
              {{$i18n.t('cp_insights_home_text')}}
            </p>
          </b-col>
          <b-col class="col-2 p-0 my-auto">
            <SelectGroup
              id="selectSpecialty"
              @handlerSelect="handleChangeSelect"
              v-if="user !== undefined"
              :options="getSpecialtyOptions"
              :selectedOption="getSelectedSpecialty"
              :isDisabled="false"
            />
          </b-col>
        </b-row>
        <div class="HomePage__chart">
          <ChartLineal
            v-if="!isLoadingInsights"
            :title="$i18n.t('cp_graph_3_title')"
            :series="averagePriceSeries"
            :isEmpty="averagePriceEmpty"
            type="currency"
          />
          <ChartLineal
            v-if="!isLoadingInsights"
            :title="$i18n.t('cp_graph_4_title')"
            :series="averageResponseTimeSeries"
            :isEmpty="averageResponseTimeEmpty"
            type="time"
          />
        </div>
      </div>
    </template>
  </private-template>
</template>

<script>
import { mapGetters } from 'vuex';
import PrivateTemplate from '@/components/organisms/PrivateTemplate.vue';
import HeadingBar from '@/components/molecules/HeadingBar/HeadingBar.vue';
import SelectGroup from '@/components/atoms/Select/SelectGroup.vue';
import { convertDataToGraphSeries, LABEL, getColorByLabel } from '@/helpers/GraphHelper';
import { KPI_VARIANTS } from '@/helpers/ComponentHelper';

import i18n from '@/plugins/vue-i18n';
import ChartBar from '../../components/atoms/Chart/ChartBar.vue';
import ChartRadial from '../../components/atoms/Chart/ChartRadial.vue';
import ChartLineal from '../../components/atoms/Chart/ChartLineal.vue';
import InsightCard from '../../components/atoms/Cards/InsightCard/InsightCard.vue';
import DatepickerGropup from '../../components/atoms/Datepicker/DatepickerGropup.vue';

const DAYS_LESS = (24 * 60 * 60 * 1000) * 30;
const TODAY = new Date();

export default {
  data() {
    return {
      isLoading: true,
      kpis: [],
      isLoadingInsights: false,
      totalSeries: [],
      totalCategories: [],
      totalSeriesIsEmpty: false,
      acceptedSeries: [],
      acceptedColors: [],
      acceptedLabels: [],
      acceptedValues: [],
      totalAcceptedIsEmpty: false,
      averagePriceSeries: [],
      averagePriceEmpty: false,
      averageResponseTimeSeries: [],
      averageResponseTimeEmpty: false,
      specialtyId: null,
      hasSpecialties: false,
      kpi_variants: KPI_VARIANTS,
      toDate: TODAY.toISOString().split('T')[0],
      maxDate: TODAY,
      fromDate: new Date(TODAY.getTime() - DAYS_LESS).toISOString().split('T')[0],
    };
  },
  created() {
    // this.specialtyId = this.getSelectedSpecialty;
    // if (!this.hasSpecialties) {
    // }
    if (this.isVidasprime) { this.loadSpecialties(); }

    this.loadRequestsData();
  },
  watch: {
    userSpecialties(newValue) {
      this.specialtyId = newValue[0].id;
      this.loadInsights();
    },
  },
  methods: {
    loadRequestsData() {
      this.isLoading = true;

      const filtersGroup = [];
      let filter = '';
      filter = this.fromDate ? `fromDate:${this.fromDate}` : '';
      filter += this.toDate ? `,toDate:${this.toDate}` : '';

      filtersGroup.push(filter);

      const data = { filters: filtersGroup };

      Promise.all([this.loadRequests(data), this.loadAccepted(data)])
        .then(([requestsData, acceptedData]) => {
          this.kpis = requestsData.all;
          const areCategoriesMonths = requestsData.columns.categories[0].length === 3;
          this.totalCategories = areCategoriesMonths ? requestsData.columns.categories.map((category) => this.$i18n.t(`cp_${category.toLowerCase()}`)) : requestsData.columns.categories;
          this.totalSeries = requestsData.columns.options.map(
            (option) => ({ ...option, name: this.$i18n.t(option.name) }),
          );
          this.totalSeriesIsEmpty = !requestsData.all[0].value;
          this.totalAcceptedIsEmpty = !requestsData.all[1].value;

          this.acceptedLabels = acceptedData.labels.map((label) => this.$i18n.t(`cp_${label.toLowerCase().replace(' ', '_')}`));
          this.acceptedSeries = acceptedData.percentage;
          this.acceptedColors = acceptedData.labels.map(
            (label) => getColorByLabel(label.toLowerCase()),
          );
          this.acceptedValues = acceptedData.options;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    loadInsights() {
      this.isLoadingInsights = true;
      const filtersGroup = [];
      if (this.specialtyId) {
        filtersGroup.push(`specialty:${this.specialtyId}`);
      }

      const data = { filters: filtersGroup };

      Promise.all([this.loadAveragePrice(data), this.loadAverageResponseTime(data)])
        .then(([priceData, timeData]) => {
          const initialValue = 0;
          this.averagePriceEmpty = priceData.options.reduce(
            (previousValue, currentValue) => previousValue + currentValue, initialValue,
          ) === 0;
          this.averagePriceSeries = convertDataToGraphSeries(priceData).map(
            ((seriesData) => ({ ...seriesData, name: this.$i18n.t(seriesData.name) })),
          );
          this.averageResponseTimeEmpty = timeData.options.reduce(
            (previousValue, currentValue) => previousValue + currentValue, initialValue,
          ) === 0;
          this.averageResponseTimeSeries = convertDataToGraphSeries(timeData).map(
            ((seriesData) => ({ ...seriesData, name: this.$i18n.t(seriesData.name) })),
          );
        })
        .finally(() => {
          this.isLoadingInsights = false;
        });
    },
    loadRequests(data) {
      return this.$store.dispatch('kpi/getRequests', data);
    },
    loadAccepted(data) {
      return this.$store.dispatch('kpi/getAccepted', data);
    },
    loadAveragePrice(data) {
      return this.$store.dispatch('kpi/getAveragePrice', data);
    },
    loadAverageResponseTime(data) {
      return this.$store.dispatch('kpi/getAverageTimeRequest', data);
    },
    handleChangeSelect(value) {
      this.specialtyId = value;
      this.loadInsights();
    },
    loadSpecialties() {
      this.isLoading = true;
      this.$store.dispatch('specialties/getAllSpecialties')
        .then((res) => {
          this.isLoading = false;
          this.specialtyId = res[0]?.id;
          this.loadInsights();
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    handleToDateChange(value) {
      this.toDate = value;
      this.loadRequestsData();
    },
    handleFromDateChange(value) {
      this.fromDate = value;
      this.loadRequestsData();
    },
  },
  computed: {
    ...mapGetters('user', {
      user: 'getCurrentUser',
      isVidasprime: 'isVidasprime',
      isDoctor: 'isDoctor',
      userSpecialties: 'getSpecialties',
    }),
    ...mapGetters('specialties', {
      specialties: 'getSpecialties',
    }),
    getSpecialtyOptions() {
      const specialties = (this.isVidasprime ? this.specialties : this.user.specialties);
      const newSpecialties = specialties?.map((specialty) => {
        const newSpecialty = {
          text: specialty.name,
          value: specialty.id,
        };

        return newSpecialty;
      });

      return newSpecialties;
    },
    getKpisText() {
      return {
        [LABEL.TOTAL]: i18n.t('lb_total_requests'),
        [LABEL.ACCEPTED]: i18n.t('lb_requests_accepted'),
        [LABEL.PENDING]: i18n.t('lb_requests_pending'),
        [LABEL.REJECTED]: i18n.t('lb_requests_rejected'),
      };
    },
    getSelectedSpecialty() {
      if (this.isVidasprime) return '37b5d63c-36ba-40d5-8fc6-4e6858b6e3e4';
      return (this?.userSpecialties?.length !== 0 && this?.userSpecialties !== undefined)
        && this.userSpecialties[0]?.id;
    },
    setYouToLastPosition(array) {
      const arrayCopy = [...array];
      return arrayCopy;
    },
  },
  components: {
    PrivateTemplate,
    HeadingBar,
    SelectGroup,
    ChartBar,
    ChartRadial,
    ChartLineal,
    DatepickerGropup,
    InsightCard,
  },
};
</script>

<style lang="sass" scoped>
@import './HomeView.sass'
</style>
