<template>
  <b-form-input
    class="Input"
    :id="`${id}-input`"
    :type="type"
    :placeholder="placeholder"
    v-model="text"
    @input.native="onChange"
    @keydown.enter.native.prevent="handleLogin"
    :state="isValid"
    :autocomplete="autocomplete"
    :disabled="disabled"
    :min="min"
  />
</template>

<script>
export default {
  props: {
    id: String,
    value: [String, Number],
    type: {
      type: String,
      default: 'text',
    },
    placeholder: String,
    isValid: {
      type: Boolean,
      default: null,
    },
    autocomplete: {
      type: String,
      default: 'autocomplete',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    min: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      text: this.value,
    };
  },
  watch: {
    value(newValue) {
      this.text = newValue;
    },
  },
  methods: {
    getText(text) {
      if (this.type === 'number') {
        return text !== '' ? parseInt(text, 10) : null;
      }

      return text !== '' ? text : null;
    },
    onChange(e) {
      this.text = e.target.value;
      this.$emit('handlerInputChange', this.getText(this.text));
    },
    handleLogin() {
      this.$emit('handleLogin');
    },
  },
};
</script>
