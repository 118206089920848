<template>
  <iframe style="border: 0;width: 100%;height: 100%;position: absolute;left: 0;top: 0;"
          :src="getURL">

  </iframe>
</template>

<script>

export default {
  data: () => ({
    survey: null,
  }),
  created() {
    this.getSurveyFromUrl();
  },
  methods: {
    getSurveyFromUrl() {
      const routeParam = this.$route.params.survey;
      this.survey = !this.isEmpty(routeParam) ? routeParam : null;
    },
    isEmpty(data) {
      return Object.keys(data).length === 0;
    },
  },
  computed: {
    getURL() {
      const base = process.env.VUE_APP_API_URL;
      return `${base}/survey/${this.survey}`;
    },
  },
  components: {
  },
};
</script>
