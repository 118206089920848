<template>
  <div class="TableGroup">
    <div class="TableGroup__table">
      <div class="TableGroup__filtersSimple">
        <TableFilters
          :id="`${id}`"
          :searchText="searchText"
          :filterCount="filterCount"
          @handleSearch="handleSearch"
          @handleSearchByFilter="handleSearchByFilter"
          @onHiddenSidebar="onHiddenSidebar"
          @handleCleanSearch="handleCleanSearch"
          @handleImport="handleImport"
          @handleExport="handleExport"
          :isHidden="isHidden"
          :showImportButton="showImportButton"
          :showExportButton="showExportButton"
          :showFilterButton="showFilterButton"
        >
          <slot name="content"></slot>
        </TableFilters>
      </div>
      <Spinner
        :isLoading="isLoading"
        variant="primary"
      />
      <slot v-if="hasItems && !isLoading"></slot>
      <p
        v-if="!hasItems"
        class="TableGroup__empty text__pr"
      >
        {{ $i18n.t('cp_not_result') }}
      </p>
    </div>
    <Pagination
      v-if="hasItems && showPagination"
      @handleRequest="paginate"
      :pagination="pagination"
    />
  </div>
</template>

<script>
import TableFilters from '@/components/molecules/Tables/TableFilters/TableFilters.vue';
import Pagination from '@/components/molecules/Pagination/Pagination.vue';
import Spinner from '@/components/atoms/Spinner/Spinner.vue';

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    showPagination: {
      type: Boolean,
      default: true,
    },
    pagination: {
      type: Object,
      default: () => {},
    },
    searchText: {
      type: String,
      default: '',
    },
    filterCount: {
      type: Number,
      default: 0,
    },
    isHidden: {
      type: Boolean,
      default: false,
    },
    showImportButton: {
      type: Boolean,
      default: false,
    },
    showFilterButton: {
      type: Boolean,
      default: true,
    },
    showExportButton: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleSearch(text) {
      this.$emit('handleSearch', text);
    },

    handleSearchByFilter() {
      this.$emit('handleSearchByFilter');
    },

    paginate(currentPage) {
      this.$emit('handlePaginate', currentPage);
    },

    onHiddenSidebar() {
      this.$emit('onHiddenSidebar');
    },

    handleCleanSearch() {
      this.$emit('handleCleanSearch');
    },

    handleImport() {
      this.$emit('handleImport');
    },

    handleExport() {
      this.$emit('handleExport');
    },
  },
  computed: {
    hasItems() {
      return this.items && this.items.length > 0;
    },
  },
  components: {
    TableFilters,
    Pagination,
    Spinner,
  },
};
</script>

<style lang="sass" scoped>
@import './TableGroup.sass'
</style>
