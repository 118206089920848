import getAxiosWithAuthHeader from '../../helpers/AuthHelper';
import {
  getParameterUrl,
  getDataWithUuid,
} from '../../helpers/ApiHelper';
import InvitationFactory from './InvitationFactory';

const INVITATIONS = '/api/invitations';
const SEARCH_DOCTORS = '/api/collaborators/search';

class InvitationsAPI {
  constructor() {
    this.client = getAxiosWithAuthHeader();
  }

  findClinics(data) {
    const parameterUrl = getParameterUrl(
      SEARCH_DOCTORS,
      data.page,
      data.filters,
      data.ordenation,
      data.perPage,
    );

    return this.client
      .get(parameterUrl)
      .then((res) => InvitationFactory.findClinics(res.data))
      .catch((error) => Promise.reject(error));
  }

  createInvitation(data) {
    const idData = getDataWithUuid(data);

    return this.client
      .post(INVITATIONS, idData)
      .then(() => idData)
      .catch((error) => Promise.reject(error));
  }

  cancelInvitation(uuid) {
    return this.client
      .put(`${INVITATIONS}/${uuid}/cancel`)
      .then(() => uuid)
      .catch((error) => Promise.reject(error));
  }

  acceptInvitation(uuid) {
    return this.client
      .put(`${INVITATIONS}/${uuid}/accept`)
      .then(() => uuid)
      .catch((error) => Promise.reject(error));
  }

  rejectInvitation(uuid) {
    return this.client
      .put(`${INVITATIONS}/${uuid}/decline`)
      .then(() => uuid)
      .catch((error) => Promise.reject(error));
  }

  getInvitations(data) {
    const parameterUrl = getParameterUrl(
      `${INVITATIONS}/search`,
      data.page,
      data.filters,
      data.ordenation,
      data.perPage,
    );

    return this.client.get(parameterUrl)
      .then((res) => InvitationFactory.getInvitations(res.data))
      .catch((error) => Promise.reject(error));
  }
}

export default InvitationsAPI;
