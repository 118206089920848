<template>
  <div id="chart" class="charts container">
    <Spinner
      :isLoading="isLoading"
    />
    <div v-if="isEmpty" class="noData">
      <div class="noData-text">
        <span >{{ $i18n.t('val_empty_data') }}</span>
      </div>
    </div>
    <apexchart
      v-if="!isLoading"
      type="radialBar"
      height='500'
      :options="options"
      :series= 'isEmpty ? [] : series'
    ></apexchart>
  </div>
</template>

<script>
import Spinner from '@/components/atoms/Spinner/Spinner.vue';

export default {
  name: 'ChartRadial',
  props: {
    series: {
      type: Array,
    },
    labels: {
      type: Array,
    },
    colors: {
      type: Array,
    },
    values: {
      type: Array,
    },
    title: {
      type: String,
    },
    isLoading: Boolean,
    isEmpty: Boolean,
  },
  data() {
    return {
      options: {
        colors: this.colors,
        labels: this.labels,
        chart: {
          height: 300,
          toolbar: {
            show: false,
            tools: {
              selection: true,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
            },
          },
        },
        plotOptions: {
          radialBar: {
            offsetY: 0,
            startAngle: 0,
            endAngle: 270,
            hollow: {
              margin: 5,
              size: '50%',
              background: 'transparent',
              image: undefined,
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                show: false,
              },
            },
          },
        },
        title: {
          text: this.title,
          align: 'left',
          offsetX: 10,
          offsetY: 10,
          floating: true,
          style: {
            fontSize: '14px',
            fontWeight: '600',
          },
        },
        legend: {
          show: true,
          floating: true,
          fontSize: '12px',
          fontWeight: 400,
          position: 'left',
          offsetX: 60,
          offsetY: 23,
          labels: {
            useSeriesColors: false,
          },
          markers: {
            size: 0,
          },
          formatter: (seriesName, opts) => `${this.labels[opts.seriesIndex]}  ${this.values[opts.seriesIndex]}`,
          itemMargin: {
            vertical: 0,
          },
        },
        noData: {
          text: '',
        },
      },
    };
  },
  components: {
    Spinner,
  },
};
</script>

<style lang="sass" scoped>
  @import './charts.sass'
</style>
