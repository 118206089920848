<template>
  <div>
    <button
      id="user-menu"
      ref="button"
      class="UserMenu"
    >
      <p>{{ getUserName }}</p>
      <b-avatar
        variant="empty"
        class="UserMenu__avatar"
        :src="getAvatarIcon"
      />
      <img
        src="@/assets/common/arrow-bottom.svg"
        :class="
          popoverShow ?
          'UserMenu__arrow--showed' :
          'UserMenu__arrow'"
      />
    </button>
    <b-popover
      target="user-menu"
      triggers="focus"
      placement="bottomleft"
      :show.sync="popoverShow"
      container="my-container"
      ref="popover"
    >
      <UserCard
        :user="user"
        @handleOpenEditUser="handleOpenEditUser"
      />
    </b-popover>
    <UpdateUser
      :user="user"
      :isVidasprime="isVidasprime"
      :isHealthManager="isHealthManager"
      :isClinic="isClinic"
      :isDoctor="isDoctor"
      @closeUpdateModal="closeUpdateModal"
      v-if="showUpdateUser"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import UserCard from '@/components/molecules/User/UserCard/UserCard.vue';
import UpdateUser from '@/components/organisms/User/UpdateUser.vue';
import { getAvatarByProps } from '@/helpers/UserHelper';

export default {
  data: () => ({
    popoverShow: false,
    showUpdateUser: false,
  }),
  created() {
    this.$store.dispatch('user/getUser');
  },
  methods: {
    handleOpenEditUser() {
      this.showUpdateUser = true;
    },

    closeUpdateModal() {
      this.showUpdateUser = false;
    },
  },
  computed: {
    ...mapGetters('user', {
      user: 'getCurrentUser',
      isVidasprime: 'isVidasprime',
      isClinic: 'isClinicRole',
      isHealthManager: 'isHealthManager',
      isDoctor: 'isDoctorRole',
    }),

    getAvatarIcon() {
      if (!this.user.avatar) return null;
      return getAvatarByProps(this.user.avatar);
    },

    getUserName() {
      if (!this.user.name) return null;
      return this.user.name;
    },
  },
  components: {
    UserCard,
    UpdateUser,
  },
};
</script>

<style lang="sass" scoped>
@import './UserMenu.sass'
</style>
