import Vue from 'vue';
import CountryFlag from 'vue-country-flag';
import VCalendar from 'v-calendar';
import Select2 from 'v-select2-component';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from '@/plugins/vue-i18n';

import '@/plugins/axios';
import '@/plugins/bootstrap-vue';
import '@/plugins/vue-inline-svg';
import '@/style/custom.sass';
import '@/helpers/Filters';
import '@/plugins/apexcharts';

Vue.component('country-flag', CountryFlag);
Vue.component('Select2', Select2);

Vue.use(VCalendar, {
  componentPrefix: 'vc',
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
