/* eslint-disable class-methods-use-this */
import RequestModel from './RequestModel';
import PaginationFactory from '../pagination/PaginationFactory';

class RequestFactory {
  constructor() {
    this.request = {};
  }

  getAllRequest(data) {
    const requestResponse = {
      requestList: this.getCollection(data?.collection),
      pagination: PaginationFactory.getPagination(data?.pagination),
    };

    return requestResponse;
  }

  getDocuments(documents) {
    return documents.map((document) => {
      const newDocument = {
        id: document.document.id,
        fileName: document.document.fileName,
        createdAt: document.document.createdAt,
        path: document.document.path,
        requestDocumentId: document.id,
        fullPath: document.document.fullPath,
      };

      return newDocument;
    });
  }

  getRequest(request) {
    this.request = new RequestModel({
      id: request.id,
      caseNumber: request.caseNumber,
      createdAt: request.createdAt,
      patient: request.patient,
      specialty: request.specialty,
      level: request.level,
      pathology: request.pathology,
      medicalProcedure: request.medicalProcedure,
      hasInsurance: request.hasInsurance,
      hasRecentReports: request.hasRecentReports,
      healthManager: request.healthManager,
      clinic: request.clinic,
      requestStage: request.requestStage,
      notes: request.notes,
      appointments: request.appointments,
      documents: this.getDocuments(request.documents),
      status: request.status ? request.status.toLowerCase() : null,
      doctors: request.doctors || [],
      doctor: request.doctor
        ? this.getDoctorDetail(request.doctor.name, request.doctor.lastname)
        : null,
      collaborators: request.collaborators || [],
      invitations: request.invitations || [],
    });

    return this.request;
  }

  getCollection(requestCollection) {
    const requestList = requestCollection.map(
      (request) => this.getRequest(request),
    );

    return requestList;
  }

  getDoctorDetail(name, lastname) {
    return `${name} ${lastname}`;
  }
}

export default new RequestFactory();
