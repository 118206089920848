import SurveyApi from '../../domain/survey/SurveyApi';

export function getAllSurvey({ commit }, data) {
  const surveyApi = new SurveyApi();
  const getAllRequest = surveyApi.getAllSurvey(data);

  return getAllRequest
    .then((res) => res)
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function filterSurvey({ commit }, data) {
  const surveyApi = new SurveyApi();
  const searchRequest = surveyApi.filterSurvey(data);

  return searchRequest
    .then((res) => res)
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function searchSurvey({ commit }, data) {
  const surveyApi = new SurveyApi();
  const searchRequest = surveyApi.searchSurvey(data);

  return searchRequest
    .then((res) => res)
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function createSurvey({ commit }, data) {
  const surveyApi = new SurveyApi();
  const createRequest = surveyApi.createSurvey(data);

  return createRequest
    .then()
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function updateSurvey({ commit }, data) {
  const surveyApi = new SurveyApi();
  const updateRequest = surveyApi.updateSurvey(data);

  return updateRequest
    .then()
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function deleteSurvey({ commit }, uuid) {
  const surveyApi = new SurveyApi();
  const deleteRequest = surveyApi.deleteSurvey(uuid);

  return deleteRequest
    .then()
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}
