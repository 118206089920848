<template>
  <b-sidebar
    class="Sidebar"
    :id="`${id}-sidebar`"
    width="50vw"
    shadow
    backdrop
    right
    no-header
    lazy
    @hidden="onHidden"
  >
    <div class="Sidebar__content">
      <slot></slot>
      <div
        v-if="showFooter"
        class="Sidebar__footer"
      >
        <FormButton
            @handleClick="handleAccept"
            :isLoading="isLoading"
            variant="primary"
          >
            {{ buttonLabel }}
          </FormButton>
      </div>
    </div>
  </b-sidebar>
</template>

<script>
import FormButton from '@/components/atoms/Buttons/FormButton/FormButton.vue';

export default {
  methods: {
    handleAccept() {
      this.$emit('handleAccept');
    },
    onHidden() {
      this.$emit('onHiddenSidebar');
    },
  },
  props: {
    id: String,
    showFooter: {
      type: Boolean,
      default: false,
    },
    filterButton: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    buttonLabel() {
      return this.filterButton ? this.$i18n.t('btn_apply') : this.$i18n.t('btn_save');
    },
  },
  components: {
    FormButton,
  },
};
</script>

<style lang="sass" scoped>
@import './SideBar.sass'
</style>
