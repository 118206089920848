<template>
  <div>
    <div v-if="!isClinic">
      <div
        class="SideForm__cards"
      >
        <LabelMiniCard
          :label="$i18n.t('lb_date')"
        >
          <p class="text__psb">
            {{ getRequest.createdAt | formatDate }}
          </p>
        </LabelMiniCard>

        <LabelMiniCard
          :label="$i18n.t('lb_status')"
        >
          <Badge :variant="getRequest.status || ''">
            {{ $i18n.t(`cp_status_${getRequest.status}`) }}
          </Badge>
        </LabelMiniCard>

        <LabelMiniCard
          :label="$i18n.t('lb_stage')"
        >
          <p class="text__psb">
            {{ getRequest.requestStage ? getRequest.requestStage.name : '' }}
          </p>
        </LabelMiniCard>
      </div>
    </div>

    <div class="sp__v__1d5x"/>

    <div class="SideForm__cards">
      <LabelMiniCard
        :label="$i18n.t('lb_emergency_degree')"
      >
        <p class="text__psb" v-if="getRequest.level">
            {{ $i18n.t(`cp_degree_${getRequest.level}`) }}
        </p>
      </LabelMiniCard>

      <LabelMiniCard
        :label="$i18n.t('lb_private_insurance')"
      >
        <p class="text__psb">
          {{
            getRequest.hasInsurance !== null
              ? $i18n.t(`cp_boolean_${getRequest.hasInsurance}`)
              : ''
          }}
        </p>
      </LabelMiniCard>

      <LabelMiniCard
        :label="$i18n.t('lb_recent_reports')"
      >
        <p class="text__psb">
          {{
            getRequest.hasRecentReports !== null
              ? $i18n.t(`cp_boolean_${getRequest.hasRecentReports}`)
              : ''
          }}
        </p>
      </LabelMiniCard>
    </div>
  </div>
</template>

<script>
import LabelMiniCard from '@/components/atoms/Cards/LabelMiniCard/LabelMiniCard.vue';
import Badge from '@/components/atoms/Badge/Badge.vue';
import defaultRequest from '@/views/Request/defaultRequest';

export default {
  props: {
    showClose: {
      type: Boolean,
      default: false,
    },
    request: {
      type: Object,
      default: () => defaultRequest,
    },
  },
  computed: {
    isClinic() {
      return this.$store.getters['user/isClinic'];
    },

    getRequest() {
      return this.request;
    },
  },
  components: {
    LabelMiniCard,
    Badge,
  },
};
</script>
