<template>
  <div class="input-group-autocomplete">
    <b-form>
      <b-form-group
        class="text__psb"
        :id="`${id}-group`"
        :label="label"
        :label-for="`${id}-input`"
      >
        <Input
          :id="id"
          :value="value"
          :type="type"
          :placeholder="placeholder"
          @handlerInputChange="handleInputGroupChange"
          :isValid="isValid"
          :autocomplete="autocomplete"
        />
        <div class="Autocomplete">
          <Spinner :isLoading="isLoading" />
          <Autocomplete
            :isLoading="!isLoading"
            :items="suggestions"
            @handlerSelectSuggestion="handleSelectSuggestion"
          />
        </div>
        <b-form-invalid-feedback
          v-if="!isValid"
          class="text__sr"
        >
          {{ error }}
        </b-form-invalid-feedback>
        <b-form-text
          v-if="showHelp"
          class="text__sr"
        >
          {{ help }}
        </b-form-text>
      </b-form-group>
    </b-form>
  </div>
</template>

<script>
import Input from '@/components/atoms/Input/Input.vue';
import Autocomplete from '@/components/atoms/Autocomplete/Autocomplete.vue';
import Spinner from '@/components/atoms/Spinner/Spinner.vue';

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: null,
    },
    isValid: {
      type: Boolean,
      default: null,
    },
    help: {
      type: String,
      default: '',
    },
    suggestions: {
      type: Array,
      required: true,
    },
    value: {
      type: String,
      twoWay: true,
    },
    showList: {
      type: Boolean,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      autocomplete: 'off',
    };
  },
  methods: {
    handleInputGroupChange(text) {
      this.$emit('handlerInputChange', text);
    },
    handleSelectSuggestion(value) {
      this.$emit('handlerSelectSuggestion', value);
    },
  },
  computed: {
    showHelp() {
      return this.help.length > 0;
    },
    suggestionList() {
      return this.suggestions;
    },
  },
  components: {
    Input,
    Autocomplete,
    Spinner,
  },
};
</script>
<style lang="sass">
@import './InputGroupAutocomplete.sass'
</style>
