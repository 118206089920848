import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export function getRequestState(state) {
  return state.request;
}

export function getRequestPaginationState(state) {
  return state.request.pagination;
}

export function getRequestListState(state) {
  return state.request.requestList;
}

export function getRequestDetailsState(state, id) {
  return state.request.requestList.find((item) => item.id === id);
}
