export default class ClinicModel {
  constructor(clinicData) {
    this.id = clinicData.id;
    this.city = clinicData.city;
    this.contactName = clinicData.contactName;
    this.credentials = clinicData.credentials;
    this.description = clinicData.description;
    this.allowCollaborations = clinicData.allowCollaborations;
    this.googleMyBusinessAccount = clinicData.googleMyBusinessAccount;
    this.isEnabled = clinicData.isEnabled;
    this.name = clinicData.name;
    this.patients = clinicData.patients;
    this.firstPrice = clinicData.firstPrice;
    this.phone = clinicData.phone;
    this.requests = clinicData.requests;
    this.totalRequests = clinicData.totalRequests;
    this.vidasPrimeRequests = clinicData.vidasPrimeRequests;
    this.email = clinicData.email;
    this.role = clinicData.role;
    this.avatar = clinicData.avatar;
    this.yearFoundation = clinicData.yearFoundation;
    this.insurances = clinicData.insurances;
    this.hasInsurances = clinicData.hasInsurances;
    this.specialties = clinicData.specialties;
    this.defaultLanguage = clinicData.defaultLanguage;
  }
}
