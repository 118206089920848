import {
  getAllSurveyTypePartialsType,
  filterSurveyTypePartialsType,
  searchSurveyTypePartialsType,
  createSurveyTypePartialsType,
  updateSurveyTypePartialsType,
  deleteSurveyTypePartialsType,
} from './surveyTypePartialsTypeActions';

const surveyTypePartialsType = {
  namespaced: true,
  state: () => ({}),
  mutations: {},
  actions: {
    getAllSurveyTypePartialsType,
    filterSurveyTypePartialsType,
    searchSurveyTypePartialsType,
    createSurveyTypePartialsType,
    updateSurveyTypePartialsType,
    deleteSurveyTypePartialsType,
  },
  getters: {
  },
};

export default surveyTypePartialsType;
