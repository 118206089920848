<template>
  <div>
    <div class="ResetPasswordMobileView" v-if="isMobile">
      <ResetPasswordForm
          :resetToken="resetToken"
        />
    </div>
    <div class="ResetPasswordView" v-if="!isMobile">
      <div class="ResetPasswordView__resetPasswordFormContainer">
        <ResetPasswordForm
          :resetToken="resetToken"
        />
      </div>
      <div class="ResetPasswordView__imageContainer" />
    </div>
  </div>
</template>

<script>
import ResetPasswordForm from '@/components/organisms/ResetPassword/ResetPasswordForm.vue';
import DEVICE from '../../helpers/ResponsiveHelper';

export default {
  name: 'ResetPasswordView',
  components: {
    ResetPasswordForm,
  },
  data: () => ({
    resetToken: null,
  }),
  created() {
    this.getResetToken();
  },
  mounted() {
    /* TODO: VP-14 REPLICAR EN las páginas de login */
    const resizeObserver = new ResizeObserver((entries) => {
      const [element] = entries;
      const isMobile = element.contentRect.width < DEVICE.MOBILE_WIDTH;
      this.$store.commit('responsive/setIsMobile', isMobile);
    });

    resizeObserver.observe(this.$el);
  },
  methods: {
    getResetToken() {
      const routeParam = this.$route.params.token;
      this.resetToken = !this.isEmpty(routeParam) ? routeParam : null;
    },
    isEmpty(data) {
      return Object.keys(data).length === 0;
    },
  },
  computed: {
    /* TODO: VP-14 EXAMPLE HOW TO USE IT. REMOVE IT */
    isMobile() {
      return this.$store.getters['responsive/isMobile'];
    },
  },
};
</script>

<style lang="sass" scoped>
@import './ResetPasswordView.sass'
</style>
