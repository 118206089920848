<template>
  <ClinicForm
    id="update-clinic"
    ref="updateClinicForm"
    :title="$i18n.t('lb_update_clinic')"
    :clinic="getClinic"
    :isLoading="isLoading"
    @handleOk="update"
  />
</template>

<script>
import ClinicForm from '@/components/organisms/Clinic/ClinicForm.vue';
import { getAvatarBase64 } from '@/helpers/UserHelper';

export default {
  props: {
    clinic: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    update(data) {
      this.isLoading = true;

      const requestData = {
        id: data.id,
        body: {
          ...(this.getAvatar(data.avatar)),
          name: data.name,
          contactName: data.contactName,
          city: data.city,
          yearFoundation: data.yearFoundation,
          description: data.description,
          credentials: data.credentials,
          hasInsurances: data.hasInsurances,
          insurances: data.insurances,
          allowCollaborations: data.allowCollaborations,
          googleMyBusinessAccount: data.googleMyBusinessAccount,
          email: data.email,
          firstPrice: data.firstPrice,
          phone: data.phone,
          specialties: data.specialties,
          defaultLanguage: data.defaultLanguage ?? '1',
          isPremium: data.isPremium,
        },
      };

      this.$store.dispatch('clinic/updateClinic', requestData)
        .then(() => {
          this.$emit('updateClinic', data);
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },

    getAvatar(avatar) {
      return getAvatarBase64(avatar);
    },
  },
  computed: {
    getClinic() {
      return this.clinic;
    },
  },
  components: {
    ClinicForm,
  },
};
</script>
