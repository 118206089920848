export default {
  lb_add: '+ Add',
  // User
  lb_configuration: 'Configuration',
  // Patient
  lb_patient: 'Patient',
  lb_patients: 'Patients',
  lb_survey: 'Survey',
  lb_surveys: 'Surveys',
  lb_surveys_title: 'Find out what your patients think and maximize patient satisfaction',
  lb_surveys_satisfaction: 'Satisfaction',
  lb_reputation: 'Reputation',
  lb_total_patients: 'TOTAL PATIENTS',
  lb_new_patient: 'New patient',
  lb_update_patient: 'Update Patient',
  lb_select_patient: 'Select patient',
  lb_import_patient: 'Import Patient',

  // Home
  lb_home: 'Insights',

  // Survey
  lb_templates: 'Templates',
  lb_answer: 'Answers',
  lb_survey_sent_count: 'Sent',
  lb_survey_answered: 'Answers',
  lb_survey_response_rate: 'Response rate',

  // Survey Type
  lb_survey_type: 'Survey Type',
  lb_select_survey_type: 'Select Survey',
  lb_survey_types: 'Survey Types',
  lb_result_survey: 'Survey outcome',
  lb_new_survey_type: 'New survey type',
  lb_survey_send_at: 'Send At',
  lb_survey_answered_at: 'Answered At',
  lb_new_survey: 'New survey',
  lb_select_survey: 'Select survey',
  lb_survey_type_name: 'Survey Type name',
  lb_update_survey_type: 'Update survey type',

  // Partial type
  lb_patial_type: 'Item Type',

  // Survey Type Partials Type
  lb_survey_types_partials_types: 'Survey items',
  lb_new_survey_type_partial_type: 'New item',
  lb_add_survey_type_partial_type: 'Add item',
  lb_survey_type_partials_type_name: 'Item name',
  lb_survey_type_partials_type_title: 'Questions',
  lb_survey_type_partials_type_description: 'Description',
  lb_survey_type_partials_type_help: 'Help text',
  lb_survey_type_partials_type_is_required: 'Is required?',
  lb_survey_type_partials_type_placeholder: 'Placeholder',

  // Doctors
  lb_doctors: 'Doctors',
  lb_doctors_title: 'Equipo',
  lb_new_doctor: 'New Doctor',
  lb_update_doctor: 'Update Doctor',
  lb_add_doctor: 'Add Doctor',
  // Health Manager
  lb_health_manager: 'Health Manager',
  lb_new_health_manager: 'New Health Manager',
  lb_update_health_manager: 'Update Health Manager',
  lb_add_health_manager: 'Assign Health Manager',
  // Calendar
  lb_calendar: 'Calendar',
  // Reviews
  lb_reviews: 'Reviews',
  // Reports
  lb_reports: 'Reports',
  // Clinics
  lb_clinic: 'Clinic',
  lb_clinics: 'Clinics',
  lb_new_clinic: 'New Clinic',
  lb_update_clinic: 'Update Clinic',
  lb_clinic_logo: 'Clinic logo',
  lb_clinic_name: 'Clinic name',
  lb_add_clinic: 'Add Clinic',
  // Applications
  lb_applications: 'Appliccations',
  // Treatments
  lb_treatments: 'Treatments',
  // Skyadoctor
  lb_skyadoctor: 'SkyaDoctor',
  // Requests
  lb_total_requests: 'Total',
  lb_requests_pending: 'Pending',
  lb_requests_accepted: 'Accepted',
  lb_requests_rejected: 'Rejected',
  lb_new_request: 'NEW REQUEST',
  lb_update_request: 'Update request',
  lb_copy_request: 'Copy request',
  lb_request_count: 'Applications ({count})',
  lb_request_tab_table: 'My requests',
  lb_request_tab_table_count: 'My requests ({count})',
  // Collaborations
  lb_collaboration_tab_sent: 'Invitations sent',
  lb_collaboration_tab_sent_count: 'Invitations sent ({count})',
  lb_collaboration_tab_received: 'Invitations received',
  lb_collaboration_tab_received_count: 'Invitations received ({count})',
  lb_collaboration_main: 'Main Clinic',
  lb_collaboration_clinics: 'Partner Clinics',
  lb_collaboration_invitations: 'Pendant Invitations',
  lb_collaboration_modal_title: 'New invitation to collaborate',
  lb_collaboration_modal_comment_title: 'Comments',
  lb_collaboration_modal_comment_placeholder: 'Enter your comments (Optional)',
  lb_collaboration_modal_name_title: 'Name',
  lb_collaboration_modal_name_placeholder: 'Enter your name',
  lb_collaboration_modal_email_title: 'Email',
  lb_collaboration_modal_email_placeholder: 'Enter your email',
  lb_collaboration_result_email_title: 'Invitation sent successfully',
  lb_collaboration_result_email_subtitle: 'You have sent your invitation to the following clinic: ',
  lb_collaboration_result_client_subtitle: 'You have sent your invitation to the following clinic, you will be able to see its status in pending invitations.',
  lb_collaboration_cancel_title: 'You are going to cancel an invitation sent',
  lb_collaboration_cancel_subtitle: 'You are going to cancel the invitation for application',
  lb_collaboration_accept_title: 'You are going to accept an invitation sent',
  lb_collaboration_accept_subtitle: 'You are going to accept the invitation for application',
  lb_collaboration_reject_title: 'You are going to reject an invitation sent',
  lb_collaboration_reject_subtitle: 'You are going to reject the invitation for application',

  // Documents
  lb_documents: 'Documents',
  lb_add_document: 'Add documents',
  lb_documents_count: 'Documents ({count})',
  // Notes
  // lb_note: 'Note',
  lb_note: 'Qualification',
  // lb_notes: 'Notes',
  lb_notes: 'Qualifications',
  lb_add_note: 'Add qualification',
  // lb_notes_count: 'Notes ({count})',
  lb_notes_count: 'Qualifications ({count})',
  // Notifications
  lb_notifications: 'Notifications',
  lb_notifications_empty: 'You have no new notifications',
  // Contact
  lb_contact_info: 'Contact information',
  lb_contact_not_accepted: 'Accept request to view contact information.',
  lb_call: 'Call',
  // Dates
  lb_dates: 'Dates',
  lb_dates_count: 'Dates ({count})',
  // Password
  lb_change_password: 'Change Password',
  lb_actual_password: 'Actual Password',
  lb_new_password: 'New Password',
  // Filters
  lb_select_origin: 'Select origin',
  lb_select_specialty: 'Select specilaity',
  lb_select_clinic: 'Select clinic',
  lb_select_insurance: 'Select insurance',
  lb_select_stage: 'Select stage',
  lb_select_status: 'Select status',
  lb_select_date: 'Select date',
  lb_select_market: 'Select market',
  lb_select_partial_type: 'Select partial type',
  // Type
  lb_type: 'Type',
  lb_type_something: 'Enter text',
  lb_type_data: 'Enter data',
  lb_type_year: 'Enter year',
  lb_type_email: 'Enter email',
  lb_type_phone: 'Enter phone number',
  lb_type_city: 'Enter city',
  // Intervals
  lb_interval_all: 'All the time',
  lb_interval_week: 'Last week',
  lb_interval_month: 'Last month',
  lb_interval_year: 'Last year',
  // Common
  lb_cancel: 'Cancel',
  lb_sent: 'Sent',
  lb_received: 'Received',
  lb_invited: 'Invited',
  lb_invited_by: 'Invited by',
  lb_created: 'Created',
  lb_created_by: 'Created By',
  lb_name: 'Name',
  lb_lastname: 'Lastname',
  lb_origin: 'Origin',
  lb_date: 'Date',
  lb_dob: 'Date of birth',
  lb_idCrm: 'ID CRM',
  lb_email: 'Email',
  lb_phone: 'Phone number',
  lb_request: 'Requests',
  lb_insight_description: 'Filter by date and know the status of your applications in real time.',
  lb_external: 'Vidasprime Patient',
  lb_gender: 'Gender',
  lb_tutor: 'Tutor',
  lb_insurance: 'Insurence',
  lb_hospital: 'Hospital',
  lb_market: 'Market',
  lb_info: 'Information',
  lb_contact: 'Contact',
  lb_contact_person: 'Contact name',
  lb_city: 'City',
  lb_foundation_year: 'Foundation year',
  lb_description: 'Description',
  lb_acreditations: 'Acreditations',
  lb_google_mybusiness_account: 'Google My Business account',
  lb_first_price: 'Precio primera consulta',
  lb_default_language: 'Default language',
  lb_language: 'Language',
  lb_contact_data: 'Contact information',
  lb_vp_request: 'Vidasprime requests',
  lb_total_request: 'Total requests',
  lb_total: 'Total',
  lb_instance: 'Case',
  lb_instance_number: 'Case Nº',
  lb_specialty: 'Specialty',
  lb_pathology: 'Pathology',
  lb_doctor: 'Doctor',
  lb_stage: 'Stage',
  lb_status: 'Status',
  lb_emergency_degree: 'LEVEL OF INTEREST',
  lb_private_insurance: 'PRIVATE MEDICAL INSURANCE',
  lb_recent_reports: 'RECENT REPORTS',
  lb_summary: 'Summary',
  lb_subscription: 'Subscription',
  lb_is_subscribed: 'Is subscribed',
  lb_collaboration: 'Collaboration',
  lb_collaborations: 'Collaborations',
  lb_collaborator: 'Collaborator',
  lb_medical_procedure: 'Medical procedure',
  lb_confirm_title: 'Are you sure?',
  lb_confirm_text: 'This operation cannot be reversed.',
  lb_bio: 'Bio',
  lb_professional_experience: 'Professional Experience',
  lb_others: 'Others',
  lb_logo: 'Logo',
  lb_new_activity: 'New activity',
  lb_can_not_undo: 'If you confirm the user will be deleted.',
  lb_creation_date: 'Creation date',
  lb_private_insurance_min: 'Private insurance',
  lb_can_not_undo_health_manager: 'If you confirm the Health Manager will be deleted.',
  lb_can_not_undo_clinic: 'If you confirm the Clinic will be deleted.',
  lb_procedure: 'Procedure',
  lb_refer_to: 'Referred to',
  lb_refer_to_f: 'Referred to',
  lb_dni_passport: 'ID or Passport',
  lb_dni_passport_s: 'ID / Passport',
  lb_clients: 'Clinic',
  lb_online_presence: 'Online presence',
  lb_videoconsultation: 'Videoconsultation',
  lb_patient_experience: 'Patient experience',
  lb_to: 'a',
  lb_from: 'de',
  // forgot password
  lb_forgot_password: 'Forgot your password?',
  lb_password: 'Password',
  lb_confirm_password: 'Confirm your password',
};
