import setIsMobile from './responsiveMutations';
import isMobile from './responsiveGetters';

const responsive = {
  namespaced: true,
  state: () => ({
    isMobile: false,
  }),
  mutations: {
    setIsMobile,
  },
  getters: {
    isMobile,
  },
};

export default responsive;
