<template>
  <div class="TableGroup__table">
    <div class="TableGroup__filters">
      <TableFilters
        id="TableClinicFilters"
        @handleSearch="handleSearch"
      />
    </div>
    <TableClinic />
  </div>
</template>

<script>
import TableClinic from '@/components/molecules/Tables/TableClinic.vue';
import TableFilters from '@/components/molecules/Tables/TableFilters/TableFilters.vue';

export default {
  methods: {
    handleSearch() {},
  },
  components: {
    TableClinic,
    TableFilters,
  },
};
</script>

<style
  lang="sass" scoped>
  @import './TableGroup.sass'
</style>
