import gbComponents from './gb-components';
import gblabels from './gb-labels';
import gbButtons from './gb-buttons';
import gbValidations from './gb-validations';
import gbTexts from './gb-texts';
import gbErrors from './gb-errors';

const messages = {
  ...gbComponents,
  ...gblabels,
  ...gbButtons,
  ...gbValidations,
  ...gbTexts,
  ...gbErrors,
};

export default messages;
