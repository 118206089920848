import moment from 'moment';
import i18n from '@/plugins/vue-i18n';
import { getFileNameFromContentDisposition } from '@/helpers/ApiHelper';

export const STATUS = {
  PENDING: {
    key: 'pending',
    value: 'cp_status_pending',
  },
  ACCEPTED: {
    key: 'accepted',
    value: 'cp_status_accepted',
  },
  REJECTED: {
    key: 'rejected',
    value: 'cp_status_rejected',
  },
  DECLINED: {
    key: 'declined',
    value: 'cp_status_rejected',
  },
  CANCELED: {
    key: 'canceled',
    value: 'cp_status_canceled',
  },
  DANGER: {
    key: 'danger',
    value: 'cp_status_canceled',
  },
  FAILED: {
    key: 'failed',
    value: 'cp_status_failed',
  },
  COLLABORATION: {
    key: 'collaboration',
    value: 'cp_status_collaboration',
  },
};

export const STATUS_OPTIONS = [
  {
    value: STATUS.PENDING.key,
    text: STATUS.PENDING.value,
  },
  {
    value: STATUS.ACCEPTED.key,
    text: STATUS.ACCEPTED.value,
  },
  {
    value: STATUS.REJECTED.key,
    text: STATUS.REJECTED.value,
  },
  {
    value: STATUS.DECLINED.key,
    text: STATUS.DECLINED.value,
  },
  {
    value: STATUS.CANCELED.key,
    text: STATUS.CANCELED.value,
  },
  {
    value: STATUS.FAILED.key,
    text: STATUS.FAILED.value,
  },
  {
    value: STATUS.COLLABORATION.key,
    text: STATUS.COLLABORATION.value,
  },
];

export function getStatusOptions() {
  return STATUS_OPTIONS;
}

export function getVariantByStatus(status) {
  switch (status) {
    case STATUS.PENDING.key:
      return STATUS.PENDING.value;

    case STATUS.ACCEPTED.key:
      return STATUS.ACCEPTED.value;

    case STATUS.REJECTED.key:
      return STATUS.REJECTED.value;

    case STATUS.DECLINED.key:
      return STATUS.DECLINED.value;

    case STATUS.CANCELED.key:
      return STATUS.CANCELED.value;

    case STATUS.FAILED.key:
      return STATUS.FAILED.value;

    case STATUS.COLLABORATION.key:
      return STATUS.COLLABORATION.value;

    default:
      return '';
  }
}

export function getImageFormatted(image) {
  if (image && typeof image === 'object') {
    return URL.createObjectURL(image);
  }

  return image || null;
}

export function getCurrentDate() {
  return moment().format('YYYY-MM-DD');
}

export function getFormattedDate(date) {
  return moment(String(date)).format('DD/MM/YYYY');
}

export const INTERVAL = {
  ALL: {
    key: 'all',
    value: 'lb_interval_all',
  },
  WEEK: {
    key: 'week',
    value: 'lb_interval_week',
  },
  MONTH: {
    key: 'month',
    value: 'lb_interval_month',
  },
  YEAR: {
    key: 'year',
    value: 'lb_interval_year',
  },
};

export const INTERVAL_OPTIONS = [
  {
    value: INTERVAL.ALL.key,
    text: INTERVAL.ALL.value,
  },
  {
    value: INTERVAL.WEEK.key,
    text: INTERVAL.WEEK.value,
  },
  {
    value: INTERVAL.MONTH.key,
    text: INTERVAL.MONTH.value,
  },
  {
    value: INTERVAL.YEAR.key,
    text: INTERVAL.YEAR.value,
  },
];

export function getIntervalOptions() {
  return INTERVAL_OPTIONS;
}

export const EMERGENCY_DEGREE = {
  LOW: {
    key: 'low',
    value: i18n.t('cp_degree_low'),
  },
  MEDIUM: {
    key: 'medium',
    value: i18n.t('cp_degree_medium'),
  },
  HIGH: {
    key: 'high',
    value: i18n.t('cp_degree_high'),
  },
};

export const EMERGENCY_DEGREE_OPTIONS = [
  {
    value: null,
    text: 'Selecciona',
  },
  {
    value: EMERGENCY_DEGREE.LOW.key,
    text: EMERGENCY_DEGREE.LOW.value,
  },
  {
    value: EMERGENCY_DEGREE.MEDIUM.key,
    text: EMERGENCY_DEGREE.MEDIUM.value,
  },
  {
    value: EMERGENCY_DEGREE.HIGH.key,
    text: EMERGENCY_DEGREE.HIGH.value,
  },
];

export const BOOLEAN_SELECTION = {
  TRUE: {
    key: true,
    value: i18n.t('cp_boolean_true'),
  },
  FALSE: {
    key: false,
    value: i18n.t('cp_boolean_false'),
  },
};

export const LANGUAGE_SELECTION = {
  SPANINSH: {
    key: 1,
    value: i18n.t('cp_spanish_language'),
  },
  ENGLISH: {
    key: 2,
    value: i18n.t('cp_english_language'),
  },
};

export const BOOLEAN_SELECTION_OPTIONS = [
  {
    value: BOOLEAN_SELECTION.TRUE.key,
    text: BOOLEAN_SELECTION.TRUE.value,
  },
  {
    value: BOOLEAN_SELECTION.FALSE.key,
    text: BOOLEAN_SELECTION.FALSE.value,
  },
];

export const LANGUAGE_SELECTION_OPTIONS = [
  {
    value: LANGUAGE_SELECTION.SPANINSH.key,
    text: LANGUAGE_SELECTION.SPANINSH.value,
  },
  {
    value: LANGUAGE_SELECTION.ENGLISH.key,
    text: LANGUAGE_SELECTION.ENGLISH.value,
  },
];

export const STAGE = {
  RECEIVED: {
    key: '71a95825-9fed-43a3-b3da-ae181881b720',
    value: 'cp_stage_received',
  },
  DOCUMENTATION_REVISION: {
    key: '0554f9ee-063d-423d-ab1d-ceae0728573e',
    value: 'cp_stage_documentation_revision',
  },
  SPECIALIST_EVALUATION: {
    key: 'd764cc84-7000-4854-98ab-e0ef7f9c9557',
    value: 'cp_stage_specialist_evaluation',
  },
  TREATMENT_PLAN: {
    key: '1e50b506-ac10-4e5c-b2ff-2c6fe9715066',
    value: 'cp_stage_treatment_plan',
  },
  IN_TREATMENT: {
    key: '920e5880-6249-4324-b440-16b516a8e56a',
    value: 'cp_stage_in_treatment',
  },
  MEDICAL_DISCHARGE: {
    key: '7f86c0db-bcce-4fba-8ea1-be058dde177f',
    value: 'cp_stage_medical_discharge',
  },
};

export const STAGE_OPTIONS = [
  {
    value: STAGE.RECEIVED.key,
    text: STAGE.RECEIVED.value,
  },
  {
    value: STAGE.DOCUMENTATION_REVISION.key,
    text: STAGE.DOCUMENTATION_REVISION.value,
  },
  {
    value: STAGE.SPECIALIST_EVALUATION.key,
    text: STAGE.SPECIALIST_EVALUATION.value,
  },
  {
    value: STAGE.TREATMENT_PLAN.key,
    text: STAGE.TREATMENT_PLAN.value,
  },
  {
    value: STAGE.IN_TREATMENT.key,
    text: STAGE.IN_TREATMENT.value,
  },
  {
    value: STAGE.MEDICAL_DISCHARGE.key,
    text: STAGE.MEDICAL_DISCHARGE.value,
  },
];

export function getStageOptions() {
  return STAGE_OPTIONS;
}

export const KPIS = [
  {
    value: 'total de solicitudes',
    text: 'lb_total_requests',
  },
  {
    value: 'solicitudes aceptadas',
    text: 'lb_requests_accepted',
  },
  {
    value: 'solicitudes pendientes',
    text: 'lb_requests_pending',
  },
  {
    value: 'solicitudes rechazadas',
    text: 'lb_requests_rejected',
  },
  {
    value: 'total pacientes',
    text: 'lb_total_patients',
  },
];

export const KPI_VARIANTS = [
  'total', 'accepted', 'pending', 'rejected',
];

export const MIN_SEARCH__LENGTH = 3;

export const PHONE__MIN__LENGTH = 9;

export const DNI__MIN__LENGTH = 8;

export function getSelectDefault(type) {
  const label = `lb_select_${type}`;
  return {
    value: null,
    text: i18n.t(label),
  };
}

export const SORT_TYPE = {
  ASC: 'asc',
  DESC: 'desc',
};

export function getSortType(type) {
  if (type === null) {
    return SORT_TYPE.ASC;
  }

  return type === SORT_TYPE.ASC ? SORT_TYPE.DESC : SORT_TYPE.ASC;
}

export function getFiltersCount(filters) {
  let totalActiveFilters = 0;

  Object.entries(filters).map((item) => {
    if (item[1] !== null) {
      totalActiveFilters += 1;
    }

    return null;
  });

  return totalActiveFilters;
}

export function getIdList(list) {
  if (list.length > 0) {
    const idList = [];

    list.forEach((item) => idList.push(item.id));

    return idList;
  }

  return null;
}

export function downloadCsv(data) {
  const link = document.createElement('a');
  const blob = new Blob([data], { type: data.headers['content-type'] });
  const url = URL.createObjectURL(blob);
  link.href = url;
  const contentDisposition = data.headers['content-disposition'];
  const fileName = getFileNameFromContentDisposition(contentDisposition);
  link.setAttribute('download', fileName);

  link.click();
}

export function uploadCsv(document) {
  const requestData = {
    fileName: document.file.name,
    encodedFile: document.base64.encodedFile,
    mimeType: document.file.type,
    extension: document.base64.extension,
  };

  return requestData;
}

export const removeAccents = (data) => data.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
export const DEBOUNCE_TIME = 500;

export const VIDASPRIME_TELEPHONE = '679 63 77 22';

export const PRIVACY_POLICY_LINK = 'https://vidasprime.es/politica-de-privacidad/';
export const COOKIES_POLICY_LINK = 'https://vidasprime.es/politica-de-cookies/';
export const CONTACT_US_LINK = 'https://vidasprime.es/reserva-demo/';
export const GO_TO_WEBSITE_LINK = 'https://vidasprime.es/profesionales/';
export const SUBSCRIBE_LINK = 'https://vidasprime.es/nueva-solicitud/';
export const ASK_DEMO_LINK = 'https://vidasprime.es/reserva-demo/';
