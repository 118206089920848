export default {
  btn_accept: 'Aceptar',
  btn_add: 'Añadir',
  btn_apply: 'Aplicar',
  btn_cancel: 'Cancelar',
  btn_change: 'Cambiar',
  btn_close_session: 'Cerrar sesión',
  btn_close: 'Cerrar',
  btn_confirm: 'Confirmar',
  btn_delete: 'Borrar',
  btn_edit: 'Editar',
  btn_export: 'Exportar',
  btn_filters: 'Filtros',
  btn_forgot_password: 'Recuperar contraseña',
  btn_import: 'Importar',
  btn_login: 'Iniciar sesión',
  btn_new_f: 'Nueva',
  btn_new: 'Nuevo',
  btn_new_collaboration: 'Nueva colaboración',
  btn_send_request: 'Enviar solicitud',
  btn_reject: 'Rechazar',
  btn_reset_password: 'Iniciar sesión',
  btn_save_request: 'Guardar solicitud',
  btn_save: 'Guardar',
  btn_send_survey: 'Enviar encuesta',
  btn_survey_new: 'Nuevo envío',
  btn_survey_type_new: 'Nueva plantilla',
  btn_update: 'Actualizar',
  btn_verify: 'Validar',
  btn_watch: 'Ver',
  btn_invitation: 'Enviar invitación',
  btn_invitation_cancel: 'Cancelar invitación',
  btn_invitation_accept: 'Aceptar invitación',
  btn_invitation_reject: 'Rechazar invitación',
  btn_search: 'Buscar',
};
