import getAxiosWithAuthHeader from '../../helpers/AuthHelper';
import {
  getDataWithUuid,
  getParameterUrl,
  getSearchUrl,
} from '@/helpers/ApiHelper';

import SurveyTypePartialsTypeFactory from './SurveyTypePartialsTypeFactory';

const SURVEY_TYPE_PARTIALS_TYPE = '/api/survey/type/partials/type';
const SEARCH_SURVEYS_TYPE_PARTIALS_TYPE = '/api/survey/type/type/partials/search';

class surveyTypePartialsTypeAPI {
  constructor() {
    this.client = getAxiosWithAuthHeader();
  }

  getAllSurveyTypePartialsType(currentPage) {
    const url = `${SURVEY_TYPE_PARTIALS_TYPE}/${currentPage.uuid}`;

    const parameterUrl = getParameterUrl(url, currentPage.page,
      [], [], currentPage.itemsPerPage);

    return this.client
      .get(parameterUrl)
      .then((res) => SurveyTypePartialsTypeFactory.getAllSurveyTypePartialsType(res.data))
      .catch((error) => Promise.reject(error));
  }

  filterSurveyTypePartialsType(data) {
    const parameterUrl = getParameterUrl(
      SEARCH_SURVEYS_TYPE_PARTIALS_TYPE,
      data.page,
      data.filters,
      data.ordenation,
    );

    return this.client
      .get(parameterUrl)
      .then((res) => SurveyTypePartialsTypeFactory.getAllSurveyTypePartialsType(res.data))
      .catch((error) => Promise.reject(error));
  }

  searchSurveyTypePartialsType(filters) {
    const filterUrl = getSearchUrl(SEARCH_SURVEYS_TYPE_PARTIALS_TYPE, filters);

    return this.client
      .get(filterUrl)
      .then((res) => SurveyTypePartialsTypeFactory.getAllSurveyTypePartialsType(res.data))
      .catch((error) => Promise.reject(error));
  }

  createSurveyTypePartialsType(data) {
    const idData = getDataWithUuid(data);

    return this.client
      .post(SURVEY_TYPE_PARTIALS_TYPE, idData)
      .then(() => idData)
      .catch((error) => Promise.reject(error));
  }

  updateSurveyTypePartialsType(requestData) {
    const url = `${SURVEY_TYPE_PARTIALS_TYPE}/${requestData.id}`;

    return this.client
      .put(url, requestData.body)
      .then()
      .catch((error) => Promise.reject(error));
  }

  deleteSurveyTypePartialsType(id) {
    const url = `${SURVEY_TYPE_PARTIALS_TYPE}/${id}`;

    return this.client
      .delete(url)
      .then()
      .catch((error) => Promise.reject(error));
  }
}

export default surveyTypePartialsTypeAPI;
