import {
  getAllClinic,
  filterClinic,
  searchClinic,
  createClinic,
  createCollaboratorClinic,
  updateClinic,
  deleteClinic,
} from './clinicActions';
import {
  setClinics,
  setClinicPagination,
  setClinicList,
} from './clinicMutations';
import {
  getClinics,
  getClincPaginationState,
  getClinicListState,
  hasClinics,
} from './clinicGetters';

const clinic = {
  namespaced: true,
  state: () => ({
    clinicList: [],
    pagination: {},
  }),
  actions: {
    getAllClinic,
    filterClinic,
    searchClinic,
    createClinic,
    createCollaboratorClinic,
    updateClinic,
    deleteClinic,
  },
  mutations: {
    setClinics,
    setClinicPagination,
    setClinicList,
  },
  getters: {
    getClinics,
    getClincPaginationState,
    getClinicListState,
    hasClinics,
  },
};

export default clinic;
