import Vue from 'vue';
import VueI18n from 'vue-i18n';
import esMessages from '../translations/spanish/es-messages';
import gbMessages from '../translations/english/gb-messages';

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: 'es',
  availableLocales: {
    es: 'es',
    gb: 'gb',
  },
  fallbackLocale: {
    gb: 'gb',
    default: ['es'],
  },
  messages: {
    es: esMessages,
    gb: gbMessages,
  },
});

export default i18n;
