/* eslint-disable class-methods-use-this */
import InsightsModel from './InsightsModel';

class InsightsFactory {
  constructor() {
    this.insights = {};
  }

  getAllInsights(data) {
    const InsightsResponse = {
      InsightsList: this.getCollection(data?.collection),
      // pagination: PaginationFactory.getPagination(data?.pagination),
    };

    return InsightsResponse;
  }

  getInsights(insights) {
    this.insights = new InsightsModel({
      amount: insights.amount,
      label: insights.label,
      percentage: insights.percentage,
      unit: insights.unit,
    });

    return this.insights;
  }

  getCollection(insightsCollection) {
    const insightsList = insightsCollection.map(
      (insights) => this.getInsights(insights),
    );

    return insightsList;
  }
}

export default new InsightsFactory();
