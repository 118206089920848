<template>
  <private-template>
    <template v-slot:private-template>
      <div class="sp__v__2x"/>
      <PatientDetail
        v-if="showDetail"
        :patient="patient"
        :isDoctor="isDoctor"
        @showUpdateForm="showDetailUpdateForm"
        @showDeleteConfirm="showDetailDeleteModal"
        @hideDetail="hideDetailView"
      />
      <div v-if="!showDetail">
        <PatientHeadingCards
          :title="$i18n.t('lb_patients')"
          :text="getText"
          :kpis="kpis"
          :isLoading="isLoading"
          @handleSelectInterval="loadReport"
        />
        <div class="sp__v__3x"/>
        <div
          v-if="!isDoctor && !isCollaborator"
          class="buttonContainer"
        >
          <b-button
            @click.prevent="showCreateModal"
            variant="primary"
            size="sm"
          >
             {{ `+ ${$i18n.t('btn_new')}` }}
          </b-button>
        </div>
        <div class="sp__v__2x"/>
        <TableGroup
          id="patient"
          :items="patientList"
          :isLoading="isTableLoading"
          :pagination="pagination"
          :searchText="searchText"
          :filterCount="getFilterCount"
          :showImportButton="isClinic"
          :showExportButton="!isDoctor"
          @handleSearch="handleSearchByInput"
          @handleSearchByFilter="handleSearchByFilter"
          @handlePaginate="handleFilterSearch"
          @handleCleanSearch="handleCleanSearch"
          @handleImport="handleShowImportModal"
          @handleExport="handleShowExportModal"
        >
          <template v-slot:content>
            <PatientFilters
              @handleChangeBirthdate="handleChangeBirthdate"
              @handleChangeOrigin="handleChangeOrigin"
              :filters="filters"
            />
          </template>

          <PatientTable
            :patientItems="patientList"
            :canDelete="!isDoctor"
            :isVidasprime="isVidasprime"
            @showUpdateForm="showUpdateForm"
            @showDeleteModal="showDeleteModal"
            @handleSelectRow="handleSelectRow"
            @handleSortAsc="handleSortAsc"
          />
        </TableGroup>
      </div>
        <CreatePatient
          v-if="showCreatePatient"
          :isVidasprime="isVidasprime"
          @addPatient="addPatient"
          @handleCancel="closeCreateModal"
        />
        <UpdatePatient
          @updatePatient="updatePatient"
          :patient="patient"
        />
        <DeletePatient
          @deletePatient="deletePatient"
          ref="deletePatient"
        />
        <ImportPatientModal
          id="requestFormImportPatientModal"
          :isCsvLoading="isCsvLoading"
          @handleUploadFile="handleUploadImportPatient"
        />
    </template>
  </private-template>
</template>

<script>
import PrivateTemplate from '@/components/organisms/PrivateTemplate.vue';
import PatientHeadingCards from '@/components/molecules/Patient/PatientHeadingCards.vue';
import CreatePatient from '@/components/organisms/Patient/CreatePatient.vue';
import UpdatePatient from '@/components/organisms/Patient/UpdatePatient.vue';
import TableGroup from '@/components/molecules/Tables/TableGroup/TableGroup.vue';
import PatientTable from '@/components/molecules/Tables/PatientTable/PatientTable.vue';
import DeletePatient from '@/components/organisms/Patient/DeletePatient.vue';
import PatientDetail from '@/components/organisms/Patient/PatientDetail.vue';
import PatientFilters from '@/components/organisms/Patient/PatientFilters.vue';
import {
  getImageFormatted,
  getCurrentDate,
  getSortType,
  SORT_TYPE,
  getFiltersCount,
  uploadCsv,
} from '@/helpers/ComponentHelper';
import { getOrdenation, getFiltersGroup } from '@/helpers/ApiHelper';
import ImportPatientModal from '@/components/molecules/Modal/ImportPatientModal.vue';
import { exportPatients } from '@/store/patient/patientActions';

export default {
  data() {
    return {
      patientList: [],
      patient: {
        id: null,
        idCrm: null,
        createdAt: null,
        name: null,
        lastname: null,
        gender: null,
        origin: null,
        dni: null,
        dob: null,
        tutor: null,
        hasInsurance: null,
        insurance: null,
        hospital: null,
        email: null,
        phone: null,
        requests: null,
        contactWhatsApp: null,
        documents: [],
        totalRequests: [],
        requestList: [],
      },
      showCreatePatient: false,
      showDetail: false,
      filters: {
        dob: null,
        origin: null,
      },
      isTableLoading: false,
      searchText: null,
      sort: {
        name: 'createdAt',
        type: 'desc',
      },
      pagination: {},
      kpis: [],
      isLoading: false,
      isCsvLoading: false,
    };
  },
  created() {
    this.handleSearch();
    this.loadReport();
  },
  methods: {
    loadReport(interval) {
      this.isLoading = true;

      this.$store.dispatch('kpi/getAllKpi', interval)
        .then((res) => {
          this.getPatientKpi(res.kpiList);

          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },

    getPatientKpi(list) {
      const currentList = list.find((kpi) => kpi.name.toLowerCase() === 'total pacientes');
      currentList.label = 'lb_total_patients';

      this.kpis = currentList;
    },

    handleShowImportModal() {
      this.$bvModal.show('requestFormImportPatientModal');
    },

    handleShowExportModal() {
      this.isTableLoading = true;

      this.$store.dispatch('patient/exportPatients', null)
        .then((res) => {
          this.downloadPatientCsv(res);
          this.isTableLoading = false;
        })
        .catch(() => {
          this.isTableLoading = false;
        });
    },

    downloadPatientCsv(document) {
      exportPatients(document);
    },

    handleUploadImportPatient(document) {
      const requestData = uploadCsv(document);

      this.isCsvLoading = true;

      this.$store.dispatch('patient/importPatients', requestData)
        .then(() => {
          this.closeImportModal();
          this.isCsvLoading = false;
        })
        .catch(() => {
          this.isCsvLoading = false;
        });
    },

    closeImportModal() {
      this.$bvModal.hide('requestFormImportPatientModal');
    },

    handleSearch(page = 1) {
      this.isTableLoading = true;

      this.$store.dispatch('patient/getAllPatient', page)
        .then((res) => {
          this.patientList = res.patientList;
          this.pagination = res.pagination;
          this.isTableLoading = false;
        })
        .catch(() => {
          this.isTableLoading = false;
        });
    },

    getFilters() {
      const filtersGroup = [];

      if (this.searchText !== null) {
        filtersGroup.push(
          `name:${this.searchText}`,
        );
      }

      return getFiltersGroup(filtersGroup, this.filters);
    },

    getRequestData(page = 1) {
      return {
        page,
        filters: this.getFilters(),
        ordenation: getOrdenation(this.sort),
      };
    },

    handleFilterSearch(page = 1) {
      this.isTableLoading = true;

      this.$store.dispatch('patient/filterPatient', this.getRequestData(page))
        .then((res) => {
          this.patientList = res.patientList;
          this.pagination = res.pagination;
          this.isTableLoading = false;
        })
        .catch(() => {
          this.isTableLoading = false;
        });
    },

    handleSelectRow(item) {
      this.setPatient(item);

      this.showDetailView();
    },

    showUpdateForm(data) {
      this.setPatient(data);

      this.$bvModal.show('update-patient');
    },

    showDetailUpdateForm(data) {
      this.setPatient(data);

      this.$bvModal.show('update-patient');
    },

    showDeleteModal(data) {
      this.$refs.deletePatient.setId(data.id);
      this.$bvModal.show('delete-patient');
    },

    showDetailDeleteModal() {
      this.$refs.deletePatient.setId(this.patient.id);
      this.$bvModal.show('delete-patient');
    },

    showCreateModal() {
      this.showCreatePatient = true;
    },

    closeCreateModal() {
      this.showCreatePatient = false;
    },

    showDetailView() {
      this.showDetail = true;
    },

    hideDetailView() {
      this.showDetail = false;
    },

    addPatient(data) {
      const newData = data;
      newData.image = getImageFormatted(data.image);

      newData.createdAt = getCurrentDate();

      this.patientList.unshift(newData);
      this.incrementPagination();
      this.loadReport();
      this.closeCreateModal();
    },

    incrementPagination() {
      this.pagination.totalItems += 1;
      this.pagination.itemsPerPage += 1;
    },

    updatePatient(data) {
      for (let i = 0; this.patientList.length; i += 1) {
        if (this.patientList[i].id === data.id) {
          const newPatient = {
            id: data.id,
            createdAt: data.createdAt,
            name: data.name,
            idCrm: data.idCrm,
            lastname: data.lastname,
            gender: data.gender,
            origin: data.origin,
            dni: data.dni,
            dob: data.dob,
            tutor: data.tutor,
            hasInsurance: data.hasInsurance,
            insurance: data.insurance,
            hospital: data.hospital,
            email: data.email,
            phone: data.phone,
            requests: this.patientList[i].requests,
            contactWhatsApp: data.contactWhatsApp,
            documents: data.documents,
            totalRequests: data.totalRequests,
            requestList: data.requestList,
          };

          this.patientList.splice(i, 1);
          this.patientList.splice(i, 0, newPatient);
          break;
        }
      }

      this.$bvModal.hide('update-patient');

      this.setPatient(data);
    },

    deletePatient(id) {
      this.$bvModal.hide('delete-patient');

      if (this.showDetail) {
        this.hideDetailView();
      }

      for (let i = 0; this.patientList.length; i += 1) {
        if (this.patientList[i].id === id) {
          this.patientList.splice(i, 1);
          this.handleSearch(this.pagination.currentPage);
          break;
        }
      }
    },

    handleSearchByInput(text) {
      if (text !== null) {
        this.searchText = text;
        this.handleFilterSearch();
      } else {
        this.searchText = null;
      }
    },

    handleSearchByFilter() {
      this.handleFilterSearch();
      this.$root.$emit('bv::toggle::collapse', 'patient-sidebar');
    },

    handleSortAsc(param) {
      this.sort.type = this.sort.name === param ? getSortType(this.sort.type) : SORT_TYPE.ASC;
      this.sort.name = param;

      this.handleFilterSearch();
    },

    handleChangeBirthdate(value) {
      this.filters.dob = value;
    },

    handleChangeOrigin(value) {
      this.filters.origin = value;
    },

    setPatient(data) {
      this.patient = {
        id: data.id,
        idCrm: data.idCrm,
        createdAt: data.createdAt,
        name: data.name,
        lastname: data.lastname,
        gender: data.gender,
        origin: data.origin,
        dni: data.dni,
        dob: data.dob,
        tutor: data.tutor,
        hasInsurance: data.hasInsurance,
        insurance: data.insurance,
        hospital: data.hospital,
        email: data.email,
        phone: data.phone,
        requests: data.requests,
        contactWhatsApp: data.contactWhatsApp,
        documents: data.documents,
        totalRequests: data.totalRequests,
        requestList: data.requestList,
      };
    },

    handleCleanSearch() {
      this.filters.dob = null;
      this.filters.origin = null;
      this.searchText = null;
      this.sort.name = null;
      this.sort.type = null;

      this.handleSearch();
    },
  },
  computed: {
    getFilterCount() {
      return getFiltersCount(this.filters);
    },

    isDoctor() {
      return this.$store.getters['user/isDoctor'];
    },

    isClinic() {
      return this.$store.getters['user/isClinic'];
    },

    isCollaborator() {
      return this.$store.getters['user/isCollaborator'];
    },

    isHealthManager() {
      return this.$store.getters['user/isHealthManager'];
    },

    isVidasprime() {
      return this.$store.getters['user/isVidasprime'];
    },

    isStates() {
      return this.$store.getters['user/getCurrentUser'];
    },

    getText() {
      return this.isVidasprime || this.isHealthManager
        ? this.$i18n.t('txt_patients_vp_role')
        : this.$i18n.t('txt_patients_clinic_role');
    },
  },
  components: {
    PrivateTemplate,
    PatientHeadingCards,
    CreatePatient,
    UpdatePatient,
    TableGroup,
    PatientTable,
    DeletePatient,
    PatientDetail,
    PatientFilters,
    ImportPatientModal,
  },
};
</script>
