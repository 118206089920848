var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Breadcrumb',{attrs:{"items":[
      {
        name: _vm.$i18n.t('lb_patients'),
        hasAction: true,
      },
      {
        name: _vm.getFullName,
      } ]},on:{"handleClickAction":_vm.hideDetail}}),_c('div',{staticClass:"sp__v"}),_c('HeadingBar',{attrs:{"title":_vm.getFullName,"text":_vm.$i18n.t('cp_created_at', { date: _vm.getFormattedDate(_vm.patient.createdAt) }),"isData":true}},[_c('div',{staticClass:"detailCard__actionBlock"},[(!_vm.isDoctor)?_c('b-button',{attrs:{"variant":"secondary","size":"sm"},on:{"click":function($event){$event.preventDefault();return _vm.handleDelete.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$i18n.t('btn_delete'))+" ")]):_vm._e(),_c('span',{staticClass:"sp__1s5x"}),_c('b-button',{attrs:{"variant":"secondary","size":"sm"},on:{"click":function($event){$event.preventDefault();return _vm.handleEdit.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$i18n.t('btn_edit'))+" ")])],1)]),_c('div',{staticClass:"sp__v__3x"}),_c('b-tabs',[_c('b-tab',{attrs:{"title":_vm.$i18n.t('lb_info')}},[_c('PatientInfo',{attrs:{"patient":_vm.patient}})],1),_c('b-tab',{attrs:{"title":_vm.$i18n.t('lb_contact')}},[_c('PatientContact',{attrs:{"patient":_vm.patient}})],1),_c('b-tab',{attrs:{"title":_vm.getDocumentsTitle}},[_c('div',{staticClass:"sp__v__3x"}),(_vm.hasDocuments)?_c('DocumentTable',{attrs:{"items":_vm.patient.documents,"canDownload":""}}):_vm._e(),(!_vm.hasDocuments)?_c('p',{staticClass:"text__pr"},[_vm._v(" "+_vm._s(_vm.$i18n.t('cp_not_result'))+" ")]):_vm._e()],1),_c('b-tab',{attrs:{"title":_vm.$i18n.t('lb_request_count', { count: _vm.patient.totalRequests })}},[_c('PatientRequest',{attrs:{"showPagination":false,"items":_vm.patient.requestList,"patient":_vm.patient}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }