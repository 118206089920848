import Vue from 'vue';
import Vuex from 'vuex';
import appointments from './appointments/appointments';
import auth from './auth/auth';
import cities from './cities/cities';
import clinic from './clinic/clinic';
import countries from './countries/countries';
import doctor from './doctor/doctor';
import document from './document/document';
import error from './error/error';
import healthManager from './healthManager/healthManager';
import insights from './insights/insights';
import insurance from './insurance/insurance';
import invitations from './invitations/invitations';
import kpi from './kpi/kpi';
import markets from './markets/markets';
import mutations from './mutations';
import note from './note/note';
import notification from './notification/notification';
import partialsType from './partialsType/partialsTypes';
import patient from './patient/patient';
import request from './request/request';
import responsive from './responsive/responsive';
import specialties from './specialties/specialties';
import state from './state';
import survey from './survey/survey';
import surveyType from './surveyType/surveyType';
import surveyTypePartialsType from './surveyTypePartialsType/surveyTypePartialsType';
import ui from './ui/ui';
import user from './user/user';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    appointments,
    auth,
    cities,
    clinic,
    countries,
    doctor,
    document,
    error,
    healthManager,
    insights,
    insurance,
    invitations,
    kpi,
    markets,
    note,
    notification,
    partialsType,
    patient,
    request,
    responsive,
    specialties,
    survey,
    surveyType,
    surveyTypePartialsType,
    ui,
    user,
  },
  state,
  mutations,
  actions: {},
});
