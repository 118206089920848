<template>
  <div class="Table" ref="requestTableComponent">
    <b-table
      :items="this.requestItems"
      :patient="patient"
      :fields="columns"
      hover
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      selectable
      select-mode="single"
      :lang="this.$i18n.locale"
      @row-selected="onRowSelected"
      ref="requestTableComponent"
    >
      <template #cell(createdAt)="data">
        <span class="text__sr text-secondary">{{ data.item.createdAt | formatDate }}</span>
      </template>
      <template #cell(collaboration)="data">
        <Badge
          v-if="isCollaboration(data)"
          :variant="status.COLLABORATION.key"
        >
          {{getVariantByStatus(status.COLLABORATION.key)}}
        </Badge>
      </template>
      <template #cell(name)="data">
        <span class="text__sr text-secondary">{{
            data.item.patient ? data.item.patient.name : null
          }}</span>
        <span v-if="isPatientRequestView" class="text__sr text-secondary">{{
            patient.name
          }}</span>
      </template>
      <template #cell(origin)="data">
        <span v-if="!isPatientRequestView" class="text__sr text-secondary">{{
            getOrigin(data.item.patient ? data.item.patient.origin : null)
          }}</span>
        <span v-if="isPatientRequestView" class="text__sr text-secondary">{{
              patient.origin.name
          }}</span>
      </template>
      <template #cell(specialty)="data">
        <span class="text__sr text-secondary">{{
            data.item.specialty ? data.item.specialty.name : null
          }}</span>
      </template>
      <template #cell(medicalProcedure)="data">
        <span class="text__sr text-secondary">{{ data.item.medicalProcedure }}</span>
      </template>
      <template #cell(clinic)="data">
        <IconGroup
          v-if="data.item.clinic"
          :icon="getClinicAvatar(data.item.clinic)"
          :label="data.item.clinic ? data.item.clinic.name : null"
        />
      </template>
      <template #cell(requestStage)="data">
        <span class="text__sr text-secondary">
          {{ getRequestStage(data.item.requestStage) }}
        </span>
      </template>
      <template #cell(status)="data">
        <Badge
          v-if="data.item.status"
          :variant="data.item.status"
        >
          {{ getVariantByStatus(data.item.status.toLowerCase()) }}
        </Badge>
      </template>
      <template #cell(actions)="data">
        <IconActionsGroup
          v-if="!(isClinic && data.item.status !== status.ACCEPTED.key)"
          @edit="showUpdateForm"
          @copy="showCopyForm"
          @delete="showDeleteModal"
          :objectData="data.item"
          :canDelete="canDelete"
          :canEdit="canEdit"
          :canCopy="canCopy"
        />
      </template>
    </b-table>
  </div>
</template>

<script>
import IconActionsGroup from '@/components/atoms/Icon/IconActionsGroup/IconActionsGroup.vue';
import IconGroup from '@/components/atoms/Icon/IconGroup/IconGroup.vue';
import Badge from '@/components/atoms/Badge/Badge.vue';
import {
  getImageFormatted,
  getVariantByStatus,
  STAGE_OPTIONS,
  STATUS,
} from '@/helpers/ComponentHelper';
import { getAvatarByPathRequest } from '@/helpers/UserHelper';

export default {
  props: {
    patient: {
      type: Object,
      default: () => {},
    },
    requestItems: {
      type: Array,
      default: () => [],
    },
    showActions: {
      type: Boolean,
      default: true,
    },
    canDelete: {
      type: Boolean,
      default: true,
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
    canCopy: {
      type: Boolean,
      default: true,
    },
    isPatientRequestView: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sortBy: 'case',
      sortDesc: false,
      status: STATUS,
    };
  },
  methods: {
    onRowSelected(items) {
      if (items.length > 0) {
        this.$emit('handleSelectRow', items[0]);
      }
    },
    showUpdateForm(data) {
      this.$emit('showUpdateForm', data);
    },

    showCopyForm(data) {
      this.$emit('showCopyForm', data);
    },

    showDeleteModal(data) {
      this.$emit('showDeleteModal', data);
    },

    getImage(image) {
      return getImageFormatted(image);
    },

    getOrigin(origin) {
      return origin !== null ? origin.name : null;
    },

    getClinicAvatar(clinic) {
      return getAvatarByPathRequest(clinic);
    },

    getDoctorAvatar(doctor) {
      return getAvatarByPathRequest(doctor);
    },

    getVariantByStatus(status) {
      return this.$i18n.t(getVariantByStatus(status));
    },

    getRequestStage(data) {
      if (!data) return null;

      let stageName;

      if (typeof data === 'string') {
        const stageOption = STAGE_OPTIONS.filter((stage) => stage.value === data);
        stageName = this.$i18n.t(stageOption[0].text);

        return stageName;
      }

      const stageOption = STAGE_OPTIONS.filter((stage) => stage.value === data.id);
      stageName = this.$i18n.t(stageOption[0].text);

      return stageName;
    },

    isCollaboration(data) {
      return data.item.collaborators.length;
    },
  },
  computed: {
    columns() {
      return [
        {
          key: 'caseNumber',
          label: this.$i18n.t('lb_instance'),
          sortable: true,
        },
        {
          key: 'collaboration',
          label: this.$i18n.t('lb_type'),
        },
        {
          key: 'createdAt',
          label: this.$i18n.t('lb_created'),
          sortable: true,
        },
        {
          key: 'name',
          label: this.$i18n.t('lb_name'),
          sortable: true,
        },
        {
          key: 'origin',
          label: this.$i18n.t('lb_origin'),
          sortable: true,
        },
        {
          key: 'specialty',
          label: this.$i18n.t('lb_specialty'),
          sortable: true,
        },
        {
          key: 'medicalProcedure',
          label: this.$i18n.t('lb_procedure'),
          sortable: true,
        },
        {
          key: 'clinic',
          label: this.$i18n.t('lb_refer_to_f'),
          sortable: true,
        },
        {
          key: 'requestStage',
          label: this.$i18n.t('lb_stage'),
          sortable: true,
        },
        {
          key: 'status',
          label: this.$i18n.t('lb_status'),
          sortable: true,
        },
        {
          key: 'actions',
          label: '',
        },
      ];
    },
    getFields() {
      let newFields;

      if (this.isDoctor || this.isClinic) {
        newFields = this.fields.filter((el) => el.key !== 'clinic');

        return newFields;
      }

      if (!(this.isDoctor || this.isClinic)) {
        newFields = this.fields.filter((el) => el.key !== 'doctor');

        return newFields;
      }

      if (this.showActions) {
        newFields.push({ key: 'actions', label: '' });
      }

      return newFields;
    },

    isDoctor() {
      return this.$store.getters['user/isDoctor'];
    },

    isClinic() {
      return this.$store.getters['user/isClinic'];
    },
  },
  watch: {
    sortBy(newValue) {
      this.$emit('handleSortAsc', newValue);
    },

    sortDesc(newValue) {
      if (newValue === true) {
        this.$emit('handleSortDesc', this.sortBy);
      } else {
        this.$emit('handleSortAsc', this.sortBy);
      }
    },
  },
  components: {
    IconActionsGroup,
    IconGroup,
    Badge,
  },
};
</script>

<style lang="sass" scoped>
@import '../Table.sass'
</style>
<!---->
