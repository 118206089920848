<template>
  <ModalAction
    :id="id"
    :okLabel="`${$i18n.t('btn_save')} Doctor`"
    :cancelLabel="$i18n.t('btn_cancel')"
    :isLoading="isLoading"
    @handleOk="handleOk"
    @handleCancel="handleCancel"
    isForm
  >
  <b-form>
    <b-row>
      <b-col>
        <p class="text__formTitle">{{ title }}</p>
      </b-col>
    </b-row>

    <div class="sp__v__1d5x" />

    <b-row>
      <b-col>
        <FileUploader
          @handleSetFile="handleChangeImage"
          isImage
          :image="this.getAvatar"
        />
        <div class="sp__v__1s5x" />
        <p class="text__sr text-secondary">{{ $i18n.t('cp_file_uploader_help') }}</p>
      </b-col>
    </b-row>

    <div class="sp__v__1d5x" />

    <b-row>
      <b-col class="col-6">
        <InputGroup
          id="doctorFormName"
          :label="$i18n.t('lb_name')"
          :placeholder="$i18n.t('lb_type_something')"
          @handlerInputChange="handleChangeName"
          :isValid="this.validationState.name"
          error="No puede estar vacio"
          :value="this.doctorData.name"
        />
      </b-col>
      <b-col class="col-6">
        <InputGroup
          id="doctorFormSurname"
          :label="$i18n.t('lb_lastname')"
          :placeholder="$i18n.t('lb_type_something')"
          @handlerInputChange="handleChangeSurname"
          :isValid="this.validationState.lastname"
          error="No puede estar vacio"
          :value="this.doctorData.lastname"
        />
      </b-col>
    </b-row>

    <div class="sp__v__1d5x" />

    <b-row>
      <b-col class="col-6">
        <Specialty
          id="doctorFormSpecialist"
          class="w-50"
          :label="$i18n.t('lb_specialty')"
          :value="getSpecialty"
          @handleChangeSpecialty="handleChangeSpecialty"
          :isValid="this.validationState.specialty"
        />
      </b-col>
    </b-row>

    <div class="sp__v__1d5x" />

     <b-row>
      <b-col class="col-6">
<!-- this.clinicData.defaultLanguage -->
        <SelectGroup
          id="defaultLanguage"
          :label="$i18n.t('lb_default_language')"
          :selectedOption="this.doctorData.defaultLanguage"
          :options="languageOptions"
          @handlerSelect="handleChangeDefaultLanguage"
        />
      </b-col>
    </b-row>

<!--    <SpecialtyComboSelector-->
<!--      @handleChangeSpecialties="handleChangeSpecialties"-->
<!--      @handleChangeHasSpecialty="handleChangeHasSpecialty"-->
<!--      :hasSelectedSpecialties="true"-->
<!--      :specialties="this.doctorData.specialties"-->
<!--    />-->

<!--    <div class="sp__v__1d5x" />-->

    <div class="sp__v__1d5x" />

    <b-row>
      <b-col class="col-6">
        <TextareaGroup
          id="doctorFormBio"
          :label="$i18n.t('lb_bio')"
          :placeholder="$i18n.t('lb_type_something')"
          @handlerTextareaChange="handleChangeBio"
          :value="this.doctorData.bio"
        />
      </b-col>
      <b-col class="col-6">
        <TextareaGroup
          id="doctorFormExperience"
          :label="$i18n.t('lb_professional_experience')"
          :placeholder="$i18n.t('lb_type_something')"
          @handlerTextareaChange="handleChangeExperience"
          :value="this.doctorData.experience"
        />
      </b-col>
    </b-row>

    <div class="sp__v__1d5x" />

    <b-row>
      <b-col class="col-6">
        <TextareaGroup
          id="doctorFormDetails"
          :label="$i18n.t('lb_others')"
          :placeholder="$i18n.t('lb_type_something')"
          @handlerTextareaChange="handleChangeDetails"
          :value="this.doctorData.details"
        />
      </b-col>
    </b-row>

    <div class="sp__v__1d5x" />

    <b-row>
      <b-col>
        <p class="text__formTitle">{{ $i18n.t('lb_contact_data')}}</p>
      </b-col>
    </b-row>
    <div class="sp__v__1d5x" />
    <b-row>
      <b-col class="col-6">
        <InputGroup
          id="doctorFormEmail"
          :label="$i18n.t('lb_email')"
          :placeholder="$i18n.t('lb_type_email')"
          @handlerInputChange="handleChangeEmail"
          :isValid="this.validationState.email"
          error="No puede estar vacio"
          :value="this.doctorData.email"
        />
      </b-col>
      <b-col class="col-6">
        <InputGroup
          id="doctorFormPhone"
          :label="$i18n.t('lb_phone')"
          :placeholder="$i18n.t('lb_type_phone')"
          @handlerInputChange="handleChangePhone"
          :value="this.doctorData.phone"
          type="tel"
        />
      </b-col>
    </b-row>
    <div class="sp__v" />
  </b-form>
  </ModalAction>
</template>

<script>
import ModalAction from '@/components/molecules/Modal/ModalAction.vue';
import InputGroup from '@/components/atoms/Input/InputGroup.vue';
import TextareaGroup from '@/components/atoms/Textarea/TextareaGroup.vue';
import {
  getValidationState,
  isValidForm,
  sanitizeEmail,
} from '@/helpers/ValidationHelper';
import FileUploader from '@/components/atoms/User/FileUploader/FileUploader.vue';
import Specialty from '@/components/atoms/Select/Specialty.vue';
import {
  getAvatarFile,
  getAvatarBase64,
  logout,
} from '@/helpers/UserHelper';
import SelectGroup from '@/components/atoms/Select/SelectGroup.vue';
import { LANGUAGE_SELECTION_OPTIONS } from '@/helpers/ComponentHelper';
// import SpecialtyComboSelector
//   from '@/components/molecules/SpecialtyComboSelector/SpecialtyComboSelector.vue';

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    doctor: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      doctorData: {
        id: this.doctor?.id,
        avatar: this.doctor?.avatar,
        name: this.doctor?.name,
        lastname: this.doctor?.lastname,
        specialty: this.doctor?.specialty,
        specialties: this.doctor?.specialties,
        hasSpecialties: this.doctor?.hasSpecialties,
        specialtyBadgeList: [],
        bio: this.doctor?.bio,
        experience: this.doctor?.experience,
        details: this.doctor?.details,
        email: this.doctor?.email,
        phone: this.doctor?.phone,
        defaultLanguage: this.doctor?.defaultLanguage,
      },
      validationState: {
        name: null,
        lastname: null,
        specialty: null,
        email: null,
      },
      languageOptions: LANGUAGE_SELECTION_OPTIONS,
      userEmail: null,
      isLoading: false,
    };
  },
  mounted() {
    this.userEmail = this.doctor?.email;
  },
  methods: {
    updateUser() {
      this.isLoading = true;

      const requestData = {
        id: this.doctorData.id,
        body: {
          ...(this.getAvatarBase(this.doctorData.avatar)),
          name: this.doctorData.name,
          lastname: this.doctorData.lastname,
          specialty: this.getSpecialty,
          specialties: this.doctorData.specialties,
          bio: this.doctorData.bio,
          experience: this.doctorData.experience,
          details: this.doctorData.details,
          email: this.doctorData.email,
          phone: this.doctorData.phone,
          defaultLanguage: this.doctorData.defaultLanguage,
        },
      };

      this.$store.dispatch('doctor/updateDoctor', requestData)
        .then(() => {
          this.isLoading = false;
          this.$emit('handleOk', this.doctorData);
          this.logoutUser();
        })
        .catch(() => {
          this.isLoading = false;
        });
    },

    handleOk() {
      this.setSanitizedEmail();

      const nameValidationState = getValidationState(
        this.doctorData.name,
      );

      const lastnameValidationState = getValidationState(
        this.doctorData.lastname,
      );

      const specialtyValidationState = getValidationState(
        this.getSpecialty,
      );

      const emailValidationState = getValidationState(
        this.doctorData.email,
      );

      if (isValidForm([
        nameValidationState,
        lastnameValidationState,
        specialtyValidationState,
        emailValidationState,
      ])) {
        this.updateUser();
      } else {
        this.validationState.name = nameValidationState;
        this.validationState.lastname = lastnameValidationState;
        this.validationState.specialty = specialtyValidationState;
        this.validationState.email = emailValidationState;
      }
    },

    handleCancel() {
      this.resetValidations();
      this.$emit('handleCancel');
    },

    handleChangeSpecialties(value) {
      this.doctorData.specialties = value.map((el) => {
        const specialtyId = {
          id: el.id,
        };
        return specialtyId;
      });
    },

    handleChangeHasSpecialty(value) {
      const boolValue = value === 'true';
      this.doctorData.hasSpecialties = boolValue;
      this.resetSpecialtyValues();
    },

    resetSpecialtyValues() {
      this.specialtyBadgeList = [];
      this.doctorData.specialties = [];
    },

    handleChangeImage(file) {
      this.doctorData.avatar = file;
    },

    handleChangeDefaultLanguage(value) {
      this.doctorData.defaultLanguage = value;
    },

    handleChangeName(text) {
      this.doctorData.name = text;
    },

    handleChangeSurname(text) {
      this.doctorData.lastname = text;
    },

    handleChangeSpecialty(value) {
      this.doctorData.specialty = value;
    },

    handleChangeBio(text) {
      this.doctorData.bio = text;
    },

    handleChangeExperience(value) {
      this.doctorData.experience = value;
    },

    handleChangeDetails(value) {
      this.doctorData.details = value;
    },

    handleChangeEmail(text) {
      this.doctorData.email = text;
    },

    handleChangePhone(text) {
      this.doctorData.phone = text;
    },

    resetValidations() {
      this.validationState.name = null;
      this.validationState.lastname = null;
      this.validationState.specialty = null;
      this.validationState.email = null;
    },

    getAvatarBase(avatar) {
      return getAvatarBase64(avatar);
    },

    logoutUser() {
      if (this.userEmail !== this.doctorData.email) {
        logout();
      }
    },

    setSanitizedEmail() {
      const sanitizedEmail = sanitizeEmail(this.doctorData.email);
      this.doctorData.email = sanitizedEmail;
    },
  },
  computed: {
    getAvatar() {
      return getAvatarFile(this.doctorData?.avatar);
    },

    getSpecialty() {
      return !(this.doctorData?.specialty && this.doctorData?.specialty.id)
        ? this.doctorData?.specialty
        : this.doctorData?.specialty.id;
    },
  },
  watch: {
    doctor(newValue) {
      this.doctorData.uuid = newValue.uuid;
      this.doctorData.avatar = newValue.avatar;
      this.doctorData.name = newValue.name;
      this.doctorData.lastname = newValue.lastname;
      this.doctorData.specialty = newValue.specialty;
      this.doctorData.bio = newValue.bio;
      this.doctorData.experience = newValue.experience;
      this.doctorData.details = newValue.details;
      this.doctorData.email = newValue.email;
      this.doctorData.phone = newValue.phone;
      this.doctorData.defaultLanguage = newValue.defaultLanguage;
    },
  },
  components: {
    // SpecialtyComboSelector,
    ModalAction,
    InputGroup,
    FileUploader,
    TextareaGroup,
    Specialty,
    SelectGroup,
  },
};
</script>
