import Vue from 'vue';
import Vuex from 'vuex';
import { ROLE } from '@/helpers/UserHelper';

Vue.use(Vuex);

export function getCurrentUser(state) {
  return state;
}

export function getSpecialties(state) {
  return state.specialties;
}

export function isAllowCollaborations(state) {
  return state.allowCollaborations;
}

export function isVidasprime(state) {
  return state.role === ROLE.VIDASPRIME;
}

export function isHealthManager(state) {
  return state.role === ROLE.HEALTH_MANAGER;
}

export function isClinic(state) {
  return state.role === ROLE.CLINIC;
}

export function isDoctor(state) {
  return state.role === ROLE.DOCTOR;
}

export function isDoctorOrClinic(state) {
  return state.role === ROLE.DOCTOR || state.role === ROLE.CLINIC;
}

export function isCollaborator(state) {
  return state.role === ROLE.COLLABORATOR_CLINIC || state.role === ROLE.COLLABORATOR_DOCTOR;
}

export function isCollaboratorClinic(state) {
  return state.role === ROLE.COLLABORATOR_CLINIC;
}

export function isClinicRole(state) {
  return state.role === ROLE.CLINIC || state.role === ROLE.COLLABORATOR_CLINIC;
}
export function isDoctorRole(state) {
  return state.role === ROLE.DOCTOR || state.role === ROLE.COLLABORATOR_DOCTOR;
}
