import Vue from 'vue';

export function setRequest(state, request) {
  state.request = request;
}

export function setRequestPagination(state, pagination) {
  state.request.pagination = pagination;
}

export function setRequestList(state, requestList) {
  state.request.requestList = requestList;
}

export function updateRequestStatus(state, req) {
  const rIndex = state.request.requestList.findIndex((item) => item.id === req.id);
  const mutatedReq = state.request.requestList[rIndex];
  mutatedReq.status = req.body.status;
  Vue.set(state.request.requestList, rIndex, mutatedReq);
}
