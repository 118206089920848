<template>
  <ModalAction
    :id="id"
    :okLabel="`${$i18n.t('btn_save')} ${$i18n.t('lb_clinic')}`"
    :cancelLabel="$i18n.t('btn_cancel')"
    :isLoading="isLoading"
    @handleOk="handleOk"
    @handleCancel="handleCancel"
    isForm
  >
  <b-form>
    <b-row>
      <b-col>
        <p class="text__formTitle">{{ title }}</p>
      </b-col>
    </b-row>

    <div class="sp__v" />

    <b-row>
      <b-col>
        <p class="text__psb">{{ $i18n.t('lb_clinic_logo')}}</p>
        <div class="sp__v" />
        <FileUploader
          @handleSetFile="handleChangeImage"
          isImage
          :image="this.getAvatar"
        />
        <div class="sp__v__1s5x" />
        <p class="text__sr text-secondary">{{ $i18n.t('cp_file_uploader_help') }}</p>
      </b-col>
    </b-row>

    <div class="sp__v__1d5x" />

    <b-row>
      <b-col class="col-6">
        <InputGroup
          id="clinicFormName"
          :label="$i18n.t('lb_clinic_name')"
          :placeholder="$i18n.t('lb_type_something')"
          @handlerInputChange="handleChangeName"
          :isValid="this.validationState.name"
          error="No puede estar vacio"
          :value="this.clinicData.name"
        />
      </b-col>
      <b-col class="col-6">
        <InputGroup
          id="clinicFormContactName"
          :label="$i18n.t('lb_contact_person')"
          :placeholder="$i18n.t('lb_type_something')"
          @handlerInputChange="handleChangeContactName"
          :isValid="this.validationState.contactName"
          error="No puede estar vacio"
          :value="this.clinicData.contactName"
        />
      </b-col>
    </b-row>

    <div class="sp__v__1d5x" />

    <b-row>
      <b-col class="col-6">
        <InputGroupAutocomplete
          id="clinicFormCity"
          :label="$i18n.t('lb_city')"
          :placeholder="$i18n.t('lb_type_something')"
          @handlerInputChange="handleChangeCity"
          @handlerSelectSuggestion="handleSelectSuggestion"
          :isValid="this.validationState.city"
          error="No puede estar vacio"
          :value="this.clinicData.city"
          :suggestions="cities"
          :showList="showList"
          :isLoading="isLoadingCities"
        />
      </b-col>
      <b-col class="col-6">
        <InputGroup
          id="clinicFormYearFoundation"
          :label="$i18n.t('lb_foundation_year')"
          :placeholder="$i18n.t('lb_type_year')"
          @handlerInputChange="handleChangeYearFoundation"
          :value="this.clinicData.yearFoundation"
          type="number"
        />
      </b-col>
    </b-row>

    <div class="sp__v__1d5x" />

    <b-row>
      <b-col class="col-6">
        <TextareaGroup
          id="clinicFormDescription"
          :label="$i18n.t('lb_description')"
          :placeholder="$i18n.t('lb_type_something')"
          @handlerTextareaChange="handleChangeDescription"
          :value="this.clinicData.description"
        />
      </b-col>
      <b-col class="col-6">
        <TextareaGroup
          id="clinicFormCredentials"
          :label="$i18n.t('lb_acreditations')"
          :placeholder="$i18n.t('lb_type_something')"
          @handlerTextareaChange="handleChangeCredentials"
          :value="this.clinicData.credentials"
        />
      </b-col>
    </b-row>

    <div class="sp__v__1d5x" />

      <InsuranceComboSelector
        @handleChangeInsurances="handleChangeInsurances"
        @handleChangeHasInsurance="handleChangeHasInsurance"
        :hasSelectedInsurances="this.clinicData.hasInsurances"
        :insurances="this.clinicData.insurances"
      />

<!--    <div class="sp__v__1d5x" />-->

<!--      <SpecialtyComboSelector-->
<!--        @handleChangeSpecialties="handleChangeSpecialties"-->
<!--        @handleChangeHasSpecialty="handleChangeHasSpecialty"-->
<!--        :hasSelectedSpecialties="true"-->
<!--        :specialties="this.clinicData.specialties"-->
<!--      />-->

    <div class="sp__v__1d5x" />

    <b-row>
      <b-col class="col-6">
        <InputGroup
          id="clinicFormGoogleMyBusinessAccount"
          :label="$i18n.t('lb_google_mybusiness_account')"
          :placeholder="$i18n.t('lb_type_something')"
          @handlerInputChange="handleChangeGoogleMyBusinessAccount"
          :value="this.clinicData.googleMyBusinessAccount"
        />
      </b-col>
      <b-col class="col-6">
        <InputGroup
          id="firstPrice"
          :label="$i18n.t('lb_first_price')"
          :placeholder="$i18n.t('lb_type_something')"
          @handlerInputChange="handleChangeFirstPrice"
          :value="this.clinicData.firstPrice"
          type="number"
          :min="0"
        />
      </b-col>
    </b-row>

    <div class="sp__v__1d5x" />

    <b-row>
      <b-col class="col-6">
<!-- this.clinicData.defaultLanguage -->
        <SelectGroup
          id="defaultLanguage"
          :label="$i18n.t('lb_default_language')"
          :selectedOption="getDefaultLanguage()"
          :options="languageOptions"
          @handlerSelect="handleChangeDefaultLanguage"
        />
      </b-col>
    </b-row>
    <div class="sp__v__1d5x" /><div class="sp__v__1d5x" />
    <b-row>
      <b-col>
        <span class="text__formTitle">{{ $i18n.t('lb_collaboration')}}</span>
      </b-col>
    </b-row>
    <div class="sp__v__1d5x" />
    <b-row>
      <b-col class="col-6">
        <Checkbox
          id="allowCollaborations"
          :label="$i18n.t('cp_enable_collaborations')"
          :description="$i18n.t('cp_enable_collaborations_sub')"
          :value="this.clinicData.allowCollaborations"
          @handleChange="handleChangeCollaboration"
          isSwitch
        />
      </b-col>
    </b-row>
    <div class="sp__v__1d5x" /><div class="sp__v__1d5x" />
    <b-row>
      <b-col>
        <p class="text__formTitle">{{ $i18n.t('lb_contact_data')}}</p>
      </b-col>
    </b-row>
    <div class="sp__v__1d5x" />
    <b-row>
      <b-col class="col-6">
        <InputGroup
          id="clinicFormEmail"
          :label="$i18n.t('lb_email')"
          :placeholder="$i18n.t('lb_type_email')"
          @handlerInputChange="handleChangeEmail"
          :isValid="this.validationState.email"
          error="No puede estar vacio"
          :value="this.clinicData.email"
        />
      </b-col>
      <b-col class="col-6">
        <InputGroup
          id="clinicFormPhone"
          :label="$i18n.t('lb_phone')"
          :placeholder="$i18n.t('lb_type_phone')"
          @handlerInputChange="handleChangePhone"
          :isValid="this.validationState.phone"
          :error="$i18n.t('val_phone')"
          :value="this.clinicData.phone"
          type="tel"
        />
      </b-col>
    </b-row>
    <div class="sp__v" />
    <hr />
    <div class="sp__v" />
    <b-row>
      <b-col class="col-6">
        <PasswordChange />
      </b-col>
    </b-row>
  </b-form>
  </ModalAction>
</template>

<script>
import { mapGetters } from 'vuex';
import ModalAction from '@/components/molecules/Modal/ModalAction.vue';
import InputGroup from '@/components/atoms/Input/InputGroup.vue';
import TextareaGroup from '@/components/atoms/Textarea/TextareaGroup.vue';
import Checkbox from '@/components/atoms/Checkbox/Checkbox.vue';
import FileUploader from '@/components/atoms/User/FileUploader/FileUploader.vue';
import {
  getValidationState,
  isValidForm,
  getPhoneValidationState,
  sanitizeEmail,
} from '@/helpers/ValidationHelper';
import { BOOLEAN_SELECTION_OPTIONS, MIN_SEARCH__LENGTH, LANGUAGE_SELECTION_OPTIONS } from '@/helpers/ComponentHelper';
import {
  getAvatarFile,
  getAvatarBase64,
  logout,
} from '@/helpers/UserHelper';
import PasswordChange from '@/components/molecules/PasswordChange/PasswordChange.vue';
import InputGroupAutocomplete from '@/components/atoms/Input/InputGroupAutocomplete.vue';
import InsuranceComboSelector from '@/components/molecules/InsuranceComboSelector/InsuranceComboSelector.vue';
import SelectGroup from '@/components/atoms/Select/SelectGroup.vue';
// import SpecialtyComboSelector
//   from '@/components/molecules/SpecialtyComboSelector/SpecialtyComboSelector.vue';

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    clinic: {
      type: Object,
    },
  },
  data() {
    return {
      clinicData: {
        avatar: this.clinic?.avatar,
        city: this.clinic?.city,
        contactName: this.clinic?.contactName,
        credentials: this.clinic?.credentials,
        defaultLanguage: this.clinic?.defaultLanguage,
        description: this.clinic?.description,
        allowCollaborations: this.clinic?.allowCollaborations,
        email: this.clinic?.email,
        firstPrice: this.clinic?.firstPrice,
        googleMyBusinessAccount: this.clinic?.googleMyBusinessAccount,
        hasInsurances: this.clinic?.hasInsurances,
        hasSpecialties: this.clinic?.hasSpecialties,
        id: this.clinic?.uuid,
        insurances: this.clinic?.insurances,
        name: this.clinic?.name,
        phone: this.clinic?.phone,
        specialties: this.clinic?.specialties,
        yearFoundation: this.clinic?.yearFoundation,
      },
      validationState: {
        city: null,
        contactName: null,
        email: null,
        name: null,
        phone: null,
      },
      booleanOptions: BOOLEAN_SELECTION_OPTIONS,
      cities: [],
      insuranceBadgeList: [],
      insuranceSelected: [],
      insuranceSelectedInfo: null,
      isLoading: false,
      isLoadingCities: false,
      languageOptions: LANGUAGE_SELECTION_OPTIONS,
      showList: false,
      specialtyBadgeList: [],
      userEmail: null,
    };
  },
  mounted() {
    this.userEmail = this.clinic?.email;
  },
  methods: {
    getDefaultLanguage() {
      return this.clinicData.defaultLanguage;
    },
    loadCities() {
      this.isLoadingCities = true;
      this.$store.dispatch('cities/getAllCities', this.searchText)
        .then((res) => {
          this.cities = res;
          this.isLoadingCities = false;
        })
        .catch(() => {
          this.isLoadingCities = false;
        });
      this.showList = this.cities.length >= 1;
    },

    handleChangeDefaultLanguage(value) {
      this.clinicData.defaultLanguage = value;
    },

    updateUser() {
      this.isLoading = true;

      const requestData = {
        id: this.clinicData.id,
        body: {
          ...(this.getAvatarBase(this.clinicData.avatar)),
          city: this.clinicData.city,
          contactName: this.clinicData.contactName,
          credentials: this.clinicData.credentials,
          defaultLanguage: this.clinicData.defaultLanguage,
          description: this.clinicData.description,
          allowCollaborations: this.clinicData.allowCollaborations,
          email: this.clinicData.email,
          firstPrice: this.clinicData.firstPrice,
          googleMyBusinessAccount: this.clinicData.googleMyBusinessAccount,
          hasInsurances: this.clinicData.hasInsurances,
          insurances: this.clinicData.insurances,
          name: this.clinicData.name,
          phone: this.clinicData.phone,
          specialties: this.clinicData.specialties,
          yearFoundation: this.clinicData.yearFoundation,
        },
      };

      this.$store.dispatch('clinic/updateClinic', requestData)
        .then(() => {
          this.isLoading = false;
          this.$emit('handleOk', this.clinicData);
          this.logoutUser();
        })
        .catch(() => {
          this.isLoading = false;
        });
    },

    handleOk() {
      this.setSanitizedEmail();

      const nameValidationState = getValidationState(
        this.clinicData.name,
      );
      const contactNameValidationState = getValidationState(
        this.clinicData.contactName,
      );
      const cityValidationState = getValidationState(
        this.clinicData.city,
      );
      const emailValidationState = getValidationState(
        this.clinicData.email,
      );
      const phoneValidationState = getPhoneValidationState(
        this.clinicData.phone,
      );
      if (isValidForm([
        nameValidationState,
        contactNameValidationState,
        cityValidationState,
        emailValidationState,
        phoneValidationState,
      ])) {
        this.updateUser();
      } else {
        this.validationState.name = nameValidationState;
        this.validationState.contactName = contactNameValidationState;
        this.validationState.city = cityValidationState;
        this.validationState.email = emailValidationState;
        this.validationState.phone = phoneValidationState;
      }
    },

    setSanitizedEmail() {
      const sanitizedEmail = sanitizeEmail(this.clinicData.email);
      this.clinicData.email = sanitizedEmail;
    },

    handleCancel() {
      this.resetValidations();
      this.$emit('handleCancel');
    },

    handleChangeFirstPrice(value) {
      this.clinicData.firstPrice = Math.abs(value);
    },

    handleChangeImage(file) {
      this.clinicData.avatar = file;
    },

    handleChangeName(text) {
      this.clinicData.name = text;
    },

    handleChangeContactName(text) {
      this.clinicData.contactName = text;
    },

    handleChangeYearFoundation(value) {
      this.clinicData.yearFoundation = value;
    },

    handleChangeDescription(value) {
      this.clinicData.description = value;
    },

    handleChangeCredentials(value) {
      this.clinicData.credentials = value;
    },

    handleChangeCollaboration(value) {
      this.clinicData.allowCollaborations = value;
    },

    handleChangeGoogleMyBusinessAccount(value) {
      this.clinicData.googleMyBusinessAccount = value;
    },

    handleChangeHasInsurance(value) {
      const boolValue = value === 'true';
      this.clinicData.hasInsurances = boolValue;
      this.resetInsuranceValues();
    },

    handleChangeHasSpecialty(value) {
      const boolValue = value === 'true';
      this.clinicData.hasSpecialties = boolValue;
      this.resetSpecialtyValues();
    },

    handleChangeInsurances(value) {
      this.clinicData.insurances = value.map((el) => {
        const insuranceId = {
          id: el.id,
        };
        return insuranceId;
      });
    },

    handleChangeSpecialties(value) {
      this.clinicData.specialties = value.map((el) => {
        const specialtyId = {
          id: el.id,
        };
        return specialtyId;
      });
    },

    handleAddInsurance() {
      this.clinicData.insurances.push(this.insuranceSelected);
      this.insuranceBadgeList.push(this.insuranceSelectedInfo);
      const updatedList = this.removeDuplicates(this.clinicData.insurances);
      const updatedBadgeList = this.removeDuplicates(this.insuranceBadgeList);
      this.clinicData.insurances = updatedList;
      this.insuranceBadgeList = updatedBadgeList;
    },

    handleChangeEmail(text) {
      this.clinicData.email = text;
    },

    handleChangePhone(text) {
      this.clinicData.phone = text;
    },

    handleDeleteInsurance(index) {
      this.insuranceBadgeList.splice(index, 1);
      this.clinicData.insurances.splice(index, 1);
    },

    handleChangeCity(value) {
      if (value?.length >= MIN_SEARCH__LENGTH) {
        this.searchText = value;
        this.loadCities();
      }
      if (!value) {
        this.cities = [];
      }
    },

    handleSelectSuggestion(value) {
      this.clinicData.city = value;
      this.cities = [];
    },

    resetValidations() {
      this.validationState.name = null;
      this.validationState.contactName = null;
      this.validationState.city = null;
      this.validationState.email = null;
      this.validationState.phone = null;
    },

    resetInsuranceValues() {
      this.insuranceBadgeList = [];
      this.clinicData.insurances = [];
    },

    resetSpecialtyValues() {
      this.specialtyBadgeList = [];
      this.clinicData.specialties = [];
    },

    removeDuplicates(list) {
      return [...new Set(list)];
    },

    getInsuranceInfo(id) {
      return this.insurance.find((el) => el.id === id);
    },

    getAvatarBase(avatar) {
      return getAvatarBase64(avatar);
    },

    logoutUser() {
      if (this.userEmail !== this.clinicData.email) {
        logout();
      }
    },
  },

  computed: {
    ...mapGetters('insurance', {
      insurance: 'getInsurances',
      hasInsurances: 'hasInsurances',
    }),

    getInsuranceOptions() {
      const newInsurances = this.insurance.map((insurance) => {
        const newInsurance = {
          text: insurance.name,
          value: insurance.id,
        };
        return newInsurance;
      });
      return newInsurances;
    },

    getAvatar() {
      return getAvatarFile(this.clinicData?.avatar);
    },
  },
  watch: {
    clinic(newValue) {
      this.clinicData.id = newValue.id;
      this.clinicData.avatar = newValue.avatar;
      this.clinicData.name = newValue.name;
      this.clinicData.contactName = newValue.contactName;
      this.clinicData.city = newValue.city;
      this.clinicData.yearFoundation = newValue.yearFoundation;
      this.clinicData.description = newValue.description;
      this.clinicData.credentials = newValue.credentials;
      this.clinicData.hasInsurances = newValue.hasInsurances;
      this.clinicData.insurances = newValue.insurances;
      this.clinicData.googleMyBusinessAccount = newValue.googleMyBusinessAccount;
      this.clinicData.email = newValue.email;
      this.clinicData.phone = newValue.phone;
      this.clinicData.firstPrice = newValue.firstPrice;
      this.clinicData.defaultLanguage = newValue.defaultLanguage;
      this.clinicData.specialties = newValue.specialties;
      this.clinicData.allowCollaborations = newValue.allowCollaborations;
    },
  },
  components: {
    // SpecialtyComboSelector,
    Checkbox,
    FileUploader,
    InputGroup,
    InputGroupAutocomplete,
    InsuranceComboSelector,
    ModalAction,
    PasswordChange,
    SelectGroup,
    TextareaGroup,
  },
};
</script>
