<template>
  <PatientForm
    id="update-patient"
    ref="updatePatientForm"
    :title="$i18n.t('lb_update_patient')"
    :patient="getPatient"
    :isLoading="isLoading"
    @handleOk="update"
  />
</template>

<script>
import moment from 'moment';
import PatientForm from './PatientForm.vue';

export default {
  props: {
    patient: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    update(data) {
      const requestData = {
        id: data.id,
        body: {
          name: data.name,
          idCrm: data.idCrm,
          lastname: data.lastname,
          gender: data.gender,
          origin: data.origin?.id,
          dni: data.dni,
          dob: this.getDob(data.dob),
          tutor: data.tutor,
          hasInsurance: data.hasInsurance,
          insurance: data.insurance,
          hospital: data.hospital,
          email: data.email,
          phone: data.phone,
          contactWhatsApp: data.contactWhatsApp,
        },
      };

      this.isLoading = true;

      this.$store.dispatch('patient/updatePatient', requestData)
        .then(() => {
          this.$emit('updatePatient', data);
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },

    getDob(dob) {
      return moment(String(dob)).format('YYYY-MM-DD');
    },
  },
  computed: {
    getPatient() {
      return this.patient;
    },
  },
  components: {
    PatientForm,
  },
};
</script>
