import InsightsAPI from '../../domain/insights/InsightsApi';

export function getInsights({ commit }, data) {
  const insightsApi = new InsightsAPI();
  const getInsightsRequest = insightsApi.getInsights(data);

  return getInsightsRequest
    .then((res) => res)
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function setLoginError() {}
