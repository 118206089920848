<template>
  <div class="Badge">
    <span class="badge badge-pill" v-bind:class="`badge-${this.variantString}`">
      <slot></slot>
    </span>
  </div>
</template>

<script>
import {
  STATUS,
} from '@/helpers/ComponentHelper';

export default {
  props: {
    variant: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      variantString: this.getVariant(this.variant),
    };
  },
  watch: {
    variantString(newValue) {
      this.variantString = this.getVariant(newValue);
    },
    variant(newValue) {
      this.variantString = this.getVariant(newValue);
    },
  },
  methods: {
    getVariant(data) {
      switch (data) {
        case STATUS.CANCELED.key:
          return STATUS.DANGER.key;

        default:
          return data;
      }
    },
  },
};
</script>

<style lang="sass" scoped>
  @import './Badge.sass'
</style>
