import DoctorModel from './DoctorModel';
import PaginationFactory from '../pagination/PaginationFactory';
import UserModel from '../user/UserModel';

class DoctorFactory {
  constructor() {
    this.doctor = {};
  }

  getAllDoctor(data) {
    const doctorResponse = {
      doctorList: this.getCollection(data?.collection),
      pagination: PaginationFactory.getPagination(data?.pagination),
    };

    return doctorResponse;
  }

  getDoctor(doctor) {
    const user = new UserModel({
      id: doctor.user.id,
      name: doctor.user.name,
      email: doctor.user.email,
      roles: doctor.user.roles,
      avatar: doctor.user.avatar,
      defaultLanguage: doctor.user.defaultLanguage,
    });

    this.doctor = new DoctorModel({
      id: doctor.id,
      avatar: user?.avatar,
      name: doctor.name,
      lastname: doctor.lastname,
      specialty: doctor.specialty,
      bio: doctor.bio,
      experience: doctor.experience,
      details: doctor.details,
      email: user?.email,
      phone: doctor.phone,
      specialties: doctor.specialties,
      defaultLanguage: user?.defaultLanguage,
    });

    return this.doctor;
  }

  getCollection(doctorCollection) {
    const doctorList = doctorCollection.map(
      (doctor) => this.getDoctor(doctor),
    );
    return doctorList;
  }
}
export default new DoctorFactory();
