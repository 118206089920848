import UserApi from '../../domain/user/UserApi';

export function resetPassword({ commit }, resetData) {
  const userApi = new UserApi();
  const apiAddUser = userApi.resetPassword(resetData);

  return apiAddUser
    .then()
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function getUser({ commit }, data) {
  const userApi = new UserApi();
  const getRequest = userApi.getUser(data);
  commit('ui/setIsUserLoading', true, { root: true });
  return getRequest
    .then((res) => {
      commit('user/setUser', res, { root: true });
      commit('ui/setIsUserLoading', false, { root: true });
      return res;
    })
    .catch((error) => {
      commit('error/setError', error, { root: true });
      commit('ui/setIsUserLoading', false, { root: true });
      return Promise.reject(error);
    });
}

export function updateUser({ commit }, data) {
  const userApi = new UserApi();
  const updateRequest = userApi.updateUser(data);

  return updateRequest
    .then((res) => {
      commit('user/setUser', res, { root: true });
      return res;
    })
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}
