export default class NotificationModel {
  constructor(data) {
    this.id = data.id;
    this.createdAt = data.createdAt;
    this.isDeleted = data.isDeleted;
    this.message = data.message;
    this.request = data.request;
    this.type = data.type;
  }
}
