<template>
    <div>
        <RequestDesktopView v-if="!isMobile"/>
        <RequestMobileView v-if="isMobile"/>
    </div>
</template>
<script>
import DEVICE from '../../helpers/ResponsiveHelper';

export default {
  data: () => ({
    isMobile: false,
  }),
  mounted() {
    const resizeObserver = new ResizeObserver((entries) => {
      const [element] = entries;
      this.isMobile = element.contentRect.width < DEVICE.MOBILE_WIDTH;
      this.$store.commit('responsive/setIsMobile', this.isMobile);
    });

    resizeObserver.observe(this.$el);
  },
  components: {
    RequestDesktopView: () => import('@/views/Request/RequestDesktop/RequestDesktopView.vue'),
    RequestMobileView: () => import('@/views/Request/RequestMobile/RequestMobileView.vue'),
  },
};
</script>
