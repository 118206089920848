import {
  findClinics,
  createInvitation,
  cancelInvitation,
  getInvitations,
  acceptInvitation,
  rejectInvitation,
} from './invitationsActions';

import {
  getInvitationState,
  getInvitationPaginationState,
  getInvitationListState,
} from './invitationsGetters';

import {
  setClinics,
  setInvitations,
  setInvitationPagination,
  setInvitationList,
  updateInvitationStatus,
} from './invitationMutations';

const invitations = {
  namespaced: true,
  state: () => ({
    invitations: {
      invitationList: [],
      pagination: {},
    },
  }),
  actions: {
    findClinics,
    createInvitation,
    cancelInvitation,
    acceptInvitation,
    rejectInvitation,
    getInvitations,
  },
  mutations: {
    setClinics,
    setInvitations,
    setInvitationPagination,
    setInvitationList,
    updateInvitationStatus,
  },
  getters: {
    getInvitationState,
    getInvitationPaginationState,
    getInvitationListState,
  },
};

export default invitations;
