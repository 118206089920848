<template>
  <div class="ButtonComboAction">
    <button
      class="ButtonComboAction__button"
      @click.prevent="handleCopy"
    >
      <img src="@/assets/actions/copy.svg" />
    </button>
    <button
      class="ButtonComboAction__button ButtonComboAction__leftBorder"
      @click.prevent="handleDelete"
    >
      <img src="@/assets/actions/delete.svg" />
    </button>
  </div>
</template>

<script>

export default {
  props: {},
  methods: {
    handleCopy() {},
    handleDelete() {},
  },
};
</script>

<style lang="sass" scoped>
  @import './ButtonComboAction.sass'
</style>
