export default class SurveyTypeModel {
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.totalSurveys = data.totalSurveys;
    this.answered = data.answered;
    this.name = data.name;
    this.templatePath = data.templatePath;
    this.createdByData = data.createdByData;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
    this.deletedAt = data.deletedAt;
    this.language = data.language;
    this.surveyTypesPartialsTypes = data.surveyTypesPartialsTypes ?? [];
  }
}
