<template>
  <div class="bg-white p-2 w-full border rounded">
    <b-button
      @click.stop="handleCreate()"
      variant="primary"
      size="sm"
      class="mb-2"
      ref="button"
    >
      + Añadir cita
    </b-button>
    <div class="Table Table__list">
      <b-table
        thead-class="d-none"
        :items="items"
        :fields="columns"
        fixed
      >
        <template #cell(info)="data">
          <p class="text__psbb text-dark ellipsis">{{
              new Date(data.item.date).toLocaleDateString()
            }} {{ new Date(data.item.date).toLocaleTimeString() }}
</p>
        </template>
        <template #cell(actions)="data">
          <div class="Table__list__actions">
            <vc-date-picker color="purple" mode="dateTime"
                            v-model="data.item.date"
                            @input="handleUpdate"
                            @popoverDidHide="removeSelected">
              <template #default="{ togglePopover }">
                <div class="flex flex-wrap">
                  <b-button
                    class="btn btn-secondary"
                    @click.stop="dateSelected($event, data.item, togglePopover)"
                  >
                    <img src="@/assets/actions/edit.svg"/>
                  </b-button>
                </div>
              </template>
            </vc-date-picker>
            <div class="sp__1s5x"/>
            <b-button
                variant="secondary"
                @click.prevent="handleShowDetail(data.item)"
              >
              <img src="@/assets/actions/preview.svg" />
            </b-button>
            <div class="sp__1s5x"/>

            <b-button
              @click.stop="handleDelete(data.item.id)"
              variant="outline-primary"
            >
              <b-spinner
              v-if="isLoading"
                style="width: 1rem; height: 1rem;"
                :variant="variant"
                label=""
              />
                <inline-svg
                v-if="!isLoading"
                class="icon__primary"
                :src="require('@/assets/actions/delete.svg')"
              />
            </b-button>
          <div class="sp__1s5x"/>
             <b-form-checkbox
                :id="data.item.id"
                v-model="data.item.isConfirmed"
                :name="`checkbox-${data.item.id}`"
                @change="handleUpdateAssist(data.item)"
                size="lg"
                />
            </div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>

import { uuid } from 'vue-uuid';
import moment from 'moment';

export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    requestID: {
      type: String,
      default: null,
    },
    canDelete: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isLoadingCreate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dates: [],
      selected: null,
      isConfirmed: false,
      attributes: [
        {
          highlight: {
            style: {
              background: '#4E20E4',
            },
            contentStyle: {
              color: 'white',
            },
          },
        },
      ],
    };
  },
  beforeMount() {
    this.items.sort((a, b) => new Date(b.date) - new Date(a.date));
  },
  computed: {
    columns() {
      return [
        { key: 'info', label: 'nombre', sortable: true },
        { key: 'actions', label: '' },
      ];
    },
    user() {
      return this.$store.getters['user/getCurrentUser'];
    },
  },
  methods: {
    handleShowDetail(appointment) {
      const data = {
        ...appointment,
        requestId: this.requestID,
      };
      this.$emit('handleShowDetail', data);
    },
    handleUpdateAssist(data) {
      const appointment = {
        requestId: this.requestID,
        appointmentId: data.id,
        date: data.date,
        comment: data.comment,
        isConfirmed: data.isConfirmed,
      };

      this.$emit('handleUpdate', appointment);
    },
    handleUpdate(day) {
      if (this.selected && !this.isLoading && !this.isLoadingCreate) {
        const appointment = {
          requestId: this.requestID,
          appointmentId: this.selected,
          date: moment(day).format(),
          comment: '',
          isConfirmed: this.isConfirmed,
        };

        this.$emit('handleUpdate', appointment);
      }
    },
    handleCreate() {
      if (!this.isLoadingCreate) {
        const appointment = {
          appointment: {
            id: uuid.v4(),
            request: this.requestID,
            createdBy: this.user.uuid,
            comment: '',
            isConfirmed: false,
            date: moment().format(),
          },
        };
        this.$emit('handleCreate', appointment);
      }
    },
    handleDelete(id) {
      if (!this.isLoading) {
        this.$emit('handleDelete', id);
      }
    },
    removeSelected() {
      this.selected = null;
    },
    dateSelected(e, appointment, toggle) {
      this.selected = appointment.id;
      this.isConfirmed = appointment.isConfirmed;
      toggle({ ref: e.target });
    },
  },
};
</script>

<style lang="sass" scoped>

@import '../Table.sass'
@import "@/style/colors.sass"
@import "@/style/borders.sass"

.flex
  display: flex

.flex-wrap
  flex-wrap: wrap

.button-date
  justify-items: center
  border-radius: 4px
  border-color: transparent
  background-color: $color__primary
  color: white
  display: flex
  justify-content: center
  align-items: center
  font-weight: 500
  height: 30px
  width: 110px
  margin-bottom: 8px
  margin-right: 5px
  padding: 0 5px
  border-width: 2px

  &:hover
    opacity: .75

.button-calendar-icon
  width: 20px
  height: 45px
  color: white
  margin-left: 4px
  margin-right: -2px

  &:hover
    color: $color__primary__dark
</style>
