import StorageManager from './StorageManager';
import emptyAvatar from '@/assets/user/empty-avatar-dark.svg';

export const ROLE = {
  VIDASPRIME: 'ROLE_VIDASPRIME',
  HEALTH_MANAGER: 'ROLE_HEALTH_MANAGER',
  CLINIC: 'ROLE_CLINIC',
  DOCTOR: 'ROLE_DOCTOR',
  COLLABORATOR_CLINIC: 'ROLE_COLLABORATOR_CLINIC',
  COLLABORATOR_DOCTOR: 'ROLE_COLLABORATOR_DOCTOR',
};

export const GENDER = {
  MALE: {
    key: 'm',
    value: 'Hombre',
  },
  FEMALE: {
    key: 'f',
    value: 'Mujer',
  },
};

export function getRole(roles) {
  if (roles && roles.length > 0) {
    return roles[0];
  }

  return null;
}

export const GENDER_OPTIONS = [
  {
    value: null,
    text: 'Selecciona género',
  },
  {
    value: GENDER.MALE.key,
    text: GENDER.MALE.value,
  },
  {
    value: GENDER.FEMALE.key,
    text: GENDER.FEMALE.value,
  },
];

export function getGender(value) {
  if (value === GENDER.MALE.key) {
    return GENDER.MALE.value;
  }

  return GENDER.FEMALE.value;
}

export function parseJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => `%${(`00 ${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));

  return JSON.parse(jsonPayload);
}

export function getUserData(token) {
  return parseJwt(token);
}

export function logout() {
  StorageManager.removeLocalData();
  window.location.href = '/';
}

export function getBase64Content(base64) {
  const base64Array = base64.split(',');

  return base64Array.length !== 1 ? base64Array[1] : null;
}

export function getExtension(name) {
  const extension = name.split('.');

  return extension.length > 0 ? extension[extension.length - 1] : null;
}

export function getAvatarUrl(avatar) {
  return `${process.env.VUE_APP_API_URL}${avatar.path}/${avatar.image}`;
}

export function getAvatarIcon(avatar) {
  if (avatar) {
    if (typeof avatar === 'string') {
      return avatar;
    }

    return typeof avatar === 'object' ? getAvatarUrl(avatar) : avatar;
  }

  return null;
}

export function getAvatarBase64(avatar) {
  const key = 'base64';

  if (avatar && Object.prototype.hasOwnProperty.call(avatar, key)) {
    Object.assign(avatar.base64, { mimeType: avatar.file.type });

    return { avatar: avatar.base64 };
  }

  return null;
}

export function getAvatarFile(avatar) {
  const key = 'file';

  if (
    avatar
    && Object.prototype.hasOwnProperty.call(avatar, key)
  ) {
    return avatar.file;
  }

  return avatar !== null
    && typeof avatar === 'object'
    ? getAvatarUrl(avatar)
    : avatar;
}

export function getAvatarByProps(avatar) {
  if (avatar === null) {
    return null;
  }

  if (Object.prototype.hasOwnProperty.call(avatar, 'file')) {
    return URL.createObjectURL(avatar.file);
  }

  if (Object.prototype.hasOwnProperty.call(avatar, 'path')) {
    return getAvatarUrl(avatar);
  }

  return avatar;
}

export function getAvatarByPath(avatar) {
  const key = 'path';

  if (
    avatar?.avatar
    && Object.prototype.hasOwnProperty.call(avatar.avatar, key)
  ) {
    return avatar?.avatar !== undefined
      ? getAvatarUrl(avatar.avatar) : emptyAvatar;
  }

  return avatar?.user?.avatar !== undefined
    ? getAvatarUrl(avatar.user.avatar) : emptyAvatar;
}

export function getIconRequest(avatar) {
  if (avatar !== null && typeof avatar !== 'undefined') {
    return getAvatarUrl(avatar);
  }

  return null;
}

export function getAvatarByPathRequest(avatar) {
  if (!avatar) {
    return emptyAvatar;
  }

  if (avatar.avatar === null || undefined) {
    return emptyAvatar;
  }

  if ((avatar.user && avatar.user.avatar) === null || undefined) {
    return emptyAvatar;
  }

  const key = 'path';

  if (
    avatar?.avatar
    && Object.prototype.hasOwnProperty.call(avatar.avatar, key)
  ) {
    return getIconRequest(avatar.avatar);
  }

  return avatar.user && avatar.user.avatar
    ? getIconRequest(avatar.user.avatar)
    : emptyAvatar;
}

export function checkEmailFormat(id, email) {
  const defaultFormat = `${id}@patient`;

  return !email.includes(defaultFormat);
}

export function isDefaultEmailPatient(email) {
  const patientEmail = ['@paciente.vidasprime', '@patient.vidasprime'];

  return patientEmail.find((v) => email.includes(v)) !== undefined;
}
