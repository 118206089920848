<template>
  <div class="OnboardingView">
    <div class="OnboardingView__header">
      <span>{{telephone}}</span>
    </div>
    <div class="OnboardingView__formContainer">
        <img
        src="@/assets/logos/logo_inline.svg"
        class="OnboardingView__logo"
        />
      <div
          v-bind:class="{
            'OnboardingView__main':!isMobile,
            'OnboardingView__mainMobile':isMobile
          }"
          v-if="!isSent"
      >
        <div class="OnboardingView__text">
          <h1>{{$i18n.t('cp_onboarding_title')}}</h1>
          <p>
            {{$i18n.t('cp_onboarding_description')}}
          </p>
          <div class="OnboardingView__list">
            <div class="OnboardingView__item">
              <img
                src="@/assets/actions/done.svg"
              />
              <p>{{$i18n.t('cp_onboarding_list_1')}}</p>
            </div>
            <div class="OnboardingView__item">
              <img
                src="@/assets/actions/done.svg"
              />
              <p>{{$i18n.t('cp_onboarding_list_2')}}</p>
            </div>
            <div class="OnboardingView__item">
              <img
                src="@/assets/actions/done.svg"
              />
              <p>{{$i18n.t('cp_onboarding_list_3')}}</p>
            </div>
          </div>
        </div>
        <div class="OnboardingView__form">
          <OnboardingForm  v-on:collaborationClinicCreated="handleCollaborationClinicCreated"/>
        </div>
      </div>
      <div  class="OnboardingView__sent" v-else>
        <div>
          <div class="OnboardingView__done">
            <img
            src="@/assets/actions/done-circle.svg"
            />
          </div>
          <div class="OnboardingView__text OnboardingView__text--p-center">
            <h1>{{$i18n.t('cp_onboarding_successfull_register_title')}}</h1>
            <p>
              {{$i18n.t('cp_onboarding_successfull_register_description')}}
            </p>
          </div>
        </div>
        <div class="OnboardingView__buttons">
          <a class="btn btn-outline-primary" :href="contactUsLink">
            {{$i18n.t('cp_contact_us')}}
          </a>
          <a class="btn btn-primary" :href="goToWebsiteLink">
            {{$i18n.t('cp_go_to_site')}}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OnboardingForm from '../../components/organisms/Onboarding/OnboardingForm.vue';
import { VIDASPRIME_TELEPHONE, CONTACT_US_LINK, GO_TO_WEBSITE_LINK } from '../../helpers/ComponentHelper';
import DEVICE from '../../helpers/ResponsiveHelper';

export default {
  name: 'OnboardingView',
  components: {
    OnboardingForm,
  },
  data() {
    return {
      telephone: VIDASPRIME_TELEPHONE,
      contactUsLink: CONTACT_US_LINK,
      goToWebsiteLink: GO_TO_WEBSITE_LINK,
      isSent: false,
    };
  },
  mounted() {
    /* TODO: VP-14 REPLICAR EN las páginas de login */
    const resizeObserver = new ResizeObserver((entries) => {
      const [element] = entries;
      const isMobile = element.contentRect.width < DEVICE.MOBILE_WIDTH;
      this.$store.commit('responsive/setIsMobile', isMobile);
    });

    resizeObserver.observe(this.$el);
  },
  methods: {
    handleCollaborationClinicCreated() {
      this.isSent = true;
    },
  },
  computed: {
    /* TODO: VP-14 EXAMPLE HOW TO USE IT. REMOVE IT */
    isMobile() {
      return this.$store.getters['responsive/isMobile'];
    },
  },
};
</script>

<style lang="sass" scoped>
@import './OnboardingView.sass'
</style>
