<template>
  <ModalAction
    :id="id"
    :okLabel="`${$i18n.t('btn_save')} ${$i18n.t('lb_patient')}`"
    :cancelLabel="$i18n.t('btn_cancel')"
    :isLoading="isLoading"
    @handleOk="handleOk"
    @handleCancel="handleCancel"
    isForm
  >
  <b-form>
    <b-row>
      <b-col>
        <p class="text__formTitle">{{ title }}</p>
      </b-col>
    </b-row>

    <div class="sp__v" />

    <b-row>
      <b-col class="col-6">
        <InputGroup
          id="patientFormName"
          :label="$i18n.t('lb_name')"
          :placeholder="$i18n.t('lb_type_something')"
          @handlerInputChange="handleChangeName"
          :isValid="this.validationState.name"
          error="No puede estar vacio"
          :value="this.patientData.name"
        />
      </b-col>
      <b-col class="col-6">
        <InputGroup
          id="patientFormSurname"
          :label="$i18n.t('lb_lastname')"
          :placeholder="$i18n.t('lb_type_something')"
          @handlerInputChange="handleChangeSurname"
          :isValid="this.validationState.lastname"
          error="No puede estar vacio"
          :value="this.patientData.lastname"
        />
      </b-col>
    </b-row>

    <div class="sp__v__1d5x" />

    <b-row>
      <b-col class="col-6">
        <GenderSelector
          id="patientFormGender"
          @handleChangeGender="handleChangeGender"
          :value="this.patientData.gender"
          :label="$i18n.t('lb_gender')"
          :isValid="this.validationState.gender"
        />
      </b-col>
      <b-col class="col-6">
        <CountrySelector
          id="patientFormOrigin"
          :label="$i18n.t('lb_origin')"
          :value="getOrigin"
          @handleChangeCountry="handleChangeOrigin"
          :isValid="this.validationState.origin"
          error="No puede estar vacio"
        />
      </b-col>
    </b-row>

    <div class="sp__v__1d5x" />

    <b-row>
      <b-col class="col-6">
        <InputGroup
          id="patientFormDNI"
          :label="$i18n.t('lb_dni_passport')"
          :placeholder="$i18n.t('lb_type_something')"
          @handlerInputChange="handleChangeDniPassport"
          :value="this.patientData.dni"
        />
      </b-col>

      <b-col class="col-6" v-if="isVidasPrime">
        <InputGroup
          id="patientFormIdCrm"
          :label="$i18n.t('lb_idCrm')"
          :placeholder="$i18n.t('lb_type_something')"
          @handlerInputChange="handleChangeIdCrm"
          :isValid="this.validationState.idCrm"
          :value="this.patientData.idCrm"
        />
      </b-col>
    </b-row>

    <div class="sp__v__1d5x" />

    <b-row>
      <b-col class="col-6">
        <DatepickerGropup
          id="patientFormBirthdate"
          :placeholder="$i18n.t('lb_select_date')"
          :label="$i18n.t('lb_dob')"
          @handleChangeDate="handleChangeBirthdate"
          :value="this.patientData.dob"
          :isValid="this.validationState.dob"
        />
      </b-col>
      <b-col class="col-6">
        <InputGroup
          id="patientFormTutor"
          :label="$i18n.t('lb_tutor')"
          :placeholder="$i18n.t('lb_type_something')"
          @handlerInputChange="handleChangeTutor"
          :value="this.patientData.tutor"
        />
      </b-col>
    </b-row>

    <div class="sp__v__1d5x" />

    <InsuranceSelector
      @handleChangeInsurance="handleChangeInsurance"
      @handleChangeHasInsurance="handleChangeHasInsurance"
      :hasInsurance="this.patientData.hasInsurance"
      :selectedInsurance="getInsurance"
    />

    <div class="sp__v__1d5x" />

    <b-row>
      <b-col class="col-6">
        <InputGroup
          id="patientFormHospital"
          :label="$i18n.t('lb_hospital')"
          :placeholder="$i18n.t('lb_type_something')"
          @handlerInputChange="handleChangeHospital"
          :isValid="this.validationState.hospital"
          error="No puede estar vacio"
          :value="this.patientData.hospital"
        />
      </b-col>
    </b-row>

    <div class="sp__v__1d5x" />

    <b-row>
      <b-col>
        <p class="text__formTitle">{{ $i18n.t('lb_contact_data')}}</p>
      </b-col>
    </b-row>

    <div class="sp__v__1d5x" />

    <b-row>
      <b-col class="col-6">
        <InputGroup
          id="patientFormEmail"
          :label="$i18n.t('lb_email')"
          :placeholder="$i18n.t('lb_type_email')"
          @handlerInputChange="handleChangeEmail"
          :value="getEmail()"
        />
      </b-col>
      <b-col class="col-6">
        <InputGroup
          id="patientFormPhone"
          :label="$i18n.t('lb_phone')"
          :placeholder="$i18n.t('lb_type_phone')"
          @handlerInputChange="handleChangePhone"
          :isValid="this.validationState.phone"
          :error="$i18n.t('val_phone')"
          :value="this.patientData.phone"
          type="tel"
        />
      </b-col>
    </b-row>

    <div class="sp__v__1d5x" />

    <b-row>
      <b-col>
        <Checkbox
          id="patientFormWhatsappAuth"
          :label="$i18n.t('cp_whatsapp_contact')"
          :description="$i18n.t('cp_whatsapp_auth')"
          :value="this.patientData.contactWhatsApp"
          @handleChange="handleChangeWhatsappAuth"
          isSwitch
        />
      </b-col>
    </b-row>

    <div class="sp__v" />

  </b-form>
  </ModalAction>
</template>

<script>
import ModalAction from '@/components/molecules/Modal/ModalAction.vue';
import InsuranceSelector from '@/components/molecules/InsuranceSelector/InsuranceSelector.vue';
import InputGroup from '@/components/atoms/Input/InputGroup.vue';
import Checkbox from '@/components/atoms/Checkbox/Checkbox.vue';
import GenderSelector from '@/components/atoms/GenderSelector/GenderSelector.vue';
import CountrySelector from '@/components/atoms/Select/CountrySelector.vue';
import DatepickerGropup from '@/components/atoms/Datepicker/DatepickerGropup.vue';
import {
  getValidationState,
  isValidForm,
  getPhoneValidationState,
  sanitizeEmail,
} from '@/helpers/ValidationHelper';
import { checkEmailFormat } from '@/helpers/UserHelper';

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    patient: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isVidasprime: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      patientData: {
        id: this.patient?.id,
        idCrm: this.patient?.idCrm,
        createdAt: this.patient?.createdAt,
        name: this.patient?.name ?? '',
        lastname: this.patient?.lastname ?? '',
        gender: this.patient?.gender,
        origin: this.patient?.origin?.id,
        dni: this.patient?.dni,
        dob: this.patient?.dob,
        tutor: this.patient?.tutor,
        hasInsurance: this.patient?.hasInsurance,
        insurance: this.patient?.insurance,
        hospital: this.patient?.hospital,
        email: this.patient?.email,
        phone: this.patient?.phone,
        contactWhatsApp: this.patient?.contactWhatsApp,
        requests: this.patient?.requests,
      },
      validationState: {
        name: null,
        idCrm: null,
        lastname: null,
        origin: null,
        dob: null,
        phone: null,
        gender: null,
      },
    };
  },
  methods: {
    sendOk() {
      this.checkInsuranceType(this.patientData.insurance);
      this.$emit('handleOk', this.patientData);
    },

    checkInsuranceType(data) {
      if (!data) {
        this.patientData.insurance = null;
      } else if (typeof data === 'object') {
        this.patientData.insurance = data.id;
      } else {
        this.patientData.insurance = data;
      }
    },

    handleOk() {
      if (this.patientData.email) {
        this.setSanitizedEmail();
      }

      const nameValidationState = true;
      if (this.patientData.name) {
        this.nameValidationState = getValidationState(
          this.patientData.name,
        );
      }

      const idCrmValidationState = true;
      if (this.patientData.idCrm) {
        this.nameValidationState = getValidationState(
          this.patientData.idCrm,
        );
      }

      const lastnameValidationState = true;
      if (this.patientData.lastname) {
        this.lastnameValidationState = getValidationState(
          this.patientData.lastname,
        );
      }

      const originValidationState = true;
      if (this.patientData.origin?.id) {
        this.originValidationState = getValidationState(
          this.patientData.origin?.id,
        );
      }

      const dobValidationState = true;
      if (this.patientData.dob) {
        this.dobValidationState = getValidationState(
          this.patientData.dob,
        );
      }

      const phoneValidationState = getPhoneValidationState(
        this.patientData.phone,
      );

      const genderValidationState = true;
      if (this.patientData.gender) {
        this.genderValidationState = getValidationState(
          this.patientData.gender,
        );
      }

      if (isValidForm([
        nameValidationState,
        idCrmValidationState,
        lastnameValidationState,
        originValidationState,
        dobValidationState,
        phoneValidationState,
        genderValidationState,
      ])) {
        this.sendOk();
      } else {
        this.validationState.name = nameValidationState;
        this.validationState.idCrm = idCrmValidationState;
        this.validationState.lastname = lastnameValidationState;
        this.validationState.origin = originValidationState;
        this.validationState.dob = dobValidationState;
        this.validationState.phone = phoneValidationState;
        this.validationState.gender = genderValidationState;
      }
    },

    handleCancel() {
      this.resetValidations();
      this.$emit('handleCancel');
    },

    handleChangeName(text) {
      this.patientData.name = text;
    },

    handleChangeIdCrm(text) {
      this.patientData.idCrm = text;
    },

    handleChangeSurname(text) {
      this.patientData.lastname = text;
    },

    handleChangeGender(value) {
      this.patientData.gender = value;
    },

    handleChangeOrigin(value) {
      this.patientData.origin = value;
    },

    handleChangeDniPassport(value) {
      this.patientData.dni = value;
    },

    handleChangeBirthdate(value) {
      this.patientData.dob = value;
    },

    handleChangeTutor(value) {
      this.patientData.tutor = value;
    },

    handleChangeHasInsurance(value) {
      this.patientData.hasInsurance = value;

      if (value === false) {
        this.patientData.insurance = '';
      }
    },

    handleChangeInsurance(value) {
      this.patientData.insurance = value;
    },

    handleChangeHospital(value) {
      this.patientData.hospital = value;
    },

    handleChangeEmail(text) {
      this.patientData.email = text;
    },

    handleChangePhone(text) {
      this.patientData.phone = text;
    },

    handleChangeWhatsappAuth(value) {
      this.patientData.contactWhatsApp = value;
    },

    resetValidations() {
      this.validationState.name = null;
      this.validationState.idCrm = null;
      this.validationState.lastname = null;
      this.validationState.origin = null;
      this.validationState.dob = null;
      this.validationState.phone = null;
      this.validationState.gender = null;
    },

    setSanitizedEmail() {
      const sanitizedEmail = sanitizeEmail(this.patientData.email);
      this.patientData.email = sanitizedEmail;
    },

    getEmail() {
      if (this.patientData.id && this.patientData.email) {
        return checkEmailFormat(this.patientData.id, this.patientData.email)
          ? this.patientData.email
          : null;
      }

      return this.patientData.email;
    },
  },
  computed: {
    isVidasPrime() {
      return this.$store.getters['user/isVidasprime'];
    },
    getOrigin() {
      return this.patientData.origin?.id;
    },

    getInsurance() {
      return this.patientData.insurance && this.patientData.insurance?.id
        ? this.patientData.insurance?.id
        : this.patientData.insurance;
    },
  },
  watch: {
    patient(newValue) {
      this.patientData.id = newValue.id;
      this.patientData.createdAt = newValue.createdAt;
      this.patientData.name = newValue.name;
      this.patientData.idCrm = newValue.idCrm;
      this.patientData.lastname = newValue.lastname;
      this.patientData.gender = newValue.gender;
      this.patientData.origin = newValue.origin;
      this.patientData.dni = newValue.dni;
      this.patientData.dob = newValue.dob;
      this.patientData.tutor = newValue.tutor;
      this.patientData.hasInsurance = newValue.hasInsurance;
      this.patientData.insurance = newValue.insurance;
      this.patientData.hospital = newValue.hospital;
      this.patientData.email = newValue.email;
      this.patientData.phone = newValue.phone;
      this.patientData.contactWhatsApp = newValue.contactWhatsApp;
      this.patientData.requests = newValue.requests;
    },
  },
  components: {
    ModalAction,
    InsuranceSelector,
    InputGroup,
    Checkbox,
    DatepickerGropup,
    GenderSelector,
    CountrySelector,
  },
};
</script>
