<template>
  <div>
    <div class="ReqColl" v-if="!isLoading">
      <div v-if="mainClinic !== null">
        <p class="text__psb">
          {{ $i18n.t('lb_collaboration_main') }}:
        </p>
        <div class="ReqColl-CardList">
          <ClinicCard
            v-if="mainClinic !== undefined"
            key="main_Clinic_Collaboration"
            :clinicItem="mainClinic"
            :isRadio="false"
          />
        </div>
      </div>
      <div v-if="collaboratorClinics.length > 0">
        <p class="text__psb">
            {{ $i18n.t('lb_collaboration_clinics') }}:
        </p>
        <div
          v-if="collaboratorClinics !== undefined"
          class="ReqColl-CardList"
        >
          <ClinicCard
            v-for="(clinicItem, index) in collaboratorClinics"
            :key="index"
            :clinicItem="clinicItem"
            :isRadio="false"
          />
        </div>
      </div>
      <div v-if="pendindInvitationsData !== undefined && (pendindInvitationsData.length > 0)">
        <p class="text__psb">
            {{ $i18n.t('lb_collaboration_invitations') }}:
        </p>
        <div class="ReqColl-CardList">
          <ClinicCard
            v-for="(invitationItem, index) in pendindInvitationsData"
            :key="index"
            :clinicItem="invitationItem"
            :isRadio="false"
            :isPending="true"
            :invitationId="invitationItem.id"
            @handleCancel="clearCancelInvitation"
          />
        </div>
      </div>
    </div>
    <InvitationCancelModal
        id="InvitationCancelModal"
        ref="InvitationCancelModal"
        :invitation="cancelInvitationId"
        v-on:handleCancelInvitation="handleCancelInvitation"
      />
  </div>
</template>
<script>

export default {
  props: {
    reqId: {
      type: String,
    },
    collaboratorClinics: {
      type: Array,
      default: () => [],
    },
    mainClinic: {
      type: Object,
      default: () => null,
    },
    pendindInvitations: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {},
  data() {
    return {
      isLoading: false,
      pendindInvitationsData: this.formatPendingInvitations(this.pendindInvitations),
      cancelInvitationId: '',
    };
  },
  computed: {
    requestList: {
      get() { return this.$store.state.request.request.requestList; },
      set(value) { this.$store.commit('request/setRequestList', value); },
    },
    isMobile() {
      return this.$store.getters['responsive/isMobile'];
    },
  },
  watch: {
    pendindInvitations(newValue) {
      this.pendindInvitationsData = this.formatPendingInvitations(newValue);
    },
    requestData(newValue) {
      this.requestData = newValue;
    },
  },
  methods: {
    formatPendingInvitations(invList) {
      const onlyPendList = invList.filter((inv) => inv.status === 'PENDING');
      const formattedInvList = onlyPendList.map((inv) => {
        const pendingInv = {
          id: inv.id,
          name: inv.collaboratorName || inv.collaborator?.name,
          email: inv.collaboratorEmail || inv.collaborator?.email,
          avatar: inv.collaborator?.avatar || inv.clinic?.avatar,
        };
        return pendingInv;
      });
      return formattedInvList;
    },
    clearCancelInvitation(invitationId) {
      this.cancelInvitationId = invitationId;
      this.$bvModal.show('InvitationCancelModal');
    },
    handleCancelInvitation(invitationId) {
      this.$bvModal.hide('InvitationCancelModal');
      this.$store.dispatch('invitations/cancelInvitation', invitationId)
        .then(() => {
          const reqIndex = this.requestList.findIndex((req) => req.id === this.reqId);
          const OpPendingInv = this.requestList[reqIndex].invitations.filter(
            (item) => item.id !== invitationId,
          );
          this.pendindInvitationsData = this.formatPendingInvitations(OpPendingInv);
          this.requestList[reqIndex].invitations = OpPendingInv;
        })
        .catch(() => {});
      if (this.isMobile) {
        this.$store.commit('ui/setShowMobileDetails', false);
      }
    },
  },
  components: {
    ClinicCard: () => import('@/components/atoms/Cards/ClinicCard/ClinicCard.vue'),
    InvitationCancelModal: () => import('@/components/molecules/Modal/Collaborations/InvitationCancelModal.vue'),
  },
};
</script>
<style lang="sass" scoped>
@import './Request.sass'
</style>
