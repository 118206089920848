import getAxiosWithAuthHeader from '../../helpers/AuthHelper';
import { getCityUrl } from '../../helpers/ApiHelper';
import CityFactory from './CityFactory';

const CITIES = '/api/cities/search';

class cityAPI {
  constructor() {
    this.city = getAxiosWithAuthHeader();
  }

  getAllCities(data) {
    const param = data;
    const urlWithParams = getCityUrl(CITIES, param);

    return this.city
      .get(urlWithParams)
      .then((res) => res.data.collection.map((city) => CityFactory.getCity(city)))
      .catch((error) => Promise.reject(error));
  }
}

export default cityAPI;
