import {
  getInsights,
} from './insightsActions';

const insights = {
  namespaced: true,
  actions: {
    getInsights,
  },
  getters: {
  },
};

export default insights;
