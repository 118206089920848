import { render, staticRenderFns } from "./ResetPasswordView.vue?vue&type=template&id=bb939318&scoped=true&"
import script from "./ResetPasswordView.vue?vue&type=script&lang=js&"
export * from "./ResetPasswordView.vue?vue&type=script&lang=js&"
import style0 from "./ResetPasswordView.vue?vue&type=style&index=0&id=bb939318&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bb939318",
  null
  
)

export default component.exports