import getAxiosWithAuthHeader from '../../helpers/AuthHelper';
import {
  getDataWithUuid,
  getParameterUrl,
  getSearchUrl,
} from '../../helpers/ApiHelper';
import ClinicFactory from './ClinicFactory';

const CLINICS = '/api/clinics';
const SEARCH_CLINICS = '/api/clinics/search';
const COLLABORATOR_CLINICS = '/api/collaborators';

class clinicAPI {
  constructor() {
    this.client = getAxiosWithAuthHeader();
  }

  getAllClinic(currentPage) {
    const parameterUrl = getParameterUrl(CLINICS, currentPage.page,
      [], [], currentPage.itemsPerPage);

    return this.client
      .get(parameterUrl)
      .then((res) => ClinicFactory.getAllClinic(res.data))
      .catch((error) => Promise.reject(error));
  }

  filterClinic(data) {
    const parameterUrl = getParameterUrl(
      SEARCH_CLINICS,
      data.page,
      data.filters,
      data.ordenation,
    );

    return this.client
      .get(parameterUrl)
      .then((res) => ClinicFactory.getAllClinic(res.data))
      .catch((error) => Promise.reject(error));
  }

  searchClinic(filters) {
    const filterUrl = getSearchUrl(SEARCH_CLINICS, filters, 10);

    return this.client
      .get(filterUrl)
      .then((res) => ClinicFactory.getAllClinic(res.data))
      .catch((error) => Promise.reject(error));
  }

  createClinic(data) {
    const idData = getDataWithUuid(data);

    return this.client
      .post(CLINICS, idData)
      .then(() => idData)
      .catch((error) => Promise.reject(error));
  }

  createCollaboratorClinic(data) {
    const idData = getDataWithUuid(data);

    return this.client
      .post(COLLABORATOR_CLINICS, idData)
      .then(() => idData)
      .catch((error) => Promise.reject(error));
  }

  updateClinic(requestData) {
    const url = `${CLINICS}/${requestData.id}`;

    return this.client
      .put(url, requestData.body)
      .then()
      .catch((error) => Promise.reject(error));
  }

  deleteClinic(id) {
    const url = `${CLINICS}/${id}`;

    return this.client
      .delete(url)
      .then()
      .catch((error) => Promise.reject(error));
  }
}

export default clinicAPI;
